import {UPDATE_PROFILE, UPDATE_PROFILE_RES } from "./type";

export const updateProfile = (data) => {
    return {
        type: UPDATE_PROFILE,
        payload: data
    };
};

export const updateProfileRes = (data) => {
    return {
        type: UPDATE_PROFILE_RES,
        payload: data
    };
};