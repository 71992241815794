import { Box, Card } from "@mui/material";
import CustomText from "../form/CustomText";
import {
  BoxSubscription,
  BoxWrap,
  CardStyle,
  CircleBox,
  CircleBoxWrap,
  Container,
} from "./style";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardCount } from "../../redux/dashboard/action";
import colors from "../../theme/colors";
import {
  CustomLoader,
  getDataFromLocalStorage,
} from "../../utils/common_functions";
import NavBar from "../navbar";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboard_count, count_loading } = useSelector((e) => e.dashboard);

  const userData = getDataFromLocalStorage("admin_detail");
  const {
    SuspendUsers,
    blockUsers,
    numberOfMales,
    numberOfFemales,
    totalUsersInApp,
    subscribedUsers,
    cancelSubscription,
    newUsers,
    activeUsers,
  } = dashboard_count?.countOfEverDataInDashboard || {};
  const cards = [
    {
      bg: colors.paleRose,
      label: "Total",
      count: totalUsersInApp,
      color: "#FF006D",
      path: "",
    },
    {
      bg: colors.transparentBlue,
      label: "Active",
      count: activeUsers,
      color: "#666CFF",
      path: "/admin/users-verify",
    },
    {
      bg: colors.lightGreen,
      label: "Incomplete",
      count: newUsers,
      color: "#7BC400",
      path: "",
    },
    {
      bg: colors.grayCard,
      label: "Rejected",
      count: blockUsers,
      color: "#575757",
      path: "",
    },
    {
      bg: colors.lightGreen,
      label: "Male",
      count: numberOfMales,
      color: "#7BC400",
      path: "",
    },
    {
      bg: colors.yellowLight,
      label: "Female",
      count: numberOfFemales,
      color: "#F0AD00",
      path: "",
    },
    {
      bg: colors.errorLight,
      label: "Deactive",
      count: SuspendUsers,
      color: "#FF0000",
      path: "",
    },
  ];
  useEffect(() => {
    dispatch(getDashboardCount());
  }, []);

  const calculate = (dividend, divider) => {
    const calculate = Math.trunc((dividend / divider) * 100) || 0;
    return calculate;
  };
  const brcrmb = [{ name: "Dashboard", path: "", noRedirect: true }];
  return (
    <>
      <NavBar option={brcrmb} />
      <Card sx={{ p: "40px 30px", m: "20px 36px 50px 36px" }}>
        <CustomText
          variant="large_title"
          sx={{ textAlign: "start", mb: "20px" }}
        >
          Welcome, {userData?.first_name + userData?.last_name}
        </CustomText>
        {count_loading && <CustomLoader />}
        <Container>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {cards.map((card, key) => {
              return (
                <CardStyle key={key} bg={card.bg}>
                  <CircleBoxWrap>
                    <CircleBox></CircleBox>
                  </CircleBoxWrap>
                  <Box>
                    <CustomText
                      variant="title_4"
                      sx={{
                        color: card.color,
                        textAlign: "start",
                        p: "12px 28px 0px 19px",
                      }}
                    >
                      {card.count || "0"}
                    </CustomText>
                    <CustomText
                      variant="title_3"
                      sx={{ mb: "15px", textAlign: "start", pl: "19px" }}
                    >
                      {card.label}
                    </CustomText>
                  </Box>
                </CardStyle>
              );
            })}
          </Box>
          <BoxWrap>
            <CustomText
              variant="title_5"
              sx={{ textAlign: "start", mb: "20px" }}
            >
              Subscription
            </CustomText>
            <Box>
              <BoxSubscription>
                <CustomText
                  className="title"
                  variant="subtitle_1"
                  sx={{ textAlign: "start" }}
                >
                  Active <br />
                  Subscriptions
                </CustomText>
                <CustomText
                  className="total_count"
                  variant="title_4"
                  sx={{ color: colors.white }}
                >
                  0%
                </CustomText>
                {/* {calculate(subscribedUsers,totalUsersInApp)} */}
              </BoxSubscription>
              <BoxSubscription bg="#F0F0F0">
                <CustomText
                  className="title"
                  variant="subtitle_1"
                  sx={{ color: colors.nero, textAlign: "start" }}
                >
                  Canceled <br /> Subscriptions
                </CustomText>
                <CustomText
                  className="total_count"
                  variant="title_4"
                  sx={{ color: colors.grayLight }}
                >
                  0%
                </CustomText>
                {/* {calculate(
                  cancelSubscription,
                  subscribedUsers + cancelSubscription
                )} */}
              </BoxSubscription>
            </Box>
          </BoxWrap>
        </Container>
      </Card>
    </>
  );
};

export default Dashboard;
