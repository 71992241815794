import styled from "@emotion/styled";
import { Box, Card } from "@mui/material";

export const CardStyle = styled(Card)(({ bg }) => ({
  borderRadius: "21px",
  background: bg && bg,
  marginRight: "20px",
  marginBottom: "20px",
  minWidth: "200px",
  cursor: "pointer",
  paddingRight: "10px",
  // maxWidth: '250px',
}));

export const BoxSubscription = styled(Box)(({ bg }) => ({
  background: bg ? bg : "linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)",
  borderRadius: "10px",
  padding: "15px",
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
  gap: "40px",
  alignItems: "center",
  "@media (max-width: 400px)": {
    gap: "10px",
    ".title": {
      fontSize: "14px",
    },
    ".total_count": {
      fontSize: "20px",
    },
  },
}));

export const BoxWrap = styled(Box)(({ bg }) => ({
  border: "1px solid rgba(123, 127, 145, 0.2)",
  borderRadius: "10px",
  padding: "15px 15px 8px",
  "@media (max-width: 800px)": {
    width: "100%",
  },
}));

export const CircleBox = styled(Box)({
  borderRadius: "50%",
  height: "84px",
  width: "84px",
  background: "#ffffff57",
  position: "absolute",
  left: "75%",
  top: "-30px",
});

export const Container = styled(Box)({
  display: "flex",
  alignItems: "start",
  justifyContent: "space-between",
  "@media (max-width: 900px)": {
    flexDirection: "column-reverse",
    gap: "30px",
  },
});

export const CircleBoxWrap = styled(Box)({
  display: "flex",
  justifyContent: "end",
  position: "relative",
});
