import {
  GET_USERS_RES,
  GET_USER_VIEW,
  GET_USER_VIEW_RESPONSE,
  GET_VERIFIED_USERS_RES,
  USER_VERIFY_BY_F_G_C,
  USER_IMOTF,
  GET_USER_EDIT,
  GET_USER_VRIFY,
  FAILER_USER_RES,
  VERIFY_STATUS,
  USER_IMOTF_STATUS,
  USER_EDIT_STATUS,
  FAIL_VIEW_STATUS,
  SIDEBAR_RESPONSIVE_RES,
  SET_LOADER_TRUE,
  GET_REGISTERED_USERS_RES,
  GET_UNDER_REVIEW_USERS_RES,
  EDIT_REJECT_EMAIL_RES,
  UNDER_REVIEW_USER_ACCEPT_RES,
  USER_VERIFY_BY_F_G_C_RES,
  UPDATE_IMOTF_STATUS_RES,
  REJECT_USER_LIST_RES,
  REJECT_USER_RES,
  REJECT_LOADER_STATUS_UPDATE,
} from "./type";

const initialState = {
  data: null,
  profile_view: [],
  // profile_data: {},
  users_list: [],
  user_verify: {},
  user_edit: {},
  user_loading: true,
  verify_loading: true,
  // verified_users: [],
  user_verify_fcg: {},
  user_imotf: {},
  view_user_loader: true,
  verify_status: false,
  imotf_status: false,
  edit_user_status: false,
  userVerifyClick: false,
  show: false,
  view_status: false,
  reject_loader: false,
  accept_loader: false,
  user_fcg_loader: false,
  reject_list_loader: false,
  reject_user_loader: false,
  verified_count: 0,
  review_count: 0,
  approval_count: 0,
  register_count: 0,
  reject_count: 0,
};

function usersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_RES:
      return {
        ...state,
        users_list: action?.payload?.data,
        approval_count: action?.payload.extra,
        user_loading: action.status,
      };
    case GET_USER_VIEW:
      return {
        ...state,
        profile_view: action.payload,
      };
    case GET_USER_VIEW_RESPONSE:
      return {
        ...state,
        profile_data: action.payload,
        view_user_loader: false,
        verify_status: false,
      };
    case GET_VERIFIED_USERS_RES:
      return {
        ...state,
        verified_users: action.payload?.data,
        verified_count: action.payload?.extra,
        verify_loading: action?.status,
      };
    case GET_USER_VRIFY:
      return {
        ...state,
        user_verify: action.payload,
      };
    case VERIFY_STATUS:
      return {
        ...state,
        verify_status: true,
      };
    case GET_USER_EDIT:
      return {
        ...state,
        user_edit: action.payload,
      };
    case USER_VERIFY_BY_F_G_C:
      return {
        ...state,
        userVerifyClick: true,
        user_verify_fcg: action.payload,
      };
    case USER_VERIFY_BY_F_G_C_RES:
      return {
        ...state,
        user_fcg_loader: action.payload,
      };
    case USER_IMOTF:
      return {
        ...state,
        user_imotf: action.payload,
      };
    case USER_IMOTF_STATUS:
      return {
        ...state,
        imotf_status: action.payload,
      };
    case USER_EDIT_STATUS:
      return {
        ...state,
        edit_user_status: action.payload,
      };

    case FAIL_VIEW_STATUS:
      return {
        ...state,
        view_user_loader: false,
        verify_status: false,
      };

    case SET_LOADER_TRUE:
      return {
        ...state,
        view_status: action.payload,
      };
    case GET_REGISTERED_USERS_RES:
      return {
        ...state,
        register_users: action.payload?.data,
        register_count: action?.payload?.extra,
        user_loading: action?.status,
      };
    case GET_UNDER_REVIEW_USERS_RES:
      return {
        ...state,
        review_users: action.payload?.data,
        review_count: action.payload?.extra,
        user_loading: action?.status,
      };
    case EDIT_REJECT_EMAIL_RES:
      return {
        ...state,
        reject_loader: action.payload,
      };
    case UNDER_REVIEW_USER_ACCEPT_RES:
      return {
        ...state,
        accept_loader: action.payload,
      };
    case FAILER_USER_RES:
      return {
        ...state,
        user_loading: false,
        verify_loading: false,
      };
    case SIDEBAR_RESPONSIVE_RES:
      return {
        show: action.payload,
      };
    case UPDATE_IMOTF_STATUS_RES:
      return {
        imotf_status: action.payload,
      };
    case REJECT_USER_LIST_RES:
      return {
        reject_users: action.payload.data,
        reject_count: action?.payload?.extra,
        reject_list_loader: action.status,
      };
    case REJECT_USER_RES:
      return {
        reject_user_loader: action.payload,
      };
    case REJECT_LOADER_STATUS_UPDATE:
      return {
        reject_user_loader: action.payload,
      };
    default:
      return state;
  }
}

export default usersReducer;
