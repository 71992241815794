import { Card, IconButton } from "@mui/material";
import CustomTable from "../form/CustomTable";
import CustomText from "../form/CustomText";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMatches, handleMatchLoader } from "../../redux/reported/action";
import { CustomLoader } from "../../utils/common_functions";
import { BlockUser, DeleteIcon, ReportUser } from "../../theme/SvgIcons";
import colors from "../../theme/colors";
import { useParams } from "react-router-dom";

const column = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "user_name",
    label: "User Name",
  },
  {
    id: "country",
    label: "Country",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "createdAt",
    label: "Registered",
  },
];

const rows = (matches_user) => {
  let list = matches_user?.user_data;
  if (Array.isArray(list)) {
    return list.map((el) => ({
      name: <CustomText variant="body_1">{el?.partner_data?.name}</CustomText>,
      user_name: (
        <CustomText variant="gray_title">
          {el?.partner_data?.user_name}
        </CustomText>
      ),
      country: (
        <CustomText variant="gray_title">
          {el?.partner_data?.residence_country}
        </CustomText>
      ),
      email: (
        <CustomText variant="gray_title">{el?.partner_data?.email}</CustomText>
      ),
      createdAt: (
        <CustomText variant="gray_title">
          {el?.partner_data?.createdAt?.split("T")[0]}
        </CustomText>
      ),
      action: (
        <>
          <IconButton sx={{ width: "40px", height: "40px" }}>
            <ReportUser />
          </IconButton>
          <IconButton sx={{ width: "40px", height: "40px" }}>
            <DeleteIcon fill={colors.pinkred} />
          </IconButton>
          <IconButton sx={{ width: "40px", height: "40px" }}>
            <BlockUser fill={colors.pinkred} />
          </IconButton>
        </>
      ),
    }));
  } else {
    return [];
  }
};
const NoOfMatches = (props) => {
  const { attributeStatus } = props || {};
  const dispatch = useDispatch();
  const { matches_user, matches_loading } = useSelector((e) => e.reported);
  const params = useParams();
  const user_id = params?.id.split("=")[1];

  useEffect(() => {
    dispatch(getMatches(user_id, attributeStatus));
    return () => {
      dispatch(handleMatchLoader(true));
    };
  }, [dispatch, user_id]);

  return (
    <>
      {matches_loading ? (
        <CustomLoader />
      ) : (
        <CustomTable rows={rows(matches_user)} headCells={column} />
      )}
    </>
  );
};
export default NoOfMatches;
