import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const PostContainer = styled.div`
  border-bottom: 1px solid #e2e2e2;
  margin: 20px 0px;
`;

export const PostImageWrapper = styled.div`
  max-height: 600px;
  max-width: 600px;
  margin: 20px 0px;
  overflow: hidden;
  img {
    width: 100%;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  .profile-image {
    height: 60px;
    width: 60px;
    border-radius: 60px;
    overflow: hidden;
    margin-right: 10px;
  }
  img {
    height: 60px;
    width: 60px;
  }
  .right-section {
    display: flex;
    width: 92%;
  }
  .left-section {
    width: 8%;
    text-align: right;
  }
  .name {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.05em;
    color: #242424;
    text-align: left;
  }
  .date {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.05em;
    color: #7b7f91;
    text-align: left;
  }
`;

export const TopSection = styled.div`
  font-family: "Poppins";
  font-style: normal;
  .description {
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    margin-top: 10px;
  }
`;
export const PostBottemWrapper = styled.div`
  .bottom-section {
    display: flex;
    align-items: center;
    height: 56px;
    background: #f7f7f7;
    border-radius: 10px;
  }
  .like-section {
    margin-right: 10px;
    justify-content: space-evenly;
  }
  .comment-section {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #7b7f91;
    justify-content: center;
  }
`;

export const PostUploadFormWrapper = styled.div`
  font-family: "Poppins";
  font-style: normal;
  padding: 25px 30px;
  .label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #242424;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 5px;
  }
`;
export const ButtonStyle = styled(Button)({
  width: "160px", //"196.28px",
  height: "40px", //"56px",
  background: "linear-gradient(268.55deg, #ff483c 0%, #ff2c5a 100%)",
  borderRadius: " 10px",
  marginTop: "30px",
  color: "white",
});
