import styled from '@emotion/styled';
import { Box, Card, TextField } from '@mui/material';
import colors from '../theme/colors';

export const NotFoundCard = styled(Card)({
  marginTop: '30px',
  height: '300px',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
});

export const textStyle = () => {
  const style = {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '16px',
    color: colors.grayLight,
    width: '180px'
  };
  return style;
};

export const CardStyle = styled(Card)(({ bg }) => ({
  height: '52px',
  minWidth: '155px',
  marginRight: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  marginBottom: '20px',
  backgroundColor: bg,
  color: colors.white,
  boxShadow: 'none',
  '&: last-child': {
    marginRight: 0
  }
}));
export const statusstyle = {
  fontFamily: 'Poppins',
  fontWeight: 500,
  fontSize: '16px',
  marginLeft: '16px'
};

export const BoxWrap = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
});


export const FieldStyle = styled(TextField)({
  background: colors.grayLight91,
  width: '100%',
  borderRadius: '6px',
  '& .MuiOutlinedInput-input': {
    background: colors.grayLight91,
    color: colors.grayLight,
    padding: '10px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '0px',
      borderRadius: '6px'
    },
    '&:hover fieldset': {
      border: '1px'
    },
    '&.Mui-focused fieldset': {
      border: '1px'
    }
  },
  '& .MuiInputAdornment-root': {
    background: 'linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)',
    borderRadius: '10px',
    height: '42px',
    width: '42px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  }
})