import styled from "@emotion/styled";
import { Box, List, ListItemButton, ListItemIcon } from "@mui/material";
import colors from "../../theme/colors";
import { Link } from "react-router-dom";

export const ListStyle = styled(List)({
    background: colors.white,
    width: '100%',
    maxWidth: 280,
    borderTop: `1px solid ${colors.ghostWhite}`,
    height: 'calc(100vh - 130px)',
    overflow: 'auto',
    '::-webkit-scrollbar': {
        display: 'none'
    }
})

export const ListButton = styled(ListItemButton)`
    padding-left:30px;
    &:hover {
        background: ${colors.gray67};
        opacity:0.6;
    }
`
export const BoxWrap = styled(Box)({
    width: '100%',
    maxWidth: '230px'
})

export const ImgStyle = styled.img`
    margin:22px 0px 30px 30px;
`
export const LinkStyle = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
});

export const ListItemIconStyle = styled(ListItemIcon)({
    minWidth: '0px',
    marginRight: '17px'
})