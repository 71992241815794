import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "userSlice",
  initialState: {
    isLoading: true,
  },
  reducers: {
    setLoader: (state, actions) => {
      if (actions) {
        state.isLoading = actions.payload;
      }
    },
  },
});

export const { setLoader } = UserSlice.actions;
export default UserSlice.reducer;
