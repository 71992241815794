import { toast } from "react-toastify";
import { CREATE_POST, DELETE_ADMIN_POST, GET_ALL_POSTS } from "./type";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  createPostRoute,
  deleteAdminPostsRoute,
  getAdminPostsRoute,
} from "./apiRoutes";
import {
  deleteAdminPostResponse,
  getALLPostResponse,
  getAdminPosts,
  getCreatePostResponse,
} from "./action";

function* createPostGenerator(action) {
  try {
    const res = yield call(createPostRoute, action.payload);
    if (res?.status === 200) {
      yield put(getCreatePostResponse({ status: true, allPostLoader: false }));
      toast.success(res?.message || "Post Create Successfully");
    }
  } catch (error) {
    yield put(getCreatePostResponse({ status: true, allPostLoader: false }));
    toast.error("Something went Wrong");
  }
}

function* getAllPostGenerator() {
  try {
    const res = yield call(getAdminPostsRoute);
    if (res?.status === 200) {
      yield put(getALLPostResponse({ data: res?.data, loader: true }));
      yield put(getCreatePostResponse({ status: false }));
    } else {
      toast.error(res?.message);
    }
  } catch (error) {
    yield put(getALLPostResponse({ data: {}, loader: true }));
    yield put(getCreatePostResponse({ status: false })); //,allPostLoader:false
    toast.error("Something went Wrong");
  }
}

function* deletePostGenerator(action) {
  const { post_id } = action;
  try {
    const res = yield call(deleteAdminPostsRoute, post_id);
    if (res?.status === 200) {
      toast.success(res?.message || "Admin post delete successfully");
      yield put(deleteAdminPostResponse(true));
      yield put(getAdminPosts());
    } else {
      toast.error(res?.message);
    }
  } catch (error) {
    toast.error("Something went Wrong");
    yield put(deleteAdminPostResponse(true));
  }
}

function* postSaga() {
  yield takeLatest(CREATE_POST, createPostGenerator);
  yield takeLatest(GET_ALL_POSTS, getAllPostGenerator);
  yield takeLatest(DELETE_ADMIN_POST, deletePostGenerator);
}

export default postSaga;
