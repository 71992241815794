import { Box } from "@mui/material";
import colors from "../../theme/colors";
import NavBar from "../navbar"
import Sidebar from "../sidebar"
import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMediaQuery } from 'react-responsive'
import { useSelector } from "react-redux";
import { mobile_size } from "../../utils/constant";

const MainWrapper = styled(Box)({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
});

const ItemWrapper = styled(Box)({
    flex: 1,
    background: colors.gray90,

    height: '100vh',
    overflow: 'auto',
    '::-webkit-scrollbar': {
        display: 'none'
    }
});
const MainContainer = () => {
    const isMobile = useMediaQuery({ query: mobile_size})
    const {show}=useSelector(e=>e.users)
    const responsive_style=isMobile?{
        position: "absolute",
        left:" 0px",
        zIndex: "999",
        background: "white",
    }:{}
    return (
        <MainWrapper>
            <Box sx={{ display: 'flex', flex: 1 }}>
                {(!isMobile || (isMobile && show)) && <Sidebar style={responsive_style}/>}
                <ToastContainer />
                <ItemWrapper>

                    {/* <Box sx={{ padding: '20px 36px 50px 36px', }}> */}
                    <Outlet />
                    {/* </Box> */}
                </ItemWrapper>
            </Box>
        </MainWrapper>
    )
}
export default MainContainer