import { Box, Button, Card, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import LikePlay from "./likePlayprofile";
import { labelStyle, TabButtonWrapper } from "./style";
import {
  getUserView,
  UsersVerifyFGC,
  UsersVerifyFGC_Res,
} from "../../redux/users/action";
import { useLocation, useParams } from "react-router-dom";
import VerifyModel from "../deleteModel/VerifyModel";
import { CustomLoader } from "../../utils/common_functions";
import NoOfAdmirers from "../reported/NoOfAdmiers";
import NoOfAdmiring from "../reported/NoOfAmiring";
import NoOfMatches from "../reported/NoOfMatches";
import NavBar from "../navbar";
import CustomText from "../form/CustomText";
import colors from "../../theme/colors";
import { query, collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import {
  questions_list,
  verification_question,
  brcmb_subsection,
  brcmb_subsection_link,
  profile_tab_buttons,
} from "../../utils/constant";

const Profile = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [loader, setLoader] = useState(true);
  const [noOfChats, setNoOfChats] = useState([]);
  const [isFacebookFullScreen, setIsFacebookFullScreen] = useState(false);
  const [verifyModel, setVerifyModel] = useState({
    type: "",
    status: false,
  });
  const [isFullScreenVerfication, setIsFullScreenVerfication] = useState(false);
  const [isFullScreenVerfication2, setIsFullScreenVerfication2] =
    useState(false);

  const params = useParams();
  const { state } = useLocation();
  const detail_id = params?.id.split("=")[1];
  const dispatch = useDispatch();
  const [preference, setPreference] = useState("Profile");

  const profile_data = useSelector((e) => e.users.profile_data);
  const { view_user_loader, user_fcg_loader } = useSelector((e) => e.users);

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   if (profile_data === undefined) getData();
  // }, [profile_data]);

  const partenrData =
    profile_data?.PartnerPhysicalQuestions &&
    profile_data?.PartnerPhysicalQuestions[0];

  const verificationData = profile_data?.FaceBookVerification;

  const govverificationData =
    profile_data?.GovernmentVerifications &&
    profile_data?.GovernmentVerifications[0];

  const profileImages =
    profile_data?.user_images_while_signup &&
    profile_data?.user_images_while_signup[0]?.image_url;

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setIsFullScreen(true);
  };

  const handleCloseModal = (index) => {
    setSelectedImageIndex(index);
    setIsFullScreen(false);
  };

  const handleClick = (buttonIndex) => {
    switch (buttonIndex) {
      case 1:
        setPreference("Preferences");
        break;
      case 2:
        setPreference("Verification");
        break;
      case 3:
        setPreference("noOfAdmirers");
        break;
      case 4:
        setPreference("noOfAdmiring");
        break;
      case 5:
        setPreference("noOfChats");
        break;
      case 6:
        setPreference("noOfMatches");
        break;
      default:
        setPreference("Profile");
        break;
    }
  };

  const getData = () => {
    dispatch(getUserView(detail_id));
    // setLoader(false)
  };

  const handleVerification = (user) => {
    setLoader(true);
    dispatch(UsersVerifyFGC(user));
  };

  useEffect(() => {
    if (user_fcg_loader) {
      getData();
      setLoader(false);
      dispatch(UsersVerifyFGC_Res(false));
    }
  }, [user_fcg_loader]);

  useEffect(() => {
    if (!view_user_loader) setLoader(false);
  }, [view_user_loader]);

  useEffect(() => {
    const q = query(collection(db, "users", detail_id, "match"));
    onSnapshot(q, (QuerySnapshot) => {
      let messages = [];
      QuerySnapshot.forEach((doc) => {
        messages.push({ ...doc.data(), id: doc.id });
      });
      setNoOfChats(messages);
    });
  }, []);

  const brcrmb = [
    {
      name: "Users",
      path: brcmb_subsection_link[state?.name] || "/admin/register-user",
    },
    {
      name: brcmb_subsection[state?.name] || "Register-User",
      path: brcmb_subsection_link[state?.name] || "/admin/register-user",
    },
    { name: profile_data?.user_name, path: "", noRedirect: true },
  ];

  const reportbrd = [
    { name: "Reported", path: "/admin/reported" },
    { name: profile_data?.user_name, path: "", noRedirect: true },
  ];

  const suspendedbrd = [
    { name: "Suspended", path: "/admin/suspended" },
    { name: profile_data?.user_name, path: "", noRedirect: true },
  ];

  const blockedbrd = [
    { name: "Blocked", path: "/admin/block-user" },
    { name: profile_data?.user_name, path: "", noRedirect: true },
  ];

  const brdOpt = state?.isReported
    ? reportbrd
    : state?.isSuspended
    ? suspendedbrd
    : state?.isBlocked
    ? blockedbrd
    : brcrmb;

  const physical_ans_show =
    profile_data?.choose_any_one === "1" ||
    profile_data?.choose_any_one === "3";
  const character_ans_show =
    profile_data?.choose_any_one === "2" ||
    profile_data?.choose_any_one === "3";

  return (
    <>
      <NavBar option={brdOpt} />
      <PermissionWrapper sx={{ m: "20px 36px 50px 36px" }}>
        <LikePlay
          image={profileImages}
          profile_data={profile_data}
          state={state}
        />
        <TabButtonWrapper>
          {profile_tab_buttons.map((list, index) => (
            <Box
              key={index}
              onClick={() => handleClick(list.value)}
              style={{
                borderBottom:
                  preference === list.key ? "2px solid #FF483C" : "",
                margin: "0 28px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  ...labelStyle(
                    preference === list.key ? "black" : "#7B7F91",
                    "12px",
                    500
                  ),
                  whiteSpace: "nowrap",
                }}
              >
                {list.name}
              </Typography>
            </Box>
          ))}
        </TabButtonWrapper>
        <Card>
          {preference !== "noOfAdmirers" &&
            preference !== "noOfAdmiring" &&
            preference !== "noOfMatches" && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  padding: "40px 20px",
                  gap: "25px",
                }}
              >
                {preference === "Profile" && (
                  <>
                    <UserDeatils>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Full Name
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Age
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.age}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Gender
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {/* uncomplete user also get whose gender is not fill so need to check proper */}
                          {profile_data?.gender === "1"
                            ? "Female"
                            : profile_data?.gender === "0"
                            ? "Male"
                            : ""}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Interested In
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {/* uncomplete user also get whose gender is not fill so need to check proper */}
                          {profile_data?.interested_in === "1"
                            ? "Female"
                            : profile_data?.interested_in === "2"
                            ? "Male"
                            : ""}
                        </Typography>
                      </div>
                    </UserDeatils>
                    <UserDeatils>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Status
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.status}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Body Type
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.body_type}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Height
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.height}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Education
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.education}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Employment
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.employment}
                        </Typography>
                      </div>
                    </UserDeatils>
                    <UserDeatils>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Country of Residence
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.residence_country}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          State
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.state}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          City
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.city}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Nationality
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.nationality}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Religion
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.religion}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Your Tribe
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.your_tribe}
                        </Typography>
                      </div>
                    </UserDeatils>
                    <UserDeatils>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Age Range preferred to date
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.age_range_for_date}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Tribe to date
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.tribe_to_date}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          Looking for
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                          }}
                        >
                          {profile_data?.looking_for}
                        </Typography>
                      </div>
                    </UserDeatils>
                    <AbouWarpper>
                      <Box gridColumn="span 12">
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                          }}
                        >
                          About me
                        </Typography>
                        <Typography
                          sx={{
                            ...labelStyle("#7B7F91", "12px", 500),
                            maxHeight: "150px",
                            overflowWrap: "anywhere",
                            overflow: "auto",
                          }}
                        >
                          {profile_data?.about_me}
                        </Typography>
                      </Box>
                    </AbouWarpper>
                    <AbouWarpper>
                      <Box gridColumn="span 12">
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                            paddingBottom: "5px",
                          }}
                        >
                          Images
                        </Typography>
                        <Facbookimgwrapper>
                          {profile_data?.user_images_while_signup?.length >
                          0 ? (
                            <Box
                              gridColumn="span 3"
                              style={{
                                maxHeight: "85px",
                                borderRadius: "8px",
                                backgroundColor: "grey",
                                overflow: "hidden",
                                border: "1px solid #e2e2e2",
                              }}
                            >
                              {profile_data?.user_images_while_signup?.map(
                                (item, index) => {
                                  return (
                                    <>
                                      {!isFullScreen ? (
                                        <ImageStyle
                                          src={`${process.env.REACT_APP_IMAGE_URL}${item?.image_url}`}
                                          onClick={() =>
                                            handleImageClick(index)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            width: "100%",
                                          }}
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            position: "fixed",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: "black",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            zIndex: 9999,
                                          }}
                                          onClick={() =>
                                            handleCloseModal(index)
                                          }
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}${profile_data?.user_images_while_signup[selectedImageIndex]?.image_url}`}
                                            alt={"altText"}
                                            style={{
                                              maxHeight: "100%",
                                              maxWidth: "100%",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </Box>
                          ) : (
                            <Box
                              gridColumn="span 12"
                              style={{
                                height: "130px",
                                background: "#F1F1F1",
                                borderRadius: "21px",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...labelStyle("#242424", "12px", 500),
                                  // margin: '0 auto'
                                  padding: "20% 35%",
                                }}
                              >
                                No Images Found
                              </Typography>
                            </Box>
                          )}
                        </Facbookimgwrapper>
                      </Box>
                    </AbouWarpper>
                  </>
                )}

                {/* preference */}
                {preference === "Preferences" && (
                  <>
                    <div
                      style={{
                        border: "1px solid #E2E2E2",
                        borderRadius: "10px",
                        backgroundColor: "#F3F3F3",
                      }}
                    >
                      <Typography
                        sx={{
                          ...labelStyle("#242424", "12px", 500),
                          padding: "10px 15px",
                        }}
                      >
                        Your Physical Attributes
                      </Typography>
                      <UserDeatils>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Size
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? profile_data?.user_physical_size
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Back End
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? profile_data?.user_physical_backend
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Facial
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? profile_data?.user_physical_facial
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Height
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? profile_data?.user_physical_height
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Front
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? profile_data?.user_physical_front
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Glasses
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? profile_data?.user_physical_glasses
                              : "---"}
                          </Typography>
                        </div>
                      </UserDeatils>
                    </div>
                    <div
                      style={{
                        border: "1px solid #E2E2E2",
                        borderRadius: "10px",
                        backgroundColor: "#F3F3F3",
                      }}
                    >
                      <Typography
                        sx={{
                          ...labelStyle("#242424", "12px", 500),
                          padding: "10px 15px",
                        }}
                      >
                        Partners Physical Attributes
                      </Typography>
                      <UserDeatils>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Size
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? partenrData?.partner_size
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Back End
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? partenrData?.partner_back_end
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Facial
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? partenrData?.partner_facial
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Height
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? partenrData?.partner_height
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Front
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? partenrData?.partner_front
                              : "---"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            sx={{
                              ...labelStyle("#242424", "12px", 500),
                            }}
                          >
                            Glasses
                          </Typography>
                          <Typography
                            sx={{
                              ...labelStyle("#7B7F91", "12px", 500),
                            }}
                          >
                            {physical_ans_show
                              ? partenrData?.partner_glasses
                              : "---"}
                          </Typography>
                        </div>
                      </UserDeatils>
                    </div>
                    <div
                      style={{
                        border: "1px solid #E2E2E2",
                        borderRadius: "10px",
                        backgroundColor: "#F3F3F3",
                      }}
                    >
                      <Typography
                        sx={{
                          ...labelStyle("#242424", "12px", 500),
                          padding: "10px 15px",
                        }}
                      >
                        Character Questions
                      </Typography>
                      <CharacterWrapper>
                        {questions_list.map((list, index) => (
                          <div key={index}>
                            <Typography
                              sx={{
                                ...labelStyle("#242424", "12px", 500),
                              }}
                            >
                              {list.question}
                            </Typography>
                            <Typography
                              sx={{
                                ...labelStyle("#7B7F91", "12px", 500),
                              }}
                            >
                              {character_ans_show ? (
                                profile_data?.[list.answer_key]
                              ) : (
                                <CustomText sx={{ padding: "0 10px" }}>
                                  ---
                                </CustomText>
                              )}
                            </Typography>
                          </div>
                        ))}
                      </CharacterWrapper>
                    </div>
                  </>
                )}

                {preference === "Verification" && (
                  <>
                    <div
                      style={{
                        border: "1px solid #E2E2E2",
                        paddingTop: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#F3F3F3",
                        height: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          float: "left",
                          padding: "0px 15px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                            paddingBottom: "10px",
                            // paddingLeft: '15px'
                          }}
                        >
                          Verify With Facebook
                        </Typography>
                      </div>
                      {verificationData !== null ? (
                        <ButtonStyle
                          sx={{
                            background: `${
                              profile_data?.facebook_verification_status
                                ? "green!important"
                                : "linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)!important"
                            }`,
                          }}
                          onClick={() =>
                            profile_data?.facebook_verification_status
                              ? {}
                              : setVerifyModel({
                                  type: "facebook",
                                  status: true,
                                })
                          }
                        >
                          <Typography
                            sx={{
                              ...labelStyle("white", "10px", 400),
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* Click to verify */}
                            {profile_data?.facebook_verification_status
                              ? "Verified"
                              : "Click to verify"}
                          </Typography>
                        </ButtonStyle>
                      ) : (
                        ""
                      )}
                      <VerifyWrapper>
                        {verificationData !== null ? (
                          <>
                            <Box gridColumn="span 6">
                              <Typography
                                sx={{
                                  ...labelStyle("#242424", "12px", 500),
                                }}
                              >
                                Username
                              </Typography>
                              <Typography
                                sx={{
                                  ...labelStyle("#7B7F91", "12px", 500),
                                }}
                              >
                                {verificationData?.facebook_username}
                              </Typography>
                            </Box>
                            <Box gridColumn="span 6">
                              <Typography
                                sx={{
                                  ...labelStyle("#242424", "12px", 500),
                                }}
                              >
                                Email
                              </Typography>
                              <Typography
                                sx={{
                                  ...labelStyle("#7B7F91", "12px", 500),
                                }}
                              >
                                {verificationData?.facebook_email}
                              </Typography>
                            </Box>

                            <Box gridColumn="span 12">
                              <Typography
                                sx={{
                                  ...labelStyle("#242424", "12px", 500),
                                }}
                              >
                                Bio
                              </Typography>
                              <Typography
                                sx={{
                                  ...labelStyle("#7B7F91", "12px", 500),
                                }}
                              >
                                {verificationData?.facebook_bio}
                              </Typography>
                            </Box>
                            <Box gridColumn="span 12">
                              <Typography
                                sx={{
                                  ...labelStyle("#242424", "12px", 500),
                                  paddingBottom: "5px",
                                }}
                              >
                                Image
                              </Typography>
                              <Facbookimgwrapper>
                                {verificationData?.facebook_image &&
                                  (!isFacebookFullScreen ? (
                                    <ImageStyle
                                      src={`${process.env.REACT_APP_IMAGE_URL}${verificationData?.facebook_image}`}
                                      onClick={() =>
                                        setIsFacebookFullScreen(true)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        borderRadius: "8px",
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        position: "fixed",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                        backgroundColor: "black",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        zIndex: 9999,
                                      }}
                                      onClick={() =>
                                        setIsFacebookFullScreen(false)
                                      }
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}${verificationData?.facebook_image}`}
                                        alt="altText"
                                        style={{
                                          maxHeight: "100%",
                                          maxWidth: "100%",
                                        }}
                                      />
                                    </div>
                                  ))}
                                {/* <Box
                            gridColumn="span 1"
                            style={{
                              // minWidth: '30px',
                              borderRadius: "8px",
                              overflow: "hidden",
                              backgroundColor: "gray",
                              height: "43px",
                            }}
                          >
                            <ImageStyle src={bio} />
                          </Box> */}
                              </Facbookimgwrapper>
                            </Box>
                          </>
                        ) : (
                          <Box
                            gridColumn="span 12"
                            style={{
                              height: "130px",
                              background: "#F1F1F1",
                              borderRadius: "21px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...labelStyle("#242424", "12px", 500),
                                // margin: '0 auto'
                                padding: "20% 35%",
                              }}
                            >
                              No Data Found
                            </Typography>
                          </Box>
                        )}
                      </VerifyWrapper>
                    </div>
                    <div
                      style={{
                        border: "1px solid #E2E2E2",
                        paddingTop: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#F3F3F3",
                        height: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          float: "left",
                          padding: "0px 15px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                            paddingBottom: "10px",
                            // paddingLeft: '15px'
                          }}
                        >
                          Verify With Government ID
                        </Typography>
                      </div>
                      {govverificationData ? (
                        <ButtonStyle
                          sx={{
                            background: `${
                              profile_data?.government_id_verification_status
                                ? "green!important"
                                : "linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)!important"
                            }`,
                          }}
                          onClick={() =>
                            profile_data?.government_id_verification_status
                              ? {}
                              : setVerifyModel({ type: "gov", status: true })
                          }
                        >
                          <Typography
                            sx={{
                              ...labelStyle("white", "10px", 400),
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* Click to verify */}
                            {profile_data?.government_id_verification_status
                              ? "Verified"
                              : "Click to verify"}
                          </Typography>
                        </ButtonStyle>
                      ) : (
                        ""
                      )}

                      <VerifyWrapper>
                        {govverificationData ? (
                          <>
                            <Box
                              gridColumn="span 6"
                              style={{
                                height: "130px",
                                background: "#F1F1F1",
                                borderRadius: "21px",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {!isFullScreenVerfication ? (
                                <ImageStyle
                                  // src="/likeplaylogo.png"
                                  src={`${process.env.REACT_APP_IMAGE_URL}${govverificationData?.government_id_front_side}`}
                                  onClick={() =>
                                    setIsFullScreenVerfication(true)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "8px",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    zIndex: 9999,
                                  }}
                                  onClick={() =>
                                    setIsFullScreenVerfication(false)
                                  }
                                >
                                  <img
                                    // src="/likeplaylogo.png"
                                    src={`${process.env.REACT_APP_IMAGE_URL}${govverificationData?.government_id_front_side}`}
                                    alt={"altText"}
                                    style={{
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                    }}
                                  />
                                </div>
                              )}
                            </Box>

                            <Box
                              gridColumn="span 6"
                              style={{
                                height: "130px",
                                background: "#F1F1F1",
                                borderRadius: "21px",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {!isFullScreenVerfication2 ? (
                                <ImageStyle
                                  // src="/likeplaylogo.png"
                                  src={`${process.env.REACT_APP_IMAGE_URL}${govverificationData?.government_id_back_side}`}
                                  onClick={() =>
                                    setIsFullScreenVerfication2(true)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    borderRadius: "8px",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    zIndex: 9999,
                                  }}
                                  onClick={() =>
                                    setIsFullScreenVerfication2(false)
                                  }
                                >
                                  <img
                                    // src="/likeplaylogo.png"
                                    src={`${process.env.REACT_APP_IMAGE_URL}${govverificationData?.government_id_back_side}`}
                                    alt={"altText"}
                                    style={{
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                    }}
                                  />
                                </div>
                              )}
                            </Box>
                          </>
                        ) : (
                          <Box
                            gridColumn="span 12"
                            style={{
                              height: "130px",
                              background: "#F1F1F1",
                              borderRadius: "21px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...labelStyle("#242424", "12px", 500),
                                // margin: '0 auto'
                                padding: "20% 35%",
                              }}
                            >
                              No Data Found
                            </Typography>
                          </Box>
                        )}
                      </VerifyWrapper>
                    </div>
                    <div
                      style={{
                        border: "1px solid #E2E2E2",
                        paddingTop: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#F3F3F3",
                        height: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          float: "left",
                          padding: "0px 15px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                            paddingBottom: "10px",
                            // paddingLeft: '15px'
                          }}
                        >
                          Verify With Camera
                        </Typography>
                      </div>
                      {profile_data?.photos_for_verification && (
                        <ButtonStyle
                          sx={{
                            background: `${
                              profile_data?.photo_verification_status
                                ? "green!important"
                                : "linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)!important"
                            }`,
                          }}
                          onClick={() =>
                            profile_data?.photo_verification_status
                              ? {}
                              : setVerifyModel({ type: "camera", status: true })
                          }
                        >
                          <Typography
                            sx={{
                              ...labelStyle("white", "10px", 400),
                              whiteSpace: "nowrap",
                            }}
                          >
                            {profile_data?.photo_verification_status
                              ? "Verified"
                              : "Click to verify"}
                          </Typography>
                        </ButtonStyle>
                      )}
                      <VerifyWrapper>
                        {profile_data?.photos_for_verification ? (
                          <Box
                            gridColumn="span 4"
                            style={{
                              borderRadius: "10px",
                              overflow: "hidden",
                              backgroundColor: "gray",
                              height: "140px",
                            }}
                          >
                            {!isFullScreen ? (
                              <ImageStyle
                                // src="/likeplaylogo.png"
                                src={`${process.env.REACT_APP_IMAGE_URL}${profile_data?.photos_for_verification}`}
                                onClick={handleImageClick}
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  position: "fixed",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  backgroundColor: "black",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  zIndex: 9999,
                                }}
                                onClick={handleCloseModal}
                              >
                                <img
                                  // src="/likeplaylogo.png"
                                  src={`${process.env.REACT_APP_IMAGE_URL}${profile_data?.photos_for_verification}`}
                                  alt={"altText"}
                                  style={{
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                  }}
                                />
                              </div>
                            )}
                          </Box>
                        ) : (
                          <Box
                            gridColumn="span 12"
                            style={{
                              height: "130px",
                              background: "#F1F1F1",
                              borderRadius: "21px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...labelStyle("#242424", "12px", 500),
                                // margin: '0 auto'
                                padding: "20% 35%",
                              }}
                            >
                              No Data Found
                            </Typography>
                          </Box>
                        )}
                      </VerifyWrapper>
                    </div>
                    <div
                      style={{
                        border: "1px solid #E2E2E2",
                        paddingTop: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#F3F3F3",
                        height: "fit-content",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          float: "left",
                          padding: "0px 15px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                            paddingBottom: "10px",
                            // paddingLeft: '15px'
                          }}
                        >
                          Verify With Phone No
                        </Typography>
                      </div>
                      <VerifyWrapper>
                        {profile_data?.mobile_number !== null ? (
                          <>
                            <Box gridColumn="span 6">
                              <Typography
                                sx={{
                                  ...labelStyle("#242424", "12px", 500),
                                }}
                              >
                                Phone No :
                              </Typography>
                              <Typography
                                sx={{
                                  ...labelStyle("#7B7F91", "12px", 500),
                                }}
                              >
                                {/* {verificationData} */}
                                {profile_data?.mobile_number}
                              </Typography>
                            </Box>
                            <Box gridColumn="span 6">
                              <Typography
                                sx={{
                                  ...labelStyle("#242424", "12px", 500),
                                }}
                              >
                                status :
                              </Typography>
                              <Typography
                                sx={{
                                  ...labelStyle("#7B7F91", "12px", 500),
                                }}
                              >
                                {profile_data?.mobile_number_verification_status
                                  ? "Verified"
                                  : "unVerified"}
                              </Typography>
                            </Box>
                          </>
                        ) : (
                          <Box
                            gridColumn="span 12"
                            style={{
                              height: "130px",
                              background: "#F1F1F1",
                              borderRadius: "21px",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                ...labelStyle("#242424", "12px", 500),
                                // margin: '0 auto'
                                padding: "20% 35%",
                              }}
                            >
                              No Data Found
                            </Typography>
                          </Box>
                        )}
                      </VerifyWrapper>
                    </div>
                    <div
                      style={{
                        border: "1px solid #E2E2E2",
                        paddingTop: "10px",
                        borderRadius: "10px",
                        backgroundColor: "#F3F3F3",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          float: "left",
                          padding: "0px 15px",
                        }}
                      >
                        <Typography
                          sx={{
                            ...labelStyle("#242424", "12px", 500),
                            paddingBottom: "10px",
                          }}
                        >
                          Verification Questions
                        </Typography>
                      </div>
                      <CharacterWrapper>
                        {verification_question.map((list, index) => (
                          <div key={index}>
                            <Typography
                              sx={{
                                ...labelStyle("#242424", "12px", 500),
                              }}
                            >
                              {list.question}
                            </Typography>
                            <Typography
                              sx={{
                                ...labelStyle("#7B7F91", "12px", 500),
                              }}
                            >
                              {profile_data?.verification[list.answer_key] || (
                                <CustomText sx={{ padding: "0 10px" }}>
                                  ---
                                </CustomText>
                              )}
                            </Typography>
                          </div>
                        ))}
                      </CharacterWrapper>
                    </div>
                  </>
                )}
                {preference === "noOfChats" && (
                  <Box sx={{ p: "0px 20px" }}>
                    <ChatCountBox>
                      Number of chats{" "}
                      <CustomText
                        variant="title_4"
                        sx={{ color: colors.grayLight, textAlign: "end" }}
                      >
                        {noOfChats.length || 0}
                      </CustomText>
                    </ChatCountBox>
                  </Box>
                )}
              </Box>
            )}
          {preference === "noOfAdmirers" && <NoOfAdmirers />}
          {preference === "noOfAdmiring" && <NoOfAdmiring />}
          {preference === "noOfMatches" && (
            <NoOfMatches attributeStatus={profile_data?.choose_any_one} />
          )}
          {verifyModel.type === "facebook" && (
            <VerifyModel
              data={"facebook"}
              open={verifyModel}
              handleOk={handleVerification}
              setOpen={() =>
                setVerifyModel({ type: "facebook", status: false })
              }
            />
          )}
          {verifyModel.type === "camera" && (
            <VerifyModel
              data={"camera"}
              open={verifyModel}
              handleOk={handleVerification}
              setOpen={() => setVerifyModel({ type: "camera", status: false })}
            />
          )}
          {verifyModel.type === "gov" && (
            <VerifyModel
              data={"government id"}
              open={verifyModel}
              handleOk={handleVerification}
              setOpen={() => setVerifyModel({ type: "gov", status: false })}
            />
          )}
        </Card>
        {loader && <CustomLoader />}
      </PermissionWrapper>
    </>
  );
};

export default memo(Profile);

const ButtonWarpper = styled(Button)({
  float: "left",
  margin: "0px 20px",
  paddingBottom: "11px",
  // borderRadius: 'none',
  "& .MuiButton-root ": {
    borderRadius: "0px !improtant",
  },
});

const PermissionWrapper = styled(Box)({
  marginTop: "20px",
});

const UserDeatils = styled.div`
  width: 100%;
  min-height: 132px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 15px;
  background-color: white;
  gap: 10px;
`;

const CharacterWrapper = styled.div`
  width: 100%;
  min-height: 132px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 10px 15px;
  background-color: white;
  height: 180px;
  overflow: auto;
`;

const AbouWarpper = styled.div`
  // width: 100%;
  min-height: 125px;
  border: 1px solid red;
  border-radius: 10px;
  padding: 10px 15px;
  border: 1px solid #e2e2e2;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

const ButtonStyle = styled(Button)({
  width: "95px",
  height: "24px",
  boxShadow: "0px 16px 32px rgba(20, 30, 73, 0.15)",
  borderRadius: "5px",
  // background: "linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)",
  float: "right",
  marginRight: "15px",
});

const VerifyWrapper = styled.div`
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 10px 15px;
  background-color: white;
  gap: 20px;
`;

const ImageStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

const Facbookimgwrapper = styled.div`
  // width: 100%;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: white;
  gap: 10px;
`;

const ChatCountBox = styled(Box)({
  borderRadius: "10px",
  padding: "0px 20px 0px 20px",
  display: "flex",
  alignItems: "center",
  background: colors.list_background,
  width: "300px",
});
