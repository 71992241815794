import {
  DELETE_ADMIN_POST_RESPONSE,
  GET_ALL_POST_RESPONSE,
  GET_CREATE_POST_RESPONSE,
} from "./type";

const initialState = {
  post_create_loader: false,
  all_post_loader: false,
  delete_post_loader: false,
};

function postReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CREATE_POST_RESPONSE:
      return {
        ...state,
        post_create_loader: action.payload.status,
        // all_post_loader:action.payload.allPostLoader
      };
    case GET_ALL_POST_RESPONSE:
      return {
        ...state,
        post_users: action.payload.data,
        all_post_loader: action.payload.loader,
        delete_post_loader: !action.payload.loader,
      };
    case DELETE_ADMIN_POST_RESPONSE:
      return {
        ...state,
        delete_post_loader: action.payload,
      };
    default:
      return state;
  }
}

export default postReducer;
