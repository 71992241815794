import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { LogoutIcon, ProfileIcon } from "../../theme/SvgIcons";
import { useNavigate } from "react-router-dom";
import { CustomFlex } from "../../utils/common_functions";

export default function Options(props) {
  const { handleLogoutClick, handleClose } = props;
  const navigate = useNavigate();

  const handleList = () => {
    navigate("/admin/update-profile");
    handleClose();
  };

  return (
    <>
      <ListItem
        onClick={handleList}
        style={{ background: "inherit", cursor: "pointer" }}
      >
        <CustomFlex sx={{ gap: "10px" }}>
          <ProfileIcon />
          <ListItemText primary="Profile" />
        </CustomFlex>
      </ListItem>
      <ListItem
        style={{ background: "inherit", cursor: "pointer" }}
        onClick={handleLogoutClick}
      >
        <CustomFlex sx={{ gap: "10px" }}>
          <LogoutIcon />
          <ListItemText primary="Logout" />
        </CustomFlex>
      </ListItem>
    </>
  );
}
