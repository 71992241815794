const colors = {
  blackDark: "#484848",
  Gray90: "#E5E5E5",
  gray91: "#E8ECEE",
  black: "#000000",
  darkBlue: "#2196F3",
  darkBlackGray: "#11212E",
  darkGreenBlue: "#2B567266",
  white: "#FFFFFF",
  ghostWhite: "#F1F4FF",
  darkSkyBlue: "#0000E9",
  grayLight: "#7B7F91",
  grayLight91: "#F7F7FA",
  gray90: "#F0F0F5",
  lightRed: "#FB896B",
  info: "#996BFB",
  rudyBlue: "#6BA5FB",
  lightPink: "#FB6BB0",
  marginBlue: "#6BBFFB",
  cancelRed: "#FB6B6B",
  gray92: "#C2C2C280",
  darkGreen: "#489F80",
  ghostWhite91: "#FCFCFF",
  nero: "#242424",
  redError: "#d32f2f",
  parrotGreen: "#33C325",
  darkblackblue: "#253CA5",
  skyblueLight: "#EDF8FF",
  gray93: "#DDE2F9",
  Gray8: "#141414",
  pink80: "#FD38AE",
  bagdePink: "#F237AE",
  green82: "#0ACF83",
  gray68: "#ADADAD",
  Gray94: "#EDEDED",
  bgDelete: "#F53131",
  silver: "#C4C4C4",
  lightOrange: "#FA896B",
  green93: "#51B247",
  nightBlue: "#6F6AF7",
  blue92: "#8CC1E9",
  activeGreen: "#44b700",
  parrotGreenLight: "#37ED6A",
  redLight80: "#F3896C",
  gray95: "#949494",
  whiteGrey: "#FBFCFD",
  gray67: "#D9D9D9",
  gray66: "#E2E2E2",
  pinkred: "#FF483C",
  paleRose: '#FFDCEB',
  transparentBlue: '#DCDDFF',
  lightGreen: '#EDF4E1',
  grayCard: '#E3E3E3',
  yellowLight: '#FFF5DC',
  errorLight: '#FFDCDC',
  creamGray: '#F4F4F4',
  lightGray_opacity:"rgb(217, 217, 217,0.6)",
  green:"green",
  list_background:"#F0F0F0",
  brown:"#A8580F"
};

export default colors;