import React, { useEffect, useState } from "react";
import { UploadImage } from "../../theme/SvgIcons";
import styled from "@emotion/styled";
import { CustomFlex } from "../../utils/common_functions";
import colors from "../../theme/colors";
import CustomText from "./CustomText";
import { video_extensions } from "../../utils/constant";

const CustomizeFileUpload = (props) => {
  const { onChange, error, helperText, value } = props;
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  useEffect(() => {
    if (value === undefined) setSelectedFileUrl(null);
  }, [value]);

  const imageUploader = (event) => {
    const fr = new FileReader();
    let fileExtension = event.target.files[0]?.name.split(".").at(-1);
    let type = video_extensions.includes(fileExtension) ? 1 : 0;
    if (type === 1) setFileType("video");
    else setFileType("image");
    if (event.target.files[0] !== undefined) {
      fr.readAsArrayBuffer(event.target.files[0]);
      fr.onload = function () {
        // you can keep blob or save blob to another position
        const blob = new Blob([fr.result]);
        // url for download
        const url = URL.createObjectURL(blob, { type: "image/* , video/*" });
        setSelectedFileUrl(url);
      };
    }
    onChange(event.target.files);
  };

  const handleClearImage = () => {
    setSelectedFileUrl(null);
    onChange(null);
  };

  return (
    <FileUploadField hasError={error}>
      <input
        accept="image/* , video/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={imageUploader}
      />
      <div className="container">
        {selectedFileUrl === null ? (
          <CustomFlex
            sx={{ justifyContent: "center", height: "100%", width: "100%" }}
          >
            <label htmlFor="contained-button-file">
              <UploadImage />
              <CustomText variant="gray_title">Upload Images</CustomText>
            </label>
          </CustomFlex>
        ) : (
          <div className="imageWrapper">
            {fileType === "image" ? (
              <img width="auto" height="140px" src={selectedFileUrl} alt="" />
            ) : (
              <video
                autoPlay
                muted
                src={selectedFileUrl}
                loop
                width="auto"
                height="140px"
                controls
              />
            )}
            <div className="closeIcon" onClick={handleClearImage}>
              x
            </div>
          </div>
        )}
      </div>
      {error && <CustomText variant="error-content">{helperText}</CustomText>}
    </FileUploadField>
  );
};

export default CustomizeFileUpload;

const FileUploadField = styled.div`
  input {
    display: none;
  }
  label {
    cursor: pointer;
  }
  .container {
    width: 100%;
    border: 1px solid grey;
    background: #ffffff;
    border: 1px solid
      ${(props) => (props.hasError ? colors.redError : colors.Gray90)};
    border-radius: 10px;
    height: 162px;
    padding: 10px 0px;
  }
  .imageWrapper {
    position: relative;
  }
  .closeIcon {
    position: absolute;
    right: 10px;
    top: -6px;
    cursor: pointer;
  }
`;
