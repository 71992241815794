import {
  BLOCKED_RES_STATUS,
  GET_BLOCKED_USER_RES,
  GET_SUSPENDED_USER_RES,
  SUSPENDED_RES_STATUS,
} from "./type";

const initialState = {
  suspend_user: [],
  suspend_loading: true,

  blocked_user: [],
  blocked_loading: true,
};

function suspendReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUSPENDED_USER_RES:
      return {
        ...state,
        suspend_user: action?.payload?.data,
        suspend_count: action?.payload?.extra,
        suspend_loading: action?.status,
      };
    case SUSPENDED_RES_STATUS:
      return {
        ...state,
        suspend_loading: false,
      };

    case GET_BLOCKED_USER_RES:
      return {
        ...state,
        blocked_user: action.payload?.data,
        block_count: action?.payload?.extra,
        blocked_loading: action?.status,
      };
    case BLOCKED_RES_STATUS:
      return {
        ...state,
        blocked_loading: false,
      };
    default:
      return state;
  }
}

export default suspendReducer;
