import { Box, Card, IconButton } from "@mui/material";
import CustomTable from "../form/CustomTable";
import CustomText from "../form/CustomText";
import { ViewIcon } from "../../theme/SvgIcons";
import { CustomLoader, SearchField } from "../../utils/common_functions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserView, getAllRejectUser } from "../../redux/users/action";
import { useEffect, useState } from "react";
import NavBar from "../navbar";
import { column } from "./VerifyUsers";
import colors from "../../theme/colors";

const UserLists = () => {
  const { reject_users, reject_list_loader, reject_count } = useSelector(
    (e) => e.users
  );
  const { isLoading } = useSelector((e) => e.userInfo);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileView = (id) => {
    dispatch(getUserView(id));
    navigate(`/admin/profile/id=${id}`, {
      state: { name: "reject" },
    });
  };

  useEffect(() => {
    if (reject_users === undefined) {
      dispatch(
        getAllRejectUser(
          `?page=${page + 1}&limit=${rowsPerPage}&search=${searchText}`
        )
      );
    }
  }, [reject_users]);

  // useEffect(() => {
  //   dispatch(getAllRejectUser(""));
  // }, []);

  const rows = (isFilterUser) => {
    if (Array.isArray(isFilterUser)) {
      if (isFilterUser) {
        return isFilterUser.map((el, index) => ({
          name: <CustomText variant="body_1">{el.name}</CustomText>,
          user_name: (
            <CustomText variant="gray_title">{el.user_name}</CustomText>
          ),
          email: <CustomText variant="gray_title">{el.email}</CustomText>,
          Refer_to_data: (
            <CustomText variant="gray_title">
              {el?.Refer_to_data[0]?.Refer_by_data?.user_name}
            </CustomText>
          ),
          country: <CustomText variant="gray_title">{el.country}</CustomText>,
          createdAt: (
            <CustomText variant="gray_title">
              {el.createdAt.split("T")[0]}
            </CustomText>
          ),
          action: (
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              onClick={() => profileView(el.id)}
            >
              <ViewIcon />
            </IconButton>
          ),
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const brcrmb = [
    { name: "Users", path: "", noRedirect: true },
    { name: "Reject", path: "", noRedirect: true },
  ];

  const handleGetListingData = (newPage, rowsPerPage, searchText = "") => {
    const queryParam = `?page=${
      newPage + 1
    }&limit=${rowsPerPage}&search=${searchText}`;
    dispatch(getAllRejectUser(queryParam));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleGetListingData(newPage, rowsPerPage, searchText);
  };

  const handleChangeRowsPerPage = (event) => {
    let rowPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(rowPerPage);
    handleGetListingData(page, rowPerPage, searchText);
  };

  let ref;
  useEffect(() => {
    if (reject_users !== undefined) {
      setPage(0);
      ref = setTimeout(
        () => handleGetListingData(0, rowsPerPage, searchText),
        1000
      );
    }
    return () => clearTimeout(ref);
  }, [searchText]);

  return (
    <>
      <NavBar option={brcrmb} />
      <Card sx={{ m: "20px 36px 50px 36px" }}>
        <Box sx={{ m: "30px", display: "flex", alignItems: "center" }}>
          <CustomText
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              width: "60px",
              color: `${colors.nero}!important`,
            }}
          >
            Reject{" "}
          </CustomText>
          <CustomText
            sx={{
              fontSize: "16px",
              textAlign: "start",
              color: colors.grayLight,
              fontWeight: 400,
            }}
          >
            {" "}
            - Show all rejected List.
          </CustomText>
        </Box>
        <Box sx={{ m: "30px", display: "flex", alignItems: "start" }}>
          <SearchField
            sx={{
              maxWidth: "579px",
              "& .MuiOutlinedInput-input": {
                background: "rgba(168, 88, 15, 0.06) !important",
              },
              background: "rgba(168, 88, 15, 0.06) !important",
            }}
            onChange={(e) => setSearchText(e)}
          />
        </Box>
        {isLoading ? (
          <CustomLoader />
        ) : (
          <CustomTable
            rows={rows(reject_users)}
            headCells={column}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            totalCount={reject_count}
          />
        )}
      </Card>
    </>
  );
};
export default UserLists;
