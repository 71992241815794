import { takeLatest, call, put } from "redux-saga/effects";
import {
  BLOCK_USER,
  DELETE_LOADER_UPDATE,
  DELETE_USER,
  GET_ADMIRERS,
  GET_ADMIRING,
  GET_MATCHES,
  GET_REPORTED_USER,
  SUSPEND_USER,
} from "./type";
import {
  blockUserRoute,
  deleteUserRoute,
  gerAdmirersRoute,
  getAdmiringUsersRoutes,
  matchesUserRoute,
  reportedUserRoute,
  suspendUserRoute,
} from "./apiRoutes";
import {
  blockUserRes,
  deleteUserRes,
  failGetUser,
  getAdmieresRes,
  getAdmiringRes,
  getMatchesRes,
  getReportedUserRes,
  suspendUserRes,
  updateDeleteLoaderRes,
} from "./action";
import { toast } from "react-toastify";

function* getReportedUserGenerator(action) {
  const { queryParam } = action || {};
  try {
    const res = yield call(reportedUserRoute, queryParam);
    if (res.status === 200) {
      yield put(getReportedUserRes(res,false));
    }
  } catch (error) {
    yield put(failGetUser());
    toast.error("Something went Wrong");
  }
}

function* getAdmirersGenerator(data) {
  try {
    const res = yield call(gerAdmirersRoute, data?.payload);
    if (res.status === 200) {
      yield put(getAdmieresRes(res?.data));
    }
  } catch (error) {
    toast.error("Something went Wrong");
    yield put(getAdmieresRes(error));
  }
}

function* getAdmiringGenerator(data) {
  try {
    const res = yield call(getAdmiringUsersRoutes, data?.payload);
    if (res.status === 200) {
      yield put(getAdmiringRes(res?.data));
    }
  } catch (error) {
    toast.error("Something went Wrong");
  }
}

function* deleteUserGenerator(data) {
  try {
    const res = yield call(deleteUserRoute, data?.payload);
    if (res.status === 200) {
      yield put(deleteUserRes(true));
      toast.success(res?.message || "Deleted");
    }
  } catch (error) {
    yield put(deleteUserRes(true));
    toast.error("Something went Wrong");
  }
}

function* blockUserGenerator(data) {
  try {
    const res = yield call(blockUserRoute, data?.payload);
    if (res.status === 200) {
      yield put(blockUserRes(res?.data));
      toast.success(res?.message || "Blocked");
    }
  } catch (error) {
    yield put(blockUserRes(error));
    toast.error("Something went Wrong");
  }
}

function* suspendUserGenerator(data) {
  try {
    const res = yield call(suspendUserRoute, data?.payload);
    if (res.status === 200) {
      yield put(suspendUserRes(res?.data));
      toast.success(res?.message || "Blocked");
    }
  } catch (error) {
    yield put(suspendUserRes(error));
    toast.error("Something went Wrong");
  }
}

function* getMatchesGenerator(data) {
  const { user_id, attributeStatus } = data || {};
  try {
    const res = yield call(matchesUserRoute, user_id, attributeStatus);
    if (res.status === 200) {
      yield put(getMatchesRes(res?.data));
    } else toast.error(res?.message || "Something went Wrong");
  } catch (error) {
    yield put(getMatchesRes(error));
    toast.error("Something went Wrong");
  }
}

function* deleteLoaderUpdateGenerator() {
  try {
    yield put(updateDeleteLoaderRes(false));
  } catch (error) {
    yield put(updateDeleteLoaderRes(false));
  }
}
function* reportedSaga() {
  yield takeLatest(GET_REPORTED_USER, getReportedUserGenerator);
  yield takeLatest(GET_ADMIRERS, getAdmirersGenerator);
  yield takeLatest(GET_ADMIRING, getAdmiringGenerator);
  yield takeLatest(DELETE_USER, deleteUserGenerator);
  yield takeLatest(BLOCK_USER, blockUserGenerator);
  yield takeLatest(SUSPEND_USER, suspendUserGenerator);
  yield takeLatest(DELETE_LOADER_UPDATE, deleteLoaderUpdateGenerator);
  yield takeLatest(GET_MATCHES, getMatchesGenerator);
}

export default reportedSaga;
