import {
  GET_TESTIMONIAL,
  GET_TESTIMONIAL_RESPONSE,
  TESTIMONIAL_LOADER_STATUS_UPDATE,
} from "./type";

export const getTestimonials = (queryParam) => {
  return {
    type: GET_TESTIMONIAL,
    queryParam,
  };
};

export const getTestimonialsResponse = (data) => {
  return {
    type: GET_TESTIMONIAL_RESPONSE,
    payload: data,
  };
};

export const updateTestimonialLoader = (data) => {
  return {
    type: TESTIMONIAL_LOADER_STATUS_UPDATE,
    payload: data,
  };
};
