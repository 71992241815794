import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import colors from "../../theme/colors";
import CustomButton from "./CommonButton";
import styled from "@emotion/styled";
import CustomText from "../form/CustomText";


export default function ChildModel(props) {
  const { open, closeModal, children, title,customeButton } = props;

  return (
    <Dialog open={open} onClose={closeModal} style={{maxHeight:"fit-content"}}>
      <ContentWrapper>
      {title && <CustomText variant='title_5'>{title}</CustomText>}
        {children}
        <ButtonWrapper>
          {
            customeButton?.length ? customeButton.map((li,index)=>(
              <CustomButton
              key={index}
              {...li}
          />
            ))
          :
          <CustomButton
            label="Back"
            width="120px"
            onClick={closeModal}
            bg={colors.list_background}
            border="none"
            //   disabled={loader}
            color={colors.black}
          />
            }
        </ButtonWrapper>
      </ContentWrapper>
    </Dialog>
  );
}

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
`;

const ContentWrapper = styled(Box)`
  padding: 15px 40px;
  width: 450px;
`;
