export const video_extensions = [
  "mpeg-2",
  "webm",
  "html5",
  "mkv",
  "swf",
  "f4v",
  "flv",
  "avchd",
  "avi",
  "wmv",
  "mov",
  "mp4",
];

export const mobile_size = "(max-width: 800px)";

export const url_path_index = {
  "/admin/dashboard": 0,
  "/admin/reported": 2,
  "/admin/suspended": 3,
  "/admin/block-user": 4,
  "/admin/feedback": 5,
  "/admin/testimonial": 6,
  "/admin/my-posts": 7,
  "/admin/change-password": 8,
  "/admin/update-profile": 9,
};

export const brcmb_subsection = {
  "de-activate": "De-Activate",
  "approval-waiting": "Approval-Waiting",
  "register-user": "Register-User",
  "under-review": "Under-Review",
  verified: "Verified",
  reject: "Reject",
};

export const brcmb_subsection_link = {
  "de-activate": "/admin/deactive-user",
  "approval-waiting": "/admin/waiting-approval",
  "register-user": "/admin/register-user",
  "under-review": "/admin/under-review",
  verified: "/admin/users-verify",
  reject: "/admin/reject-user",
};

export const questions_list = [
  {
    question: "People say I am dependable and reliable",
    answer_key: "character_am_dependable_and_reliable",
  },
  {
    question: "I am very outgoing and can make anywhere exciting",
    answer_key: "character_am_outgoing_and_make_anywhere_exciting",
  },
  {
    question: "I am my best under pressure",
    answer_key: "character_am_best_under_pressure",
  },
  {
    question:
      "I am very forgiving in a relationship, even if my partner cheats",
    answer_key: "character_am_forgiving",
  },
  {
    question: "My family and friends heavily influence my decisions",
    answer_key: "character_am_decisional",
  },

  {
    question: "I cannot date someone below my socio-economic class",
    answer_key: "character_cannot_date_below_my_socio_economic",
  },
  {
    question: "People say I am a prude, and that is fine by me",
    answer_key: "character_am_prude",
  },
  {
    question: "I am very judgemental",
    answer_key: "character_am_judgemental",
  },
  {
    question: "Folks see me as a good guy",
    answer_key: "character_folks_see_me_as_goodguy",
  },
  {
    question: "I am a rebel, never do things the normal way",
    answer_key: "character_am_rebel_never_do_things_normal_way",
  },

  {
    question: "I don’t care what people say about me",
    answer_key: "character_i_dont_care_what_people_say_aboutme",
  },
  {
    question: "I am a very strong willed person",
    answer_key: "character_am_strong_willed_person",
  },
  {
    question: "I am a people person",
    answer_key: "character_am_people_person",
  },
  {
    question: "Sex/Chemistry is my #1 attraction in relationship",
    answer_key: "character_attraction_in_relationship",
  },
  {
    question: "Character is my #1 attraction to an individual",
    answer_key: "character_attraction_in_individual",
  },
];

export const verification_question = [
  {
    question: "Have you ever been involved in taking hard drugs?*",
    answer_key: "Taking_hard_drugs",
  },
  {
    question: "Have you been involved in criminal activity?*",
    answer_key: "criminal_activity",
  },
  {
    question: "Have you been involved in sexual harassment?*",
    answer_key: "sexual_harassment",
  },
  {
    question: "Have you been involved in domestic violence? *",
    answer_key: "domestic_violence",
  },
  {
    question: `Have you been convicted of any criminal activity involving hard drugs and financial crimes?`,
    answer_key: "any_criminal_activity_hard_drugs_and_financial_crimes",
  },
  {
    question: `Have you been convicted in activity involving sexual harassment or domestic violence? *`,
    answer_key: `activity_involving_sexual_harassment_domestic_violence`,
  },
  {
    question: "Have been involved in other serious crimes? *",
    answer_key: "serious_crime",
  },
];

export const profile_tab_buttons = [
  {
    name: "Profile",
    key: "Profile",
    value: 0,
  },
  {
    name: "Preference",
    key: "Preferences",
    value: 1,
  },
  {
    name: "Verification",
    key: "Verification",
    value: 2,
  },
  {
    name: "Number of admirers",
    key: "noOfAdmirers",
    value: 3,
  },
  {
    name: "Number of admiring",
    key: "noOfAdmiring",
    value: 4,
  },
  {
    name: "Number of matches",
    key: "noOfMatches",
    value: 6,
  },
  {
    name: "Number of chats",
    key: "noOfChats",
    value: 5,
  },
];
