import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import colors from "../../theme/colors";
import styled from "@emotion/styled";
import CustomButton from "./CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { getUsersVerify } from "../../redux/users/action";
import { useParams } from "react-router-dom";
import CustomText from "../form/CustomText";
import { labelStyle } from "../Profile/style";
import { toast } from "react-toastify";

export default function CustomModal(props) {
  const { open, setOpen, user, setLoader, loader } = props;
  const [value, setValue] = React.useState("");

  const dispatch = useDispatch();

  const params = useParams();
  const detail_id = params?.id.split("=")[1];
  const profile_data = useSelector((e) => e.users.profile_data);

  const handleButton1Click = () => {
    const {
      photo_verification_status,
      facebook_verification_status,
      government_id_verification_status,
      mobile_number_verification_status,
      user_verification,
    } = profile_data || {};
    const status =
      photo_verification_status ||
      facebook_verification_status ||
      government_id_verification_status ||
      mobile_number_verification_status;
    if (profile_data?.user_status === "0" && !status) {
      toast.info("You,First needs to verify the user.");
      setOpen(false);
      return;
    } else if (profile_data?.user_status === "1" && value === "")
      toast.info("Please enter the reason first.");
    else {
      const user = {
        userId: detail_id,
        status: profile_data?.user_status === "1" ? "0" : "1",
        message: value,
        attributeStatus: profile_data?.choose_any_one,
      };
      setLoader(true);
      setOpen(false);
      dispatch(getUsersVerify(user));
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen()}>
        <ConfirmBox>
          {profile_data?.user_status === "1" ? (
            <>
              {" "}
              <CustomText
                sx={{ fontSize: "26px", fontWeight: 600, mb: "10px" }}
              >
                Add your message
              </CustomText>
              <CustomText variant="body1_medium">Give Reasons</CustomText>
              <TextArea onChange={(e) => setValue(e.target.value)} />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <ImgStyle src="/likeplaylogo.png" alt={""} />
              <Typography
                sx={{
                  ...labelStyle(colors.black, "14px", 500),
                  textAlign: "center",
                  paddingBottom: "10px",
                  mt: "2rem",
                }}
              >
                {`Are you sure you want to ${
                  user?.user_status !== "1" ? "activate" : "deactivate"
                } ${user?.name}`}
              </Typography>
            </Box>
          )}

          <ButtonWrapper>
            <CustomButton
              label="Back"
              width="120px"
              onClick={() => setOpen(false)}
              bg={"#F0F0F0"}
              border="none"
              disabled={loader}
              color={colors.black}
            />
            <CustomButton
              iconAlign={"left"}
              label="Submit"
              width="120px"
              disabled={loader}
              onClick={handleButton1Click}
              bg={"linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)"}
              color={colors.white}
            />
          </ButtonWrapper>
        </ConfirmBox>
      </Dialog>
    </>
  );
}

const ConfirmBox = styled(Box)`
  width: 446px;
  height: 300px;
  display: flex;
  background: colors.ghostWhite;
  padding: 30px;
  flex-direction: column;
`;

const TextArea = styled.textarea({
  border: `1px solid ${colors.gray66}`,
  borderRadius: "10px",
  marginTop: "6px",
  minHeight: "100px",
  padding: "10px",
});

const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px 40px;
`;

const ImgStyle = styled.img({
  height: "80px",
  width: "80px",
});
