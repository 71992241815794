import {
  CREATE_POST,
  DELETE_ADMIN_POST,
  DELETE_ADMIN_POST_RESPONSE,
  GET_ALL_POSTS,
  GET_ALL_POST_RESPONSE,
  GET_CREATE_POST_RESPONSE,
} from "./type";

export const createPost = (data) => {
  return {
    type: CREATE_POST,
    payload: data,
  };
};

export const getCreatePostResponse = (data) => {
  return {
    type: GET_CREATE_POST_RESPONSE,
    payload: data,
  };
};

export const getAdminPosts = () => {
  return {
    type: GET_ALL_POSTS,
  };
};

export const getALLPostResponse = (data) => {
  return {
    type: GET_ALL_POST_RESPONSE,
    payload: data,
  };
};

export const deleteAdminPost = (id) => {
  return {
    type: DELETE_ADMIN_POST,
    post_id: id,
  };
};

export const deleteAdminPostResponse = (data) => {
  return {
    type: DELETE_ADMIN_POST_RESPONSE,
    payload: data,
  };
};
