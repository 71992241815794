import axios from "axios";
import { getDataFromLocalStorage } from "./common_functions";
import store from "../redux/store";
import { setLoader } from "../redux/users/userSlice";

const base_url = process.env.REACT_APP_API_URL;

const axiosInterceptor = axios.create({
  timeout: 60000,
  baseURL: base_url,
});
const userData = getDataFromLocalStorage("admin_detail");
axiosInterceptor.interceptors.request.use(
  (config) => {
    store.dispatch(setLoader(true));
    config.headers["Authorization"] = `Bearer ${userData?.token}`;
    config.headers["Accept"] = "application/json";
    return config;
  },
  (error) => {
    store.dispatch(setLoader(false));
    return Promise.reject(error);
  }
);

axiosInterceptor.interceptors.response.use(
  (response) => {
    store.dispatch(setLoader(false));
    return response.data;
  },
  (error) => {
    store.dispatch(setLoader(false));
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
