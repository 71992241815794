import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { CustomLoader } from "../../utils/common_functions";
import { PostUploadFormWrapper, ButtonStyle } from "./style";
import CustomizeFileUpload from "../form/CustomizeFileUpload";
import { useDispatch, useSelector } from "react-redux";
import { createPost } from "../../redux/post/action";
import { video_extensions } from "../../utils/constant";
import EmojiTextAreaField from "../form/CustomEmoji_TextArea";
import uploadFileToS3 from "../../utils/s3bucket";

export default function UploadPostForm() {
  const { register, handleSubmit, control, reset } = useForm();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { post_create_loader } = useSelector((e) => e.post);

  const onSubmit = async (data) => {
    let fileExtension = data.file[0]?.name.split(".").at(-1);
    let type = video_extensions.includes(fileExtension) ? 1 : 0;
    setLoader(true);
    const pathName = Date.now() + "-" + data?.file[0]?.name;
    // const fd = new FormData();
    // fd.append("caption", data.caption);
    // fd.append("media_type", type);
    // fd.append("media_url", data?.file[0], data?.file[0]?.name);
    // fd.append("file", pathName);
    const obj = {
      caption: data.caption,
      media_type: type,
      file: pathName,
    };

    const ApiCall = () => {
      dispatch(createPost(obj));
    };
    uploadFileToS3(data?.file[0], pathName, ApiCall);
    reset();
  };

  useEffect(() => {
    if (post_create_loader) {
      setLoader(false);
    }
  }, [post_create_loader]);

  return (
    <PostUploadFormWrapper>
      {loader ? (
        <CustomLoader />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <div className="label">Take the Floor</div>
            <Controller
              name="file"
              control={control}
              rules={{
                required: "File is required",
              }}
              render={({
                fieldState: { error },
                field: { onChange, value },
              }) => (
                <CustomizeFileUpload
                  // register={{ ...register("file") }}
                  error={!!error}
                  type="file"
                  value={value}
                  placeholder="Upload Image"
                  helperText={error ? error.message : null}
                  onChange={onChange}
                  multiple
                  accept="image/*"
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth>
            <div className="label">Take the Mic</div>
            <Controller
              name="caption"
              control={control}
              rules={{
                required: "caption is required",
              }}
              render={({
                fieldState: { error },
                field: { onChange, value },
              }) => (
                <EmojiTextAreaField
                  register={{ ...register("caption") }}
                  error={!!error}
                  placeholder="Write Something..."
                  minRows={6}
                  value={value}
                  helperText={error ? error.message : null}
                  onChange={onChange}
                />
              )}
            />
          </FormControl>
          <ButtonStyle type="submit">Submit</ButtonStyle>
        </form>
      )}
    </PostUploadFormWrapper>
  );
}
