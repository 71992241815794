import { SIGN_IN_RES } from './type';

const initialState = {
    sign_in_loader: {}
};

function signInReducer(state = initialState, action) {
    switch (action.type) {
        case SIGN_IN_RES:
            return {
                ...state,
                sign_in_loader: action.payload
            };
        default:
            return state;
    }
}

export default signInReducer;
