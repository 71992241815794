import { takeLatest, call, put } from 'redux-saga/effects';
import { SIGN_IN } from './type';
import { signIn } from './apiRoute';
import { setDataInLocalStorage } from '../../utils/common_functions';
import { signInAdminRes } from './action';
import { toast } from 'react-toastify';

function* signInGenerator(action) {
    const { data, navigate } = action.payload || {};
    try {
        let res = yield call(signIn, data);
        yield put(signInAdminRes(res?.data))
        if (res && res?.status === 200) {
            toast.success(res?.status?.message || 'Login Successfully')
            setTimeout(() => {
                setDataInLocalStorage('admin_detail', res?.data)
                window.location.reload();
            }
                , 1000);
            navigate('/admin/waiting-approval')
        }

    } catch (error) {
        yield put(signInAdminRes(error?.response))
        if (error) {
            toast.error(error?.response?.data.message || 'Something went wrong!')
        }
    }
}

function* signInSaga() {
    yield takeLatest(SIGN_IN, signInGenerator);
}

export default signInSaga;
