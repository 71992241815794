import { all, fork } from 'redux-saga/effects';
import signInSaga from './sign-in/signInSaga';
import usersSaga from './users/usersSaga';
import dashboardCountSaga from './dashboard/dashboardSaga';
import reportedSaga from './reported/reportedSaga';
import postSaga from './post/postSaga';
import suspendedSaga from './suspended/suspendedSaga';
import changePasswordSaga from './changePassword/changePasswordSaga';
import testimonialSaga from './testimonial/testimonialSaga';
import updateProfileSaga from './update-profile/updateProfileSaga';

function* rootSaga() {
  yield all(
    [
      fork(signInSaga),
      fork(usersSaga),
      fork(dashboardCountSaga),
      fork(reportedSaga),
      fork(postSaga),
      fork(suspendedSaga),
      fork(changePasswordSaga),
      fork(testimonialSaga),
      fork(updateProfileSaga)
    ]
  );
}

export default rootSaga;
