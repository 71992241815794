import * as React from "react";
import Dialog from "@mui/material/Dialog";
// import { ConfimDeleteIcon, LogoIcon } from "../../theme/SvgIcons";
import { Box, Typography } from "@mui/material";
// import { labelStyle } from "../employee/style";
import colors from "../../theme/colors";
import styled from "@emotion/styled";
import { labelStyle } from "../Profile/style";
import CustomButton from "./CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { userVerifyApi } from "../../redux/users/apiRoute";
import {
  getUsersVerify,
  getUserView,
  UsersImotf,
} from "../../redux/users/action";
import { useParams } from "react-router-dom";

export default function Imotf(props) {
  const { open, setOpen, setLoader } = props;

  const dispatch = useDispatch();

  const params = useParams();
  const detail_id = params?.id.split("=")[1];

  const handleButton1Click = () => {
    const user = {
      user_id: detail_id,
      floor_me_by_admin: true,
    };
    dispatch(UsersImotf(user));
    setLoader(true)
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen()}>
      <ConfirmBox>
        <MainContainerModal style={{ margin: "40px auto" }}>
          <DeleteWrapper>
            <div style={{ position: "absolute", top: "18px", left: "0px" }}>
              {/* <LogoIcon /> */}
              <img
                src="/likeplaylogo.png"
                alt={"altText"}
              // style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </div>
          </DeleteWrapper>
          <DelText style={{ marginTop: "25px", padding: "0px 60px" }}>
            <Typography
              sx={{
                ...labelStyle(colors.black, "14px", 500),
                textAlign: "center",
                paddingBottom: "10px",
              }}
            >
              {/* {`Are you sure you want to convert */}
              Are you sure you want to convert profile image into feed.
            </Typography>
          </DelText>
          <div style={{ width: "80%", margin: "0 auto" }}>
            <ButtonWrapper
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "space-between",
                padding: "15px 20px",
                width: "100%",
              }}
            >
              <div>
                <CustomButton
                  iconAlign={"left"}
                  label="NO"
                  width="120px"
                  onClick={() => setOpen(false)}
                  bg={colors.white}
                  color={colors.black}
                />
              </div>
              <div>
                <CustomButton
                  iconAlign={"left"}
                  label="YES"
                  width="120px"
                  onClick={handleButton1Click}
                  bg={colors.pinkred}
                  color={colors.white}
                />
              </div>
            </ButtonWrapper>
          </div>
        </MainContainerModal>
      </ConfirmBox>
    </Dialog>
  );
}

const ConfirmBox = styled(Box)`
  width: 446px;
  height: 306.64px;
  display: flex;
  background: colors.ghostWhite;
`;

const MainContainerModal = styled(Box)`
  margin: 40px auto;
`;

const DeleteWrapper = styled(Box)`
  width: 80px;
  height: 80px;
  background: colors.white;
  boxshadow: 0px 14px 28px rgba(0, 0, 0, 0.18);
  borderradius: 50px;
  position: relative;
  margin: 0px auto;
`;

const DelText = styled(Box)`
  margin-top: 10px;
  padding: 0px 60px;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  textalign: "center;
justifyContent: " space-between;
  padding: 15px 40px;
`;
