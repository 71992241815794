import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import colors from "../../theme/colors";
import { Box, TablePagination } from "@mui/material";

export default function CustomTable(props) {
  const {
    isTableBorder,
    rows,
    headCells,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
    totalCount,
  } = props || {};

  const handleChangePage = (event, newPage) => {
    setPage(event, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <TablePagination
          rowsPerPageOptions={[25, 50, 75]}
          component="div"
          count={totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="custom-pagination"
        />
        <TableContainer component={Paper} sx={{ maxHeight: 480 }}>
          <Table
            sx={{
              minWidth: 750,
              border: isTableBorder ? `1px solid ${colors.Gray90}` : "",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: colors.creamGray }}>
                {headCells.map((col, index) => {
                  return <TableCell key={index}>{col.label}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.length ? (
                rows
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: colors.skyblueLight,
                          },
                        }}
                      >
                        {headCells.map((column, columnIndex) => (
                          <TableCell
                            sx={{
                              border: column.isBorder
                                ? `1px solid ${colors.Gray90}`
                                : "",
                            }}
                            key={columnIndex}
                            component="th"
                          >
                            {row[column.id]}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })
              ) : (
                <Box sx={{ alignItems: "center", display: "flex", p: "20px" }}>
                  No Data Found
                </Box>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
