import styled from "@emotion/styled";
import React, { useState } from "react";
import colors from "../../theme/colors";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";

export default function MultiSelection(props) {
  const { options, getSelectedList, maxLimit = -1 } = props;
  const [selectedList, setSelectedList] = useState([]);
  const [selectedKey,setSelectedKey]=useState([])

  const handleSelect = (list) => {
    if (selectedList?.includes(list.value)) {
      let filter_list = selectedList.filter((key) => key !== list.value);
      getSelectedList(filter_list);
      setSelectedList(filter_list);
    } else {
      if (maxLimit === -1 || selectedList.length < maxLimit) {
        getSelectedList([...selectedList, list]);
        setSelectedList((pre) => [...pre, list]);
        setSelectedKey((pre) => [...pre, list.value]);
      } else toast.info(`You can't select more than ${maxLimit} options`);
    }
  }; 
  return (
    <SelectionWrapper>
      {options?.map((list, index) => (
        <Tooltip title={list.reasons} placement="bottom-end">
          <List
            status={!selectedKey?.includes(list.value)}
            onClick={() => handleSelect(list)}
            key={index}
          >
            {list.value}
          </List>
        </Tooltip>
      ))}
    </SelectionWrapper>
  );
}

const SelectionWrapper = styled.div``;

const List = styled.div`
  border-radius: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 10px 0px;
  font-size:14px;
  color: ${(props) => (props.status ? "black" : "white")};
  background: ${(props) =>
    props.status ? colors.list_background : colors.brown};
`;
