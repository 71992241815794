import Typography from "@mui/material/Typography";
import colors from "../../theme/colors";
import { Avatar, Box, Breadcrumbs, Link } from "@mui/material";
import CustomText from "../form/CustomText";
import {
  BoxWrap,
  ChipStyle,
  FlexStyle,
  ImgStyle,
  MenuIconContainer,
  TextBox,
} from "./style";
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage } from "../../utils/common_functions";
import { useState } from "react";
import { sideBar_Responsive } from "../../redux/users/action";
import { useDispatch } from "react-redux";
import { MenuIcon } from "../../theme/SvgIcons";
import { useMediaQuery } from "react-responsive";
import { mobile_size } from "../../utils/constant";
import Options from "./options";
import Logout from "../sidebar/Logout";
import { toast } from "react-toastify";
import BasicMenu from "../basicMenu";

const NavBar = (props) => {
  const isMobile = useMediaQuery({ query: mobile_size });
  const { option } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const navigate = useNavigate();
  const userData = getDataFromLocalStorage("admin_detail");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handletoggle = () => {
    setShow((pre) => !pre);
    dispatch(sideBar_Responsive(!show));
  };

  const handleLogout = () => {
    localStorage.removeItem("admin_detail");
    setTimeout(() => {
      toast.success("Logout Successfully");
      setIsLogout(false);
      window.location.reload();
    }, 500);
  };

  const handleLogoutIcon = () => {
    setAnchorEl(null);
    setIsLogout(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { first_name, last_name } = userData || {};
  return (
    <BoxWrap>
      <>
        <Breadcrumbs sx={{ ml: "36px" }}>
          {option?.map((crmb,index) => {
            return (
              <div key={index}>
                {crmb.noRedirect ? (
                  <Typography
                    sx={{
                      color: colors.nero,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    {crmb.name || ""}
                  </Typography>
                ) : (
                  <Link
                    sx={{ cursor: "pointer" }}
                    component={"span"}
                    onClick={() => navigate(crmb.path)}
                    underline="none"
                    color="inherit"
                    href=""
                  >
                    <Typography
                      sx={{
                        color: colors.grayLight,
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      {crmb.name || ""}
                    </Typography>
                  </Link>
                )}
              </div>
            );
          })}
        </Breadcrumbs>
      </>
      <Box>
        <FlexStyle onClick={handleClick}>
          {/* <Avatar alt={first_name} src="/static/images/avatar/1.jpg" /> */}
          <ImgStyle>
            <img
              height={"38px"}
              width={"auto"}
              src={process.env.REACT_APP_IMAGE_URL + userData?.profile_picture}
              alt=""
            />
          </ImgStyle>
          <TextBox>
            <CustomText>{first_name + last_name}</CustomText>
            <ChipStyle size={"small"} label="Admin" />
          </TextBox>
        </FlexStyle>
        <BasicMenu anchorEl={anchorEl} handleClose={handleClose}>
          <Options
            handleLogoutClick={handleLogoutIcon}
            handleClose={handleClose}
          />
        </BasicMenu>
      </Box>
      {isLogout && (
        <Logout open={isLogout} setOpen={setIsLogout} onClick={handleLogout} />
      )}
      {isMobile && (
        <MenuIconContainer onClick={handletoggle}>
          <MenuIcon />
        </MenuIconContainer>
      )}
    </BoxWrap>
  );
};
export default NavBar;