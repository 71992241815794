import React, { useState } from "react";
import { ProfileInfo, TopSection } from "./style";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { getDataFromLocalStorage } from "../../utils/common_functions";
import CustomMenu from "../form/CustomMenu";

const parseDate = (date, format) => {
  let result_date = moment(date).format(format);
  return result_date;
};

const optionsList = [{ value: "delete", label: "Delete" }];

export default function PostTopSection(props) {
  const { list, setOpenConfirm } = props;

  const admin_info = getDataFromLocalStorage("admin_detail");
  return (
    <TopSection>
      <ProfileInfo>
        <div className="right-section">
          <div className="profile-image">
            <img
              src={
                process.env.REACT_APP_IMAGE_URL + admin_info?.profile_picture
              }
              alt="Profile"
            />
          </div>
          <div className="profile-name">
            <div className="name">
              {admin_info?.first_name + " " + admin_info?.last_name}
            </div>
            <div className="date">
              {parseDate(list.createdAt, "DD MMMM, hh:mm a")}
            </div>
          </div>
        </div>
        <div className="left-section">
          {/* <MoreHorizIcon /> */}
          <CustomMenu
            option={optionsList}
            onChange={(e) =>
              setOpenConfirm({
                type: e.value,
                openModal: true,
                post_id: list.id,
              })
            }
          />
        </div>
      </ProfileInfo>
      <div className="description">{list?.caption}</div>
    </TopSection>
  );
}
