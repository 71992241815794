import * as React from 'react';
import Menu from '@mui/material/Menu';

export default function BasicMenu(props) {
    const {anchorEl ,handleClose, children} = props
  const open = Boolean(anchorEl);

  return (
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{width:"200px"}}
        PaperProps={{  
          style: {  
            width:"150px",  
            borderRadius: "15px",
          },  
       }} 
      >
       {children}
      </Menu>
  );
}