import {
  BLOCKED_RES_STATUS,
  GET_BLOCKED_USER,
  GET_BLOCKED_USER_RES,
  GET_SUSPENDED_USER,
  GET_SUSPENDED_USER_RES,
  SUSPENDED_RES_STATUS,
} from "./type";

export const getSuspendedUser = (queryParam) => {
  return {
    type: GET_SUSPENDED_USER,
    queryParam,
  };
};

export const getSuspendedRes = (res, status) => {
  return {
    type: GET_SUSPENDED_USER_RES,
    payload: res,
    status,
  };
};

export const suspendUserStatus = () => {
  return {
    type: SUSPENDED_RES_STATUS,
  };
};

export const getBlockedUser = (queryParam) => {
  return {
    type: GET_BLOCKED_USER,
    queryParam,
  };
};

export const getBlockedRes = (res) => {
  return {
    type: GET_BLOCKED_USER_RES,
    payload: res,
  };
};

export const blockedUserStatus = () => {
  return {
    type: BLOCKED_RES_STATUS,
  };
};
