import React, { memo, useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import CustomTextField from "../form/CustomTextField";
import CustomText from "../form/CustomText";
import { BoxWrapper, ButtonStyle, CardStyle } from "./style";
import colors from "../../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import { signInAdmin } from "../../redux/sign-in/action";
import { useNavigate } from "react-router-dom";
import { CustomLoader } from "../../utils/common_functions";
import { getAuth, signInAnonymously } from "firebase/auth";

function SignIn() {
  const { register, handleSubmit, control } = useForm();
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sign_in_loader } = useSelector(e => e.signIn)

  const createUserOnFirebase = async () => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  };

  const onSubmit = async (data) => {
    setLoader(true)
    dispatch(signInAdmin({ data, navigate }))
  };

  useEffect(() => {
    if (sign_in_loader?.data === 400 || sign_in_loader === undefined) {
      createUserOnFirebase();
      setLoader(false)      
    }
  }, [sign_in_loader])
  return (
    <>
      {loader ? <CustomLoader /> : <BoxWrapper>
        <CardStyle>
          <img alt='' src='/likeplaylogo.png' />
          <CustomText
            variant={"large_title"}
            sx={{ mt: '30px' }}
          >
            Login
          </CustomText>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
              <CustomText variant="body1_medium" sx={{ mt: "20px", mb: "10px" }}>
                Username/Email
              </CustomText>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Username/Email is required",
                }}
                render={({ fieldState: { error }, field: { onChange } }) => (
                  <CustomTextField
                    sx={{
                      border: `1px solid${colors.gray66} !important`,
                      borderRadius: '10px !important'
                    }}
                    register={{ ...register("email") }}
                    error={!!error}
                    type="email"
                    placeholder={"Email"}
                    helperText={error ? error.message : null}
                    onChange={onChange}
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ mt: '10px' }} fullWidth>
              <CustomText variant="body1_medium" sx={{ mt: "20px", mb: "10px" }}>
                Password
              </CustomText>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: "Password is required",
                }}
                render={({ fieldState: { error }, field: { onChange } }) => (
                  <CustomTextField
                    sx={{
                      border: `1px solid${colors.gray66} !important`,
                      borderRadius: '10px !important'
                    }}
                    register={{ ...register("password") }}
                    label=""
                    error={!!error}
                    type="password"
                    onChange={onChange}
                    placeholder={"Password"}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </FormControl>
            <ButtonStyle type="submit">
              Login
            </ButtonStyle>
          </form>
        </CardStyle>
      </BoxWrapper>}

    </>
  );
}

export default memo(SignIn);
