import styled from "@emotion/styled";
import { Box, Button, Card } from "@mui/material";
import colors from "../../theme/colors";

export const BoxWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  background: "EBEDEF",
  minHeight: "calc(100vh - 99px)",
});

export const ButtonStyle = styled(Button)({
  width: "100%",
  textTransform: "capitalize",
  color: colors.white,
  background: "linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)",
  height: "46px",
  marginTop: "30px",
  borderRadius: "10px",
});

export const CardStyle = styled(Card)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  border: `1px solid ${colors.gray66}`,
  padding: "50px 30px",
  background: colors.white,
  boxShadow: "0px 16px 112px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "22px",
  width: "460px",
  maxHeight: "557px",
  margin: "auto",
});

export const ImageWrapper = styled.div`
  height: 120px;
  width: 120px;
  border-radius: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
`;

export const TextContent = styled.div`
  margin: 15px 0;
  svg {
    cursor: pointer;
  }
`;

export const ChangePasswordContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  margin: 28px 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const UploadIconContainer = styled.div`
  position: relative;
  margin: 10px 0px;
  input {
    display: none;
  }
  label {
    position: absolute;
    cursor: pointer;
    bottom: 2px;
    right: 8px;
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-item: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #242424;
  text-transform: capitalize;
  input {
    font-size: 20px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 5px 2px;
    margin-bottom: 5px;
  }
  p {
    margin: 0px !important;
  }
`;

export const CardWrapper = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  background:${colors.white};
  margin: 10px 20px;
  overflow-y: auto;
  height: calc(100vh - 119px);
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;