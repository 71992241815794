import {
  FEEDBACK_LOADER_STATUS_UPDATE,
  FEEDBACK_STATUS,
  GET_DASHBOARD_USER_COUNT,
  GET_DASHBOARD_USER_COUNT_RES,
  GET_FEEDBACK,
  RESPONSE_STATUS,
} from "./type";

export const getDashboardCount = () => {
  return {
    type: GET_DASHBOARD_USER_COUNT,
  };
};

export const getDashboardCountRes = (res) => {
  return {
    type: GET_DASHBOARD_USER_COUNT_RES,
    payload: res,
  };
};

export const responseStatus = (res) => {
  return {
    type: RESPONSE_STATUS,
    payload: res,
  };
};
export const getFeedback = (queryParam) => {
  return {
    type: GET_FEEDBACK,
    queryParam,
  };
};
export const getFeedbackRes = (res) => {
  return {
    type: FEEDBACK_STATUS,
    payload: res,
  };
};

export const updateFeedbackLoader = (data) => {
  return {
    type: FEEDBACK_LOADER_STATUS_UPDATE,
    payload: data,
  };
};
