import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  UserWrapper,
  labelStyle,
  DetailWrapper,
  UserDetailsWrapper,
  ReviewVerifyBtn,
} from "./style";
import colors from "../../theme/colors";
import styled from "@emotion/styled";
import CustomModal from "../deleteModel/CommonModel";
import EditPermissionUser from "../deleteModel/EditPermissionUser";
import male from "../../assets/male.png";
import female from "../../assets/female.png";
import Imotf from "../deleteModel/Imotf";
import { VerifyIcon } from "../../theme/SvgIcons";
import { CustomFlex, CustomLoader } from "../../utils/common_functions";
import CustomMenu from "../form/CustomMenu";
import Logout from "../sidebar/Logout";
import { useDispatch, useSelector } from "react-redux";
import {
  blockUser,
  deleteUser,
  suspendUser,
  updateDeleteLoader,
} from "../../redux/reported/action";
import { useParams, useNavigate } from "react-router-dom";
import {
  editRejectMail,
  editRejectMail_Res,
  getUserView,
  getUsersEdit,
  resetEditUserLoader,
  underReviewAccept,
  underReviewAccept_Res,
  updateIMOTFStatus,
  updateReject_loader,
  userReject,
  viewUserStatus,
} from "../../redux/users/action";
import moment from "moment/moment";
import ChildModel from "../deleteModel/ChildModel";
import MultiSelection from "../multi-selection-list";
import { toast } from "react-toastify";

const options = [
  { value: "delete", label: "Delete" },
  { value: "resolve", label: "Resolve" },
];

const blockedOption = { value: "block", label: "Block" };
const suspendedOption = { value: "suspend", label: "Suspend" };

const return_Edit_list = [
  {
    key: 1,
    value: "Inappropriate Image uploaded",
    reasons:
      "The images uploaded by you are inappropriate as per the instructions given on the Upload Images page. Kindly upload the new images which meet the requirements of LikePlay!",
  },
  {
    key: 2,
    value: "Verification of Government Id Failed",
    reasons:
      "We are sorry to say that we are unable to verify your Government ID because the face on your Government ID is not matching with your profile picture on LikePlay!",
  },
  {
    key: 3,
    value: "Facebook Verification Failed.",
    reasons:
      "We are sorry to say that your facebook information doesn't match with the information added on LikePlay!",
  },
  {
    key: 4,
    value: "Pose Verification Failed",
    reasons:
      "Please ensure that your picture is clear, facing front without a mask, nor sideways. There should be no sunglasses or face-cap. We request this information to ensure that bots are not on the platform.",
  },
  {
    key: 5,
    value: "About me Information is Inappropriate",
    reasons:
      "About me Information is Inappropriate, Please update this information.",
  },
];

const LikePlay = (props) => {
  const [open, setOpen] = useState(false);
  const [editopen, setEditOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedList, setSelectedList] = useState([]);

  const [imotfopen, setImotfOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState({
    type: "",
    openModal: false,
  });
  const { view_status } = useSelector((e) => e.users);
  const { image, profile_data, state } = props;
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [viewLoader, setViewLoader] = useState(true);
  const [openReasons, setOpenReasons] = useState(false);
  const {
    verify_status,
    imotf_status,
    edit_user_status,
    accept_loader,
    reject_loader,
    reject_user_loader,
  } = useSelector((e) => e.users);

  const { user_verification, profile_status, user_status, id } =
    profile_data || {};
  let since_date =
    moment(profile_data?.createdAt).local().format("MMMM DD,YYYY ") || "---";

  const optionsList = state?.isBlocked
    ? [...options, suspendedOption]
    : state?.isSuspended
    ? [...options, blockedOption]
    : [...options, suspendedOption, blockedOption];
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const detail_id = params?.id.split("=")[1];
  const { delete_status, block_status, suspend_status } = useSelector(
    (e) => e.reported
  );

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseModal = () => {
    setIsFullScreen(false);
  };

  let apiData = {
    user_id: detail_id,
    action: true,
  };

  let blockData = {
    user_id: detail_id,
    user_status: 2,
  };

  let resolveData = {
    user_id: detail_id,
    user_status: 1,
  };

  let suspendData = {
    user_id: detail_id,
    action: 3,
  };

  const handleConfirmClick = () => {
    if (openConfirm.type === "resolve") {
      setLoader(true);
      setOpenConfirm({ openModal: false });
      dispatch(blockUser(resolveData));
    } else if (openConfirm.type === "delete") {
      setLoader(true);
      setOpenConfirm({ openModal: false });
      dispatch(deleteUser(apiData));
    } else if (openConfirm.type === "suspend") {
      setLoader(true);
      setOpenConfirm({ openModal: false });
      dispatch(suspendUser(suspendData));
    } else if (openConfirm.type === "accept") {
      setLoader(true);
      setOpenConfirm({ openModal: false });
      dispatch(underReviewAccept({ user_id: id }));
    } else if (openConfirm.type === "decline") {
      setLoader(true);
      setOpenConfirm({ openModal: false });
      dispatch(editRejectMail({ user_id: id }));
    } else if (openConfirm.type === "reject") {
      setLoader(true);
      setOpenConfirm({ openModal: false });
      dispatch(userReject({ user_id: id }));
    } else {
      dispatch(blockUser(blockData));
      setLoader(true);
      setOpenConfirm({ openModal: false });
    }
  };

  const confirmLabel = () => {
    const text = {
      resolve: "Are you sure want to resolve?",
      delete: "Are you sure want to delete this user?",
      block: "Are you sure want to block this user?",
      suspend: "Are you sure want to suspend this user?",
      accept: "Are you sure , you want to accept",
      decline: "Are you sure , you want to decline",
      reject: "Are you sure , you want to reject",
    };
    if (openConfirm) {
      return text[openConfirm.type];
    }
  };

  const btnLabel = () => {
    const text = {
      resolve: "Resolve",
      delete: "Delete",
      block: "Block",
      suspend: "Suspend",
      accept: "Accept",
      decline: "Decline",
      reject: "Reject",
    };
    if (openConfirm) {
      return text[openConfirm.type];
    }
  };

  const handleClose = () => {
    setOpenReasons(false);
  };

  const handleSelectReason = () => {
    if (selectedList.length) {
      setEditOpen(true);
      setOpenReasons(false);
    } else toast.info("Atleast select one option.");
  };

  const customeButton = [
    {
      label: "Cancel",
      bg: "lightgrey",
      onClick: handleClose,
      border: "none",
      width: "120px",
    },
    {
      label: "Next",
      bg: "linear-gradient(271.38deg, #FF483C 0%, #FF2C5A 100%)",
      onClick: handleSelectReason,
      border: "none",
      width: "120px",
    },
  ];

  const handleEdit = (user) => {
    setLoader(true);
    dispatch(getUsersEdit({ ...user, message: [...selectedList] }));
    setEditOpen(false);
  };

  useEffect(() => {
    if (
      verify_status ||
      imotf_status ||
      edit_user_status ||
      reject_loader ||
      accept_loader ||
      reject_user_loader
    ) {
      if (reject_loader) dispatch(editRejectMail_Res(false));
      else if (accept_loader) {
        dispatch(underReviewAccept_Res(false));
        navigate(`/admin/profile/id=${id}`, { state: { name: "verified" } });
      } else if (verify_status) {
        user_status === "1" && profile_status === 9
          ? navigate(`/admin/profile/id=${id}`, {
              state: { name: "de-activate" },
            })
          : navigate(`/admin/profile/id=${id}`, {
              state: { name: "verified" },
            });
      } else if (
        edit_user_status &&
        (profile_status === 9 || profile_status === 8 || profile_status === 10)
      ) {
        navigate(`/admin/profile/id=${id}`, {
          state: { name: "under-review" },
        });
        dispatch(resetEditUserLoader(false));
      } else if (imotf_status) dispatch(updateIMOTFStatus(false));
      else if (reject_user_loader) {
        dispatch(updateReject_loader(false));
        navigate(`/admin/profile/id=${detail_id}`, {
          state: { name: "reject" },
        });
      }
      setLoader(false);
      dispatch(getUserView(detail_id));
    }
  }, [
    verify_status,
    detail_id,
    dispatch,
    imotf_status,
    edit_user_status,
    reject_loader,
    reject_user_loader,
    accept_loader,
  ]);

  useEffect(() => {
    if (delete_status || block_status || suspend_status) {
      // dispatch(getReportedUser());
      if (delete_status) {
        profile_status === 10 ? navigate(`/admin/reject-user`) : navigate(-1);
        dispatch(updateDeleteLoader());
      } else navigate(-1);
      setLoader(false);
    }
  }, [dispatch, delete_status, block_status, suspend_status]);

  const showHideIMOFT = () => {
    if (profile_data?.user_status === "1" && profile_data?.floor_status) {
      return (
        <>
          {profile_data.createpostIMOTF === false &&
          profile_status === 9 &&
          user_verification === "0" &&
          user_status === "1" ? (
            <IMOTBTN onClick={() => setImotfOpen(true)}>
              <Typography sx={{ ...labelStyle(colors.white, "14px", 500) }}>
                IMOTF
              </Typography>
            </IMOTBTN>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      <></>;
    }
  };

  useEffect(() => {
    if (view_status) {
      dispatch(viewUserStatus(false));
      setViewLoader(false);
    }
  }, [view_status, viewLoader, dispatch]);

  return (
    <UserWrapper>
      {loader && <CustomLoader />}
      <UserDetailsWrapper>
        <UserImageWrapper>
          {!isFullScreen ? (
            <ImageStyle
              src={
                image
                  ? `${process.env.REACT_APP_IMAGE_URL}${image}`
                  : profile_data?.gender === "1"
                  ? `${female}`
                  : `${male}`
              }
              onClick={handleImageClick}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 9999,
              }}
              onClick={handleCloseModal}
            >
              <img
                src={
                  image
                    ? `${process.env.REACT_APP_IMAGE_URL}${image}`
                    : profile_data.gender === "1"
                    ? `${female}`
                    : `${male}`
                }
                alt={"altText"}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </div>
          )}
        </UserImageWrapper>
        <DetailWrapper>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <Typography
              sx={{
                ...labelStyle(colors.nero, "28px", 700),
                letterSpacing: "0.05em",
              }}
            >
              {profile_data?.name}
            </Typography>
          </div>
          <div
            style={{ display: "flex", margin: "10px 0px", flexWrap: "wrap" }}
          >
            <Typography sx={labelStyle("#7B7F91", "16px", 400)}>
              {profile_data?.user_name}, {profile_data?.age}
              {profile_data?.gender === "1" ? " Female " : " Male "}
            </Typography>
            <Typography sx={{ ...labelStyle("#7B7F91", "16px", 400) }}>
              | {profile_data?.email}
            </Typography>
          </div>
          {profile_data?.profile_status >= 8 && (
            <div style={{ display: "flex" }}>
              {profile_data?.profile_status === 9 &&
                user_verification === "0" &&
                user_status === "1" && (
                  <ReviewVerifyBtn bg="#D4EED6">
                    <CustomFlex
                      sx={{
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ paddingTop: "5px" }}>
                        <VerifyIcon />
                      </div>
                      <Typography
                        sx={{
                          ...labelStyle(colors.black, "10px", 400),
                          paddingRight: "10px",
                        }}
                      >
                        Verified
                      </Typography>
                    </CustomFlex>
                  </ReviewVerifyBtn>
                )}
              {((profile_data?.profile_status === 9 &&
                user_verification === "2") ||
                (profile_data?.profile_status === 8 &&
                  user_verification === "2")) && (
                <ReviewVerifyBtn bg="#D9D9D9">
                  <div style={{ paddingTop: "3px" }}>
                    <Typography sx={{ ...labelStyle("#242424", "10px", 400) }}>
                      Under Review
                    </Typography>
                  </div>
                </ReviewVerifyBtn>
              )}
              {profile_data?.profile_status === 9 &&
                user_verification === "0" &&
                user_status === "1" && (
                  <CustomFlex
                    sx={{
                      gap: " 5px",
                      margin: "0px 15px",
                    }}
                  >
                    <Typography variant="title_1" sx={{ lineHeight: "16px" }}>
                      Member Since
                    </Typography>
                    <Typography variant="body_1">{since_date}</Typography>
                  </CustomFlex>
                )}
            </div>
          )}
        </DetailWrapper>
      </UserDetailsWrapper>
      <ButtonWrapper>
        {profile_data?.profile_status > 7 &&
          (state?.showMenuOption ? (
            <CustomMenu
              option={optionsList}
              onChange={(e) =>
                setOpenConfirm({ type: e.value, openModal: true })
              }
            />
          ) : (
            <>
              {(profile_status === 9 || profile_status === 8) &&
                user_verification === "0" && (
                  <ButtonStyle onClick={() => setOpen(true)}>
                    <Typography
                      sx={{ ...labelStyle(colors.white, "14px", 500) }}
                    >
                      {profile_data?.user_status !== "1"
                        ? "Activate"
                        : "Deactivate"}
                    </Typography>
                  </ButtonStyle>
                )}
              {((profile_status === 9 &&
                user_verification === "0" &&
                user_status === "1") ||
                ((profile_status === 8 || profile_status === 10) &&
                  user_verification === "0") ||
                user_verification === "2") && (
                <EditButton onClick={() => setOpenReasons(true)}>
                  <Typography
                    sx={{
                      ...labelStyle("#242424", "14px", 500),
                      whiteSpace: "nowrap",
                    }}
                  >
                    Return to Edit
                  </Typography>
                </EditButton>
              )}
              {user_verification === "2" && (
                <CustomFlex sx={{ gap: "10px" }}>
                  <ButtonStyle
                    onClick={() =>
                      setOpenConfirm({ type: "accept", openModal: true })
                    }
                  >
                    <Typography
                      sx={{ ...labelStyle(colors.white, "14px", 500) }}
                    >
                      Accept
                    </Typography>
                  </ButtonStyle>
                  <ButtonStyle
                    onClick={() =>
                      setOpenConfirm({ type: "decline", openModal: true })
                    }
                  >
                    <Typography
                      sx={{ ...labelStyle(colors.white, "14px", 500) }}
                    >
                      Decline
                    </Typography>
                  </ButtonStyle>
                </CustomFlex>
              )}
              {profile_status === 8 && user_verification === "0" && (
                <ButtonStyle
                  onClick={() =>
                    setOpenConfirm({ type: "reject", openModal: true })
                  }
                >
                  <Typography sx={{ ...labelStyle(colors.white, "14px", 500) }}>
                    Reject
                  </Typography>
                </ButtonStyle>
              )}
            </>
          ))}
        {(profile_status < 8 ||
          profile_status === 10 ||
          (profile_status === 9 && user_status === "0")) && (
          <ButtonStyle
            onClick={() => setOpenConfirm({ type: "delete", openModal: true })}
          >
            <Typography sx={{ ...labelStyle(colors.white, "14px", 500) }}>
              Delete
            </Typography>
          </ButtonStyle>
        )}
        {state?.isReported ? "" : showHideIMOFT()}
      </ButtonWrapper>
      {open && (
        <CustomModal
          open={open}
          setOpen={() => setOpen(false)}
          user={profile_data}
          loader={loader}
          setLoader={setLoader}
        ></CustomModal>
      )}
      {editopen && (
        <EditPermissionUser
          open={editopen}
          setOpen={() => setEditOpen(false)}
          user={profile_data}
          selectedList={selectedList}
          handleOk={handleEdit}
        ></EditPermissionUser>
      )}
      {imotfopen && (
        <Imotf
          open={imotfopen}
          setOpen={() => setImotfOpen(false)}
          user={profile_data}
          setLoader={setLoader}
        ></Imotf>
      )}
      {viewLoader && <CustomLoader />}
      {openConfirm.openModal && (
        <Logout
          open={openConfirm.openModal}
          setOpen={() => setOpenConfirm({ openModal: false })}
          text={confirmLabel()}
          onClick={() => handleConfirmClick()}
          btnLabel={btnLabel()}
        />
      )}
      {openReasons && (
        <ChildModel
          open={openReasons}
          closeModal={handleClose}
          title="Reasons"
          customeButton={customeButton}
        >
          <MultiSelection
            options={return_Edit_list}
            getSelectedList={setSelectedList}
            maxLimit={3}
          />
        </ChildModel>
      )}
    </UserWrapper>
  );
};

export default LikePlay;

export const UserImageWrapper = styled(Box)({
  background: "white",
  width: "105px",
  height: "105px",
  borderRadius: "50%",
  backgroundColor: "grey",
  overflow: "hidden",
  margin: "15px",
});

export const ImageStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ButtonWrapper = styled(Box)(() => ({
  display: "flex",
  background: "white",
  justifyContent: "space-between",
  float: "right",
  paddingTop: "20px",
  flexWrap: "wrap",
  gap: "10px",
  alignItems: "flex-start",
}));

export const ButtonStyle = styled(Button)({
  width: "103px",
  height: "39px",
  borderRadius: "10px",
  padding: "10px 22px",
  background: "linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)",
  float: "right",
  textTransform: "none",
});

export const IMOTBTN = styled(Button)({
  height: "39px",
  borderRadius: "10px",
  padding: "10px 22px",
  textTransform: "none",
  marginLeft: "10px",
  background: "linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)",
});

export const EditButton = styled(Button)({
  width: "142px",
  height: "39px",
  background: "#F0F0F0",
  borderRadius: "10px",
  padding: "10px 22px",
  float: "right",
  textTransform: "none",
  marginLeft: "10px",
});
