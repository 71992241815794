import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import CustomDialog from '../form/CustomDialog';
import CustomButton from '../form/CustomButton';

export default function Logout(props) {
    const { open, setOpen, onClick, text, btnLabel } = props;
    return (
        <CustomDialog open={open} setOpen={setOpen}>
            <ConfirmBox sx={{ margin: '40px auto', alignItems: 'center' }}>
                <Img alt='' src="/likeplaylogo.png" />
                <Typography
                    sx={{
                        textAlign: 'center',
                        mt: '20px',
                        fontSize: '20px',
                        fontWeight: 600
                    }}>
                    {text ? text : 'Are you sure want to logout?'}
                </Typography>
            </ConfirmBox>
            <ButtonWrapper>
                <Box sx={{ mr: '10px' }}>
                    <CustomButton onClick={() => setOpen(false)} variant='outlined'>Cancel</CustomButton>
                </Box>
                <CustomButton onClick={() => onClick()}>{btnLabel ? btnLabel : 'Logout'}</CustomButton>
            </ButtonWrapper>
        </CustomDialog>
    );
}

const ConfirmBox = styled(Box)`
  width: 446px;
  display: flex;
  flex-direction:column;
`;
const ButtonWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const Img = styled.img`
height:100px;
width:100px
`