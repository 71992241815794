import { Box, Card } from "@mui/material";
import NavBar from "../navbar";
import CustomText from "../form/CustomText";
import CustomTable from "../form/CustomTable";
import { useEffect, useState } from "react";
import {
  getFeedback,
  updateFeedbackLoader,
} from "../../redux/dashboard/action";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader, SearchField } from "../../utils/common_functions";

const column = [
  {
    id: "user_name",
    label: "User",
  },
  {
    id: "source",
    label: "FeedBack",
  },
  {
    id: "createdAt",
    label: "Created At",
  },
];
const brcrmb = [{ name: "Feedback", path: "/admin/feedback" }];

const Feedback = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const { feedback_list, feedback_loading, feedback_count } = useSelector(
    (e) => e.dashboard
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const checkStatus = (key) => {
    let text = {
      1: "facebook",
      2: "youtube",
      3: "linkedIn",
      4: "Instagram",
      5: "Twitter",
      6: "Newspaper",
      7: "Friends",
      8: "Media Ads",
    };
    return text[key];
  };

  const handleGetListingData = (newPage, rowsPerPage, searchText = "") => {
    const queryParam = `?page=${
      newPage + 1
    }&limit=${rowsPerPage}&search=${searchText}`;
    dispatch(getFeedback(queryParam));
  };

  const rows = () => {
    if (Array.isArray(feedback_list)) {
      if (feedback_list) {
        return feedback_list.map((el) => ({
          user_name: (
            <CustomText variant="body_1">{el?.User?.user_name}</CustomText>
          ),
          source: (
            <CustomText variant="body_1">{checkStatus(el?.source)}</CustomText>
          ),
          createdAt: (
            <CustomText variant="gray_title">
              {el?.createdAt.split("T")[0]}
            </CustomText>
          ),
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(updateFeedbackLoader(true));
    handleGetListingData(newPage, rowsPerPage, searchText);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(updateFeedbackLoader(true));
    let rowPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(rowPerPage);
    handleGetListingData(page, rowPerPage, searchText);
  };

  let ref;
  useEffect(() => {
    if (feedback_list !== undefined) {
      setPage(0);
      ref = setTimeout(() => handleSearch(), 1000);
    }
    return () => clearTimeout(ref);
  }, [searchText]);

  const handleSearch = () => {
    dispatch(updateFeedbackLoader(true));
    handleGetListingData(0, rowsPerPage, searchText);
  };

  return (
    <>
      <NavBar option={brcrmb} />
      <Card sx={{ m: "20px 36px 50px 36px" }}>
        <Box sx={{ m: "30px", display: "flex", alignItems: "start" }}>
          <SearchField
            sx={{
              maxWidth: "579px",
              "& .MuiOutlinedInput-input": {
                background: "rgba(168, 88, 15, 0.06) !important",
              },
              background: "rgba(168, 88, 15, 0.06) !important",
            }}
            onChange={(e) => setSearchText(e)}
          />
        </Box>
        {feedback_loading ? (
          <CustomLoader />
        ) : (
          <CustomTable
            rows={rows()}
            headCells={column}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            totalCount={feedback_count}
          />
        )}
      </Card>
    </>
  );
};

export default Feedback;
