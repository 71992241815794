// eslint-disable-next-line import/no-extraneous-dependencies
import AWS from "aws-sdk";
import { toast } from "react-toastify";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
const REGION = process.env.REACT_APP_S3_REGION;
const ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

const uploadFileToS3 = (file, bucketPath, handleUpload = () => {}) => {
  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: bucketPath, // file.name,
    Body: file,
  };
  var upload = s3
    .putObject(params)
    .on("httpUploadProgress", (evt) => {
      console.log(
        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
      );
    })
    .promise();

  upload.then((err, data) => {
    console.log("S3 errror", err, data);
    // return { err, data };
    handleUpload();
  });
};

export const deleteS3Object = async (bucketPath, handleDelete = () => {}) => {
  // return new Promise((resolve, reject) => {
  try {
    let s3bucket = new AWS.S3({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      Bucket: S3_BUCKET,
    });
    var params = { Bucket: S3_BUCKET, Key: bucketPath };
    console.log("params delete", params);
    s3bucket.deleteObject(params, function (err, data) {
      console.log("++delete response", data, err);
      // an error occurred
      if (err) toast.error("Something went wrong");
      else handleDelete();
      //resolve(data); // successful response
      console.log(typeof handleDelete, "type");
    });
  } catch (e) {
    console.log("error in s3 delete", e);
    toast.error("Something went wrong");
  }
  // });
};

export default uploadFileToS3;
