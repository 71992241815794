import { CHANGE_PASSWORD_RES } from './type';

const initialState = {
    change_password_loader: true
};

function changePasswordReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PASSWORD_RES:
            return {
                ...state,
                change_password_loader: action.payload
            };
        default:
            return state;
    }
}

export default changePasswordReducer;
