import { Box, CircularProgress, InputAdornment } from "@mui/material";
import { FieldStyle } from "./style";
import { SearchIcon } from "../theme/SvgIcons";
import colors from "../theme/colors";

/**
 *
 * @param {*} props
 * @returns  common FlexBox with justifyContent
 */
export const CustomFlex = (props) => {
  const { children, sx } = props;
  let style = {
    display: "flex",
    alignItems: "center",
  };
  return <Box sx={{ ...style, ...sx }}>{children}</Box>;
};

/**
 *
 * @param {*} _key key name which set in local storage.
 * @param {*} _localData data which is set in local storage.
 */
export const setDataInLocalStorage = (_key, _localData) => {
  localStorage.setItem(_key, JSON.stringify(_localData));
};

/**
 *
 * @param {*} _item key name to get data.
 * @returns
 */
export const getDataFromLocalStorage = (_item) => {
  return JSON.parse(localStorage.getItem(_item));
};

export const CustomLoader = () => {
  return (
    <CustomFlex
      sx={{
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        background: "black",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        opacity: 0.5,
        zIndex: 3,
      }}
    >
      <CircularProgress size="3rem" thickness={2} />
    </CustomFlex>
  );
};

/**
 *
 * @param {*} props
 * @returns common Search field
 */
export const SearchField = (props) => {
  const { sx, onChange, value } = props || {};
  return (
    <FieldStyle
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={sx}
      placeholder="Search..."
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon fill={colors.white} />
          </InputAdornment>
        ),
      }}
    />
  );
};
