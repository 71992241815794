import { Typography } from '@mui/material';
import { fontFamilys } from '../../theme/common_style';

const EllipseText = (props) => {
  const { children, variant, sx } = props;
  let style = {
    fontFamily: fontFamilys.poppinsFont,
    width:"120px",
    overflow:"hidden",
    textOverflow:"ellipsis",
    display:"-webkit-box",
    "-webkit-line-clamp":"1",
    "-webkit-box-orient":"vertical"
};
  return (
    <Typography
      sx={{ ...style, ...sx }}
      component={'p'}
      variant={variant ? variant : 'body1_medium'}>
      {children}
    </Typography>
  );
};


export default EllipseText;
