import {
    BlockedUserIcon,
    ChangePasswordIcon,
    FeedbackIcon,
    HomeIcon,
    PostIcon,
    ReportUser,
    SuspendedIcon,
    TestimonialIcon,
    UserFilled
} from "../../theme/SvgIcons";

export const menuList = [
    { icon: <HomeIcon height="18px" width="17px"/>, label: 'Dashboard', path: '/admin/dashboard' },
    {
        icon: <UserFilled />, label: 'Users', path: '/admin/waiting-approval',
        child:
            [
                { icon: <></>, label: 'Waiting For Approval', path: '/admin/waiting-approval' },
                { icon: <></>, label: 'Active', path: '/admin/users-verify' },
                { icon: <></>, label: 'Under Review', path: '/admin/under-review' },
                { icon: <></>, label: 'Register', path: '/admin/register-user' },
                { icon: <></>, label: 'Deactive', path: '/admin/deactive-user' },
                { icon: <></>, label: 'Reject', path: '/admin/reject-user' },
            ]
    },
    { icon: <ReportUser />, label: 'Reported', path: '/admin/reported' },
    { icon: <SuspendedIcon />, label: 'Suspended', path: '/admin/suspended' },
    { icon: <BlockedUserIcon />, label: 'Blocked', path: '/admin/block-user' },
    { icon: <FeedbackIcon />, label: 'Feedback', path: '/admin/feedback' },
    { icon: <TestimonialIcon />, label: 'Testimonial', path: '/admin/testimonial' },
    { icon: <PostIcon />, label: 'Post', path: '/admin/my-posts' },
    { icon: <ChangePasswordIcon />, label: 'Change Password', path: '/admin/change-password', },
    // { icon: <LogoutIcon />, label: 'Logout', path: '/admin/logout', }
]