import styled from "@emotion/styled";
import { Box, Chip } from "@mui/material";
import colors from "../../theme/colors";

export const FlexStyle = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "19px",
  borderRadius: "40px",
  background: colors.grayLight91,
  padding: "6px 15px 6px 6px",
  cursor: "pointer",
});

export const TextBox = styled(Box)({
  marginLeft: "10px",
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
});

export const ChipStyle = styled(Chip)({
  marginTop: "5px",
  background: colors.darkGreen,
  color: "white",
  cursor: "pointer",
});
export const BoxWrap = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "white",
  border: `1px solid ${colors.Gray90}`,
  position: "sticky",
  width: " 100%",
  top: "0%",
  zIndex: 3,
});

export const MenuIconContainer = styled(Box)({
  margin: "0 10px",
});

export const ImgStyle = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid ${colors.grayLight91};
  height: 38px;
  width: 38px;
  overflow: hidden;
`;
