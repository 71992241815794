import { combineReducers } from "redux";
import usersReducer from "./users/usersReducer";
import signInReducer from "./sign-in/signInReducer";
import dashboarCountReducer from "./dashboard/dashboardCountReducer";
import reportedReducer from "./reported/reportedReducer";
import postReducer from "./post/postReducer";
import suspendReducer from "./suspended/suspendedReducer";
import changePasswordReducer from "./changePassword/changePasswordReducer";
import testimonialReducer from "./testimonial/testimonialReducer";
import updateProfileReducer from "./update-profile/updateProfileReducer";
import userSlice from "./users/userSlice";

export const rootReducer = combineReducers({
  users: usersReducer,
  signIn: signInReducer,
  dashboard: dashboarCountReducer,
  reported: reportedReducer,
  post: postReducer,
  suspend: suspendReducer,
  changePassword: changePasswordReducer,
  testimonial: testimonialReducer,
  profile: updateProfileReducer,
  userInfo: userSlice,
});
