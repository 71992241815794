import { FormControl } from "@mui/material";
import NavBar from "../navbar";
import CustomText from "../form/CustomText";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../form/CustomTextField";
import { BoxWrapper, CardStyle, ButtonStyle, CardWrapper } from "./style";
import { useDispatch, useSelector } from "react-redux";
import {
  changePassword,
  resetLoaderStatus,
} from "../../redux/changePassword/action";
import { CustomLoader } from "../../utils/common_functions";
import { useEffect, useState } from "react";

const ChangePassword = () => {
  const { register, handleSubmit, control, reset, getValues } = useForm();
  const [loader, setLoader] = useState(false);
  const { change_password_loader } = useSelector((e) => e.changePassword);
  const dispatch = useDispatch();

  const brcrmb = [{ name: "Change Password", path: "/admin/change-password" }];

  const onSubmit = (data) => {
    setLoader(true);
    dispatch(changePassword(data));
    reset();
  };

  useEffect(() => {
    if (!change_password_loader) {
      setLoader(false);
      dispatch(resetLoaderStatus(true));
    }
  }, [change_password_loader]);

  return (
    <>
      <NavBar option={brcrmb} />
      {loader && <CustomLoader />}
      <BoxWrapper>
        <CardWrapper>
          <CardStyle>
            <img alt="" src="/likeplaylogo.png" />
            <CustomText
              variant={"large_title"}
              sx={{
                mt: "20px",
                textAlign: "start",
                fontSize: "22px!important",
              }}
            >
              Change Password
            </CustomText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ mt: "10px" }} fullWidth>
                <CustomText
                  variant="body1_medium"
                  sx={{ mt: "10px", mb: "10px", textAlign: "start" }}
                >
                  Old Password
                </CustomText>
                <Controller
                  name="old_password"
                  control={control}
                  rules={{
                    required: "Old Password is required",
                  }}
                  render={({ fieldState: { error }, field: { onChange } }) => (
                    <CustomTextField
                      sx={{
                        // border: `1px solid${colors.gray66} !important`,
                        borderRadius: "10px !important",
                        height: "37px!important",
                      }}
                      input_padding="6px"
                      register={{ ...register("old_password") }}
                      label=""
                      error={!!error}
                      type="password"
                      onChange={onChange}
                      placeholder={"Old Password"}
                      showAdornment={true}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ mt: "10px" }} fullWidth>
                <CustomText
                  variant="body1_medium"
                  sx={{ mt: "10px", mb: "10px", textAlign: "start" }}
                >
                  New Password
                </CustomText>
                <Controller
                  name="new_password"
                  control={control}
                  rules={{
                    required: "New Password is required",
                  }}
                  render={({ fieldState: { error }, field: { onChange } }) => (
                    <CustomTextField
                      sx={{
                        // border: `1px solid${colors.gray66} !important`,
                        borderRadius: "10px !important",
                        height: "37px!important",
                      }}
                      input_padding="6px"
                      register={{ ...register("new_password") }}
                      error={!!error}
                      type="password"
                      placeholder={"New Password"}
                      helperText={error ? error.message : null}
                      onChange={onChange}
                      showAdornment={true}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ mt: "10px" }} fullWidth>
                <CustomText
                  variant="body1_medium"
                  sx={{ mt: "10px", mb: "10px", textAlign: "start" }}
                >
                  Confirm New Password
                </CustomText>
                <Controller
                  name="confirm_password"
                  control={control}
                  rules={{
                    required: "Confirm Password is required",
                    validate: {
                      passwordMatch: (value) =>
                        value === getValues().new_password ||
                        "New Password and Confirm Passwords does not match",
                    },
                  }}
                  render={({ fieldState: { error }, field: { onChange } }) => (
                    <CustomTextField
                      sx={{
                        // border: `1px solid${colors.gray66} !important`,
                        borderRadius: "10px !important",
                        height: "37px!important",
                      }}
                      input_padding="6px"
                      register={{ ...register("confirm_password") }}
                      label=""
                      error={!!error}
                      type="password"
                      onChange={onChange}
                      placeholder={"Confirm Password"}
                      helperText={error ? error.message : null}
                      showAdornment={true}
                    />
                  )}
                />
              </FormControl>
              <ButtonStyle type="submit">Submit</ButtonStyle>
            </form>
          </CardStyle>
        </CardWrapper>
      </BoxWrapper>
    </>
  );
};

export default ChangePassword;
