import { Box, Card, IconButton } from "@mui/material";
import CustomTable from "../form/CustomTable";
import CustomText from "../form/CustomText";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getReportedUser } from "../../redux/reported/action";
import { CustomLoader, SearchField } from "../../utils/common_functions";
import { ViewIcon } from "../../theme/SvgIcons";
import { getUserView } from "../../redux/users/action";
import { useNavigate } from "react-router-dom";
import NavBar from "../navbar";

export const column = [
  {
    id: "name",
    label: "Reported User",
  },
  {
    id: "message",
    label: "Reported Reason",
  },
  {
    id: "reportBy",
    label: "Reported By",
  },
  {
    id: "createdAt",
    label: "Date of Reporting",
  },
  {
    id: "action",
    label: "Actions",
  },
];

const ReportedUser = () => {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState({ type: "", openModal: false });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reported_user, reported_loading, report_count } = useSelector(
    (e) => e.reported
  );
  const { isLoading } = useSelector((e) => e.userInfo);

  useEffect(() => {
    dispatch(
      getReportedUser(
        `?page=${page + 1}&limit=${rowsPerPage}&search=${searchText}`
      )
    );
  }, [dispatch]);

  const profileView = (id) => {
    dispatch(getUserView(id));
    navigate(`/admin/profile/id=${id}`, {
      state: { isReported: true, showMenuOption: true },
    });
  };

  const rows = (reported_user, searchText, setOpen) => {
    if (Array.isArray(reported_user)) {
      if (reported_user) {
        return reported_user.map((el) => ({
          name: (
            <CustomText variant="body_1">{el?.reportTo?.user_name}</CustomText>
          ),
          message: <CustomText variant="gray_title">{el.message}</CustomText>,
          reportBy: (
            <CustomText variant="gray_title">
              {el?.reportBy?.user_name}
            </CustomText>
          ),
          createdAt: (
            <CustomText variant="gray_title">
              {el.createdAt?.split("T")[0]}
            </CustomText>
          ),
          action: (
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              onClick={() => profileView(el.report_to)}
            >
              <ViewIcon />
            </IconButton>
          ),
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const brcrmb = [{ name: "Reported", path: "/admin/reported" }];

  const handleGetListingData = (newPage, rowsPerPage, searchText = "") => {
    const queryParam = `?page=${
      newPage + 1
    }&limit=${rowsPerPage}&search=${searchText}`;
    dispatch(getReportedUser(queryParam));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleGetListingData(newPage, rowsPerPage, searchText);
  };

  const handleChangeRowsPerPage = (event) => {
    let rowPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(rowPerPage);
    handleGetListingData(page, rowPerPage, searchText);
  };

  let ref;
  useEffect(() => {
    setPage(0);
    ref = setTimeout(
      () => handleGetListingData(0, rowsPerPage, searchText),
      1000
    );
    return () => clearTimeout(ref);
  }, [searchText]);

  return (
    <>
      <NavBar option={brcrmb} />
      <Card sx={{ m: "20px 36px 50px 36px" }}>
        <Box sx={{ m: "30px", display: "flex", alignItems: "start" }}>
          <SearchField
            sx={{
              maxWidth: "579px",
              "& .MuiOutlinedInput-input": {
                background: "rgba(168, 88, 15, 0.06) !important",
              },
              background: "rgba(168, 88, 15, 0.06) !important",
            }}
            onChange={(e) => setSearchText(e)}
          />
        </Box>
        {isLoading ? (
          <CustomLoader />
        ) : (
          <CustomTable
            rows={rows(reported_user, searchText, setOpen, open)}
            headCells={column}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            totalCount={report_count}
          />
        )}
      </Card>
    </>
  );
};
export default ReportedUser;
