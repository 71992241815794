import { toast } from "react-toastify";
import { GET_TESTIMONIAL } from "./type";
import { call, put, takeLatest } from "redux-saga/effects";
import { getTestimonialRoute } from "./apiRoutes";
import { getTestimonialsResponse } from "./action";

function* getTestimonialGenerator(action) {
  const { queryParam } = action || {};
  try {
    const res = yield call(getTestimonialRoute, queryParam);
    if (res?.status === 200) {
      const { data = [], extra = 0 } = res || {};
      yield put(getTestimonialsResponse({ data, extra }));
    } else {
      toast.error(res?.message);
    }
  } catch (error) {
    yield put(getTestimonialsResponse());
    toast.error("Something went Wrong");
  }
}

function* testimonialSaga() {
  yield takeLatest(GET_TESTIMONIAL, getTestimonialGenerator);
}

export default testimonialSaga;
