export const GET_REPORTED_USER = "GET_REPORTED_USER";
export const GET_REPORTED_USER_RES = "GET_REPORTED_USER_RES";
export const FAIL_GET_USER = "FAIL_GET_USER";
export const GET_ADMIRERS = "GET_ADMIRERS";
export const GET_ADMIRERS_RES = "GET_ADMIRERS_RES";
export const GET_ADMIRING = "GET_ADMIRING";
export const GET_ADMIRING_RES = "GET_ADMIRING_RES";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_RES = "DELETE_USER_RES";
export const BLOCK_USER = "BLOCK_USER";
export const BLOCK_USER_STATUS = "BLOCK_USER_STATUS";
export const SUSPEND_USER = "SUSPEND_USER";
export const SUSPEND_USER_STATUS = "SUSPEND_USER_STATUS";
export const DELETE_LOADER_UPDATE = "DELETE_LOADER_UPDATE";
export const DELETE_LOADER_UPDATE_RES = "DELETE_LOADER_UPDATE_RES";
export const GET_MATCHES = "GET_MATCHES";
export const GET_MATCHES_RES = "GET_MATCHES_RES";
export const MATCH_LOADER_RES = "MATCH_LOADER_RES";
