import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
// import PropTypes from "prop-types";
// import { fontFamilys } from "../../theme/common_style";
import colors from "../../theme/colors";

const StyledButton = styled(Button)(({ bg, width, border }) => ({
  background: bg,
  textTransform: "capitalize",
  width: width,
  fontFamily: "Poppins",

  border: border ? border : `1px solid ${colors.pinkred}`,
  borderRadius: "8px",

  "&:hover": {
    background: bg ? bg : colors.bg,
  },
}));
const CustomButton = (props) => {
  const {
    label,
    bg,
    onClick,
    children,
    width,
    iconAlign,
    variant,
    type,
    color,
    border,
    disabled
  } = props;
  return (
    <StyledButton
      width={width}
      type={type || "button"}
      onClick={() => onClick && onClick()}
      variant={variant || "contained"}
      bg={bg}
      disabled={disabled && disabled}
      border={border}
    >
      <Box
        sx={{
          flexDirection: iconAlign === "right" ? "" : "row-reverse",
          display: "flex",
          alignItems: "center",
          color: color,
        }}
      >
        {label}
      </Box>
      {children && children}
    </StyledButton>
  );
};

export default CustomButton;
