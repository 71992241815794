import {
  BLOCK_USER,
  BLOCK_USER_STATUS,
  DELETE_LOADER_UPDATE,
  DELETE_LOADER_UPDATE_RES,
  DELETE_USER,
  DELETE_USER_RES,
  GET_ADMIRING_RES,
  GET_MATCHES,
  GET_MATCHES_RES,
  MATCH_LOADER_RES,
  SUSPEND_USER,
  SUSPEND_USER_STATUS,
} from "./type";
import {
  FAIL_GET_USER,
  GET_ADMIRERS,
  GET_ADMIRERS_RES,
  GET_ADMIRING,
  GET_REPORTED_USER,
  GET_REPORTED_USER_RES,
} from "./type";

export const getReportedUser = (queryParam) => {
  return {
    type: GET_REPORTED_USER,
    queryParam,
  };
};

export const getReportedUserRes = (res, status) => {
  return {
    type: GET_REPORTED_USER_RES,
    payload: res,
    status,
  };
};

export const failGetUser = (res) => {
  return {
    type: FAIL_GET_USER,
    payload: res,
  };
};
export const getAdmieres = (payload) => {
  return {
    type: GET_ADMIRERS,
    payload: payload,
  };
};

export const getAdmieresRes = (res) => {
  return {
    type: GET_ADMIRERS_RES,
    payload: res,
  };
};

export const getAdmirings = (payload) => {
  return {
    type: GET_ADMIRING,
    payload: payload,
  };
};

export const getAdmiringRes = (res) => {
  return {
    type: GET_ADMIRING_RES,
    payload: res,
  };
};

export const deleteUser = (data) => {
  return {
    type: DELETE_USER,
    payload: data,
  };
};
export const deleteUserRes = (res) => {
  return {
    type: DELETE_USER_RES,
    payload: res,
  };
};
export const updateDeleteLoader = () => {
  return {
    type: DELETE_LOADER_UPDATE,
  };
};
export const updateDeleteLoaderRes = (res) => {
  return {
    type: DELETE_LOADER_UPDATE_RES,
    payload: res,
  };
};

export const blockUser = (data) => {
  return {
    type: BLOCK_USER,
    payload: data,
  };
};
export const blockUserRes = (res) => {
  return {
    type: BLOCK_USER_STATUS,
    payload: res,
  };
};

export const suspendUser = (data) => {
  return {
    type: SUSPEND_USER,
    payload: data,
  };
};
export const suspendUserRes = (res) => {
  return {
    type: SUSPEND_USER_STATUS,
    payload: res,
  };
};

export const getMatches = (user_id, attributeStatus) => {
  return {
    type: GET_MATCHES,
    user_id,
    attributeStatus,
  };
};

export const getMatchesRes = (res) => {
  return {
    type: GET_MATCHES_RES,
    payload: res,
  };
};

export const handleMatchLoader = (data) => {
  return {
    type: MATCH_LOADER_RES,
    payload: data,
  };
};
