import { takeLatest, call, put } from "redux-saga/effects";
import { GET_DASHBOARD_USER_COUNT, GET_FEEDBACK } from "./type";
import { dashboardCountRoute, feedbackRoute } from "./apiRoutes";
import { getDashboardCountRes, getFeedbackRes, responseStatus } from "./action";
import { toast } from "react-toastify";

function* dashboardCountGenerator() {
  try {
    let res = yield call(dashboardCountRoute);
    yield put(getDashboardCountRes(res?.data));
    if (res && res?.status === 200) {
    }
  } catch (error) {
    if (error) {
      toast.error(error?.response?.data.message || "Something went wrong!");
      yield put(responseStatus(error));
    }
  }
}

function* getFeedbackGenerator(action) {
  const { queryParam } = action || {};
  try {
    let res = yield call(feedbackRoute, queryParam);
    const { data = [], extra = 0 } = res || {};
    yield put(getFeedbackRes({ data, extra }));
    if (res && res?.status === 200) {
    }
  } catch (error) {
    if (error) {
      toast.error(error?.response?.data.message || "Something went wrong!");
      yield put(getFeedbackRes(error));
    }
  }
}
function* dashboardCountSaga() {
  yield takeLatest(GET_DASHBOARD_USER_COUNT, dashboardCountGenerator);
  yield takeLatest(GET_FEEDBACK, getFeedbackGenerator);
}

export default dashboardCountSaga;
