import { Box } from "@mui/material"

const NoRouteFound = () => {
    return (
        <Box>
            <img style={{ width: '60%', }} alt='' src='/404.jpeg' />
        </Box>
    )
}

export default NoRouteFound