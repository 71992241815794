import axiosInterceptor from "../../utils/axiosInterseptor";

export const getUserViewApi = (id) =>
  axiosInterceptor.get(`admin/getUserDetails?userId=${id}`);

export const getUserRoute = (queryParam) =>
  axiosInterceptor.get(`admin/getUserListing${queryParam}`);

export const getVerifyUserRoute = (data, query = "") =>
  axiosInterceptor.get(
    `admin/getAllVerifiedUsers?status=${data.status}${query}`
  );

export const userVerifyApi = (payload) =>
  axiosInterceptor.put("/admin/updateUserStatus", payload);

export const userEditApi = (payload) =>
  axiosInterceptor.put("/admin/userVerifyByAdmin", payload);

export const userVerifyFcgApi = (payload) =>
  axiosInterceptor.post("/admin/verifyUserByAdmin", payload);

export const userImotfApi = (payload) =>
  axiosInterceptor.put("/admin/takeUserOnFloor", payload);

export const getRegisterUserRoute = (queryParam) =>
  axiosInterceptor.get(`admin/registerUsers${queryParam}`);

export const getUnderReviewUserRoute = (queryParam) =>
  axiosInterceptor.get(`admin/underReviewUsers${queryParam}`);

export const sendEditRejectEmailRoute = (payload) =>
  axiosInterceptor.put("admin/sendEditRejectionEmail", payload);

export const reviewUserAcceptRoute = (payload) =>
  axiosInterceptor.put("admin/underReviewUsersAccept", payload);

// Reject user
export const rejectUserRoute = (payload) =>
  axiosInterceptor.put("admin/rejectUser", payload);

export const getRejectUsersRoute = (queryParam) =>
  axiosInterceptor.get(`admin/rejectedUsers${queryParam}`);
