import { takeLatest, call, put } from 'redux-saga/effects';
import { UPDATE_PROFILE } from './type';
import { updateProfileRoute } from './apiRoute';
import { updateProfileRes } from './action';
import { toast } from 'react-toastify';

function* updateProfileGenerator(action) {
    const { payload } = action|| {};
    try {
        let res = yield call(updateProfileRoute, payload);
        yield put(updateProfileRes(res?.data))
        if (res && res?.status === 200) {
            toast.success(res?.message)
        }

    } catch (error) {
        yield put(updateProfileRes(error?.response))
        if (error) {
            toast.error(error?.response?.data.message || 'Something went wrong!')
        }
    }
}

function* updateProfileSaga() {
    yield takeLatest(UPDATE_PROFILE, updateProfileGenerator);
}

export default updateProfileSaga;
