export const GET_USER_VIEW = "GET_USER_VIEW";
export const GET_USER_VIEW_RESPONSE = "GET_USER_VIEW_RESPONSE";
export const GET_USER_VRIFY = "GET_USER_VRIFY";
export const GET_USER_EDIT = "GET_USER_EDIT";
export const USER_EDIT_STATUS = "USER_EDIT_STATUS";
export const RESET_EDIT_USER_LOADER = "RESET_EDIT_USER_LOADER";
export const USER_VERIFY_BY_F_G_C = "USER_VERIFY_BY_F_G_C";
export const USER_VERIFY_BY_F_G_C_RES = "USER_VERIFY_BY_F_G_C_RES";
export const USER_IMOTF = "USER_IMOTF";
export const USER_IMOTF_STATUS = "USER_IMOTF_STATUS";
export const VERIFY_STATUS = "VERIFY_STATUS";
export const FAIL_VIEW_STATUS = "FAIL_VIEW_STATUS";

export const GET_USERS = "GET_USERS";
export const GET_USERS_RES = "GET_USERS_RES";
export const FAILER_USER_RES = "FAILER_USER_RES";

export const GET_VERIFIED_USERS = "GET_VERIFIED_USERS";
export const GET_VERIFIED_USERS_RES = "GET_VERIFIED_USERS_RES";
export const SIDEBAR_RESPONSIVE = "SIDEBAR_RESPONSIVE";
export const SIDEBAR_RESPONSIVE_RES = "SIDEBAR_RESPONSIVE_RES";

export const SET_LOADER_TRUE = "SET_LOADER_TRUE";

// registered users
export const GET_REGISTERED_USERS = "GET_REGISTERED_USERS";
export const GET_REGISTERED_USERS_RES = "GET_REGISTERED_USERS_RES";

// underReview User
export const GET_UNDER_REVIEW_USERS = "GET_UNDER_REVIEW_USERS";
export const GET_UNDER_REVIEW_USERS_RES = "GET_UNDER_REVIEW_USERS_RES";

export const EDIT_REJECT_EMAIL = "EDIT_REJECT_EMAIL";
export const EDIT_REJECT_EMAIL_RES = "EDIT_REJECT_EMAIL_RES";

export const UNDER_REVIEW_USER_ACCEPT = "UNDER_REVIEW_USER_ACCEPT";
export const UNDER_REVIEW_USER_ACCEPT_RES = "UNDER_REVIEW_USER_ACCEPT_RES";

export const UPDATE_IMOTF_STATUS = "UPDATE_IMOTF_STATUS";
export const UPDATE_IMOTF_STATUS_RES = "UPDATE_IMOTF_STATUS_RES";

// reject user
export const REJECT_USER = "REJECT_USER";
export const REJECT_USER_RES = "REJECT_USER_RES";
export const REJECT_LOADER_STATUS_UPDATE = "REJECT_LOADER_STATUS_UPDATE";
export const REJECT_LOADER_UPDATE = "REJECT_LOADER_UPDATE";

export const REJECT_USER_LIST = "REJECT_USER_LIST";
export const REJECT_USER_LIST_RES = "REJECT_USER_LIST_RES";

export const LOADER_STATUS = "LOADER_STATUS";
