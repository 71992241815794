import { takeLatest, call, put } from "redux-saga/effects";
import { GET_BLOCKED_USER, GET_SUSPENDED_USER } from "./type";
import { blockedUserRoute, suspendedUserRoute } from "./apiRoutes";
import {
  blockedUserStatus,
  getBlockedRes,
  getSuspendedRes,
  suspendUserStatus,
} from "./action";
import { toast } from "react-toastify";

function* getSuspendedUserGenerator(action) {
  const { queryParam } = action || {};
  try {
    const res = yield call(suspendedUserRoute, queryParam);
    if (res.status === 200) {
      yield put(getSuspendedRes(res, false));
    }
  } catch (error) {
    yield put(suspendUserStatus());
    toast.error("Something went Wrong");
  }
}

function* getBlockedUserGenerator(action) {
  const { queryParam } = action || {};
  try {
    const res = yield call(blockedUserRoute, queryParam);
    if (res.status === 200) {
      yield put(getBlockedRes(res, false));
    }
  } catch (error) {
    yield put(blockedUserStatus());
    toast.error("Something went Wrong");
  }
}

function* suspendedSaga() {
  yield takeLatest(GET_SUSPENDED_USER, getSuspendedUserGenerator);
  yield takeLatest(GET_BLOCKED_USER, getBlockedUserGenerator);
}

export default suspendedSaga;
