import { Box, Card, IconButton } from "@mui/material";
import CustomTable from "../form/CustomTable";
import CustomText from "../form/CustomText";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { CustomLoader, SearchField } from "../../utils/common_functions";
import { ViewIcon } from "../../theme/SvgIcons";
import { getUserView } from "../../redux/users/action";
import { useNavigate } from "react-router-dom";
import NavBar from "../navbar";
import { getBlockedUser, getSuspendedUser } from "../../redux/suspended/action";

export const column = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "user_name",
    label: "Username",
  },
  {
    id: "country",
    label: "Country",
  },
  {
    id: "action",
    label: "Action",
  },
];

const SuspendUser = (props) => {
  const { isBlockedUser } = props;
  const [searchText, setSearchText] = useState("");
  const [blockedSearch, setBlockedSearch] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    suspend_user,
    suspend_loading,
    blocked_loading,
    blocked_user,
    block_count,
    suspend_count,
  } = useSelector((e) => e.suspend);
  const { isLoading } = useSelector((e) => e.userInfo);

  useEffect(() => {
    if (isBlockedUser) {
      dispatch(
        getBlockedUser(
          `?page=${page + 1}&limit=${rowsPerPage}&search=${searchText}`
        )
      );
    } else {
      dispatch(
        getSuspendedUser(
          `?page=${page + 1}&limit=${rowsPerPage}&search=${searchText}`
        )
      );
    }
  }, [dispatch, isBlockedUser]);

  // useEffect(() => {
  //   if (isBlockedUser) {
  //     setSearchText("");
  //     setBlockedSearch("");
  //   }
  // }, [isBlockedUser]);

  const profileView = (id) => {
    dispatch(getUserView(id));
    let stateProps = isBlockedUser
      ? { isBlocked: true }
      : { isSuspended: true };
    navigate(`/admin/profile/id=${id}`, {
      state: { ...stateProps, showMenuOption: true },
    });
  };

  const rowData = isBlockedUser ? blocked_user : suspend_user;
  const searchVal = isBlockedUser ? blockedSearch : searchText;
  const total_count = isBlockedUser ? block_count : suspend_count;

  const rows = () => {
    if (Array.isArray(rowData)) {
      if (rowData) {
        return rowData.map((el) => ({
          name: <CustomText variant="body_1">{el?.name}</CustomText>,
          email: <CustomText variant="gray_title">{el.email}</CustomText>,
          user_name: (
            <CustomText variant="gray_title">{el?.user_name}</CustomText>
          ),
          country: <CustomText variant="gray_title">{el.country}</CustomText>,
          action: (
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              onClick={() => profileView(el.id)}
            >
              <ViewIcon />
            </IconButton>
          ),
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const brcrmb = [
    {
      name: isBlockedUser ? "Blocked" : "Suspended",
      path: isBlockedUser ? "/admin/block-user" : "/admin/suspended",
    },
  ];

  const handleGetListingData = (newPage, rowsPerPage, searchText = "") => {
    const queryParam = `?page=${
      newPage + 1
    }&limit=${rowsPerPage}&search=${searchText}`;
    if (isBlockedUser) dispatch(getBlockedUser(queryParam));
    else dispatch(getSuspendedUser(queryParam));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleGetListingData(newPage, rowsPerPage, searchVal);
  };

  const handleChangeRowsPerPage = (event) => {
    let rowPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(rowPerPage);
    handleGetListingData(page, rowPerPage, searchVal);
  };

  let ref;
  useEffect(() => {
    setPage(0);
    ref = setTimeout(
      () => handleGetListingData(0, rowsPerPage, searchVal),
      1000
    );
    return () => clearTimeout(ref);
  }, [searchVal]);

  return (
    <>
      <NavBar option={brcrmb} />
      <Card sx={{ m: "20px 36px 50px 36px" }}>
        <Box sx={{ m: "30px", display: "flex", alignItems: "start" }}>
          <SearchField
            value={searchVal}
            sx={{
              maxWidth: "579px",
              "& .MuiOutlinedInput-input": {
                background: "rgba(168, 88, 15, 0.06) !important",
              },
              background: "rgba(168, 88, 15, 0.06) !important",
            }}
            onChange={(e) => {
              isBlockedUser ? setBlockedSearch(e) : setSearchText(e);
            }}
          />
        </Box>
        {isLoading ? (
          <CustomLoader />
        ) : (
          <CustomTable
            rows={rows()}
            headCells={column}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            totalCount={total_count}
          />
        )}
      </Card>
    </>
  );
};
export default SuspendUser;
