import React, { useEffect, useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { BoxWrap, ImgStyle, LinkStyle, ListButton, ListItemIconStyle, ListStyle } from "./style";
import { ArrowDown, LogoutIcon, RightArrow, UpArrow } from "../../theme/SvgIcons";
import { menuList } from "./menus";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import Logout from "./Logout";
import colors from "../../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import { sideBar_Responsive } from "../../redux/users/action";
import { url_path_index } from "../../utils/constant";

const Sidebar = (props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const [menuItem, setMenuItem] = useState()
  const [open, setOpen] = React.useState(false);
  const [indexM, setIndexM] = useState();
  const {show}=useSelector(e=>e.users)
  const dispatch=useDispatch()
  const {style}=props

  const handleImageClick = () => {
    setIsFullScreen(true);
  };
  const handleCloseModal = () => {
    setIsFullScreen(false);
  };

  useEffect(()=>{
    if(indexM===undefined){
      const pathname = window.location.pathname
      let refresh_index=url_path_index[pathname]===undefined?1:url_path_index[pathname]
      refresh_index===1 && setOpen(true)
      setIndexM(refresh_index)
    }
  },[indexM])

  const handleClick = (index,menu_child) => {
    setIndexM(index);
    menu_child && setOpen(!open);
    !menu_child && dispatch(sideBar_Responsive(!show))
  };
  const handleSideBarClose=()=>dispatch(sideBar_Responsive(!show))

  const removeLocal = (_menu) => {
    setMenuItem(_menu)
    if (_menu.label === 'Logout') {
      setModalOpen(true)
    }
  };


  const handleOnClick = () => {
    if (menuItem.label === 'Logout') {
      localStorage.removeItem('admin_detail');
      setTimeout(() => {
        toast.success('Logout Successfully')
        setModalOpen(false)
        window.location.reload();
      }, 500);
    }
  }

  return (
    <BoxWrap sx={style}>
      {!isFullScreen ? (
        <Box sx={{ width: '100%', alignItems: 'start', display: 'flex' }}>
          <ImgStyle
            src="/likeplaylogo.png"
            onClick={handleImageClick}
            style={{ cursor: "pointer" }}
          />
        </Box>
      ) : (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
          onClick={handleCloseModal}
        >
          <img
            src="/likeplaylogo.png"
            alt={"altText"}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </div>
      )}

      <ListStyle disablePadding
      className="menu-list"
      sx={{
        [`& .list:hover`]: {
          "& svg > path": {
            fill: colors.pinkred
          }
        }
      }}
      >
        {menuList?.map((_menu, key) => {
          return (
            <LinkStyle key={key} to={_menu.path}>
              <ListButton
              className="list"
              sx={{background:`${key===indexM && colors.lightGray_opacity}`,
                [`& .logo`]: {
                  "& svg > path": {
                    fill: `${key===indexM && colors.pinkred}`
                  }
                }
              }}
                onClick={() => {handleClick(key,_menu.child); }}// _menu.child && handleClick(key);
              >
                <ListItemIconStyle className="logo">{_menu.icon}</ListItemIconStyle>
                <ListItemText primary={_menu.label} />
                {open && indexM === key
                  ? _menu.child && <ArrowDown />
                  : _menu.child && <UpArrow />}
              </ListButton>
              {indexM === key && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {_menu.child &&
                    _menu.child.map((menuChild) => {
                      return (
                        <LinkStyle key={menuChild.label} to={menuChild.path}>
                          <ListButton sx={{ pl: 4 }} onClick={handleSideBarClose}>
                            <ListItemIcon>{menuChild.icon}</ListItemIcon>
                            <ListItemText primary={menuChild.label} />
                            <RightArrow />
                          </ListButton>
                        </LinkStyle>
                      );
                    })}
                </Collapse>
              )}
            </LinkStyle>
          );
        })}
        <LinkStyle>
          <ListButton className="list" onClick={() => removeLocal({ label: 'Logout', })}>
            <ListItemIconStyle className="logo"><LogoutIcon /></ListItemIconStyle>
            <ListItemText primary={'Logout'} />
          </ListButton>
        </LinkStyle>
      </ListStyle>
      {modalOpen && <Logout open={modalOpen} setOpen={setModalOpen} onClick={() => handleOnClick()} />}
    </BoxWrap>
  );
};

export default Sidebar;
