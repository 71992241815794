import { InputAdornment, TextField } from "@mui/material";
import colors from "../../theme/colors";
import styled from "@emotion/styled";
import { HideDataIcon, VisibleDataIcon } from "../../theme/SvgIcons";
import { useState } from "react";

export const FieldStyle = styled(TextField)(({ hasError, width,input_padding }) => ({
  background: colors.white,
  width: width || "100%",
  border: `1px solid ${hasError ? colors.redError : colors.Gray90}`,
  borderRadius: "4px",
  height: "47px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "0px",
      borderRadius: "6px",
    },
    "& input": {
      padding: input_padding || "12px",
    },
  },
}));

const CustomTextField = (props) => {
  const {
    value,
    onChange,
    name,
    type,
    placeholder,
    width,
    sx,
    error,
    helperText,
    register,
    showAdornment,
    input_padding
  } = props;
  const [isVisibleContent, setIsVisibleContent] = useState("password");

  const handlePasswordVisible = () => {
    setIsVisibleContent((prev) => (prev === "password" ? "text" : "password"));
  };
  
  return (
    <FieldStyle
      {...register}
      name={name}
      sx={sx}
      placeholder={placeholder || "Text"}
      type={showAdornment ? isVisibleContent : type ? type : "text"}
      value={value}
      hasError={error}
      error={error ? error : false}
      width={width}
      input_padding={input_padding}
      helperText={helperText ? helperText : ""}
      InputProps={{
        endAdornment: showAdornment && (
          <InputAdornment position="end" onClick={handlePasswordVisible}>
           { isVisibleContent==="text"?<VisibleDataIcon />:<HideDataIcon/> }
          </InputAdornment>
        ),
      }}
      onChange={onChange}
    ></FieldStyle>
  );
};

export default CustomTextField;
