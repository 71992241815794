import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Typography } from "@mui/material";
import colors from "../../theme/colors";
import styled from "@emotion/styled";
import { labelStyle } from "../Profile/style";
import CustomButton from "./CommonButton";
import { useParams } from "react-router-dom";
import CustomText from "../form/CustomText";

export default function EditPermissionUser(props) {
  const { open, setOpen, user, selectedList,handleOk } = props;
  const params = useParams();
  const detail_id = params?.id.split("=")[1];

  const handleButton1Click = () => {
    const user = {
      user_id: detail_id,
      user_verification: "2",
    };
    handleOk(user)
  };

  const handleButton2Click = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen()}>
      <ConfirmBox>
        <MainContainerModal>
          <DelText>
            <Typography
              sx={{
                ...labelStyle(colors.black, "14px", 500),
                textAlign: "center",
                paddingBottom: "10px",
                fontSize: "16px",
              }}
            >
              {`Are you sure you want to return ${user.user_name} to edit for below reasons`}
            </Typography>
            {selectedList.length &&
              selectedList.map((list, index) => (
                <CustomText variant="title_1" key={index}>
                  {list.value}
                </CustomText>
              ))}
          </DelText>
          <ButtonWrapper
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "end",
              padding: "20px 10px",
              gap: "15px",
              //   border: "1px solid red",
            }}
          >
            <CustomButton
              //   onClick={() => onClick()}
              iconAlign={"left"}
              // variant="editBtn"
              label="NO"
              width="120px"
              onClick={handleButton2Click}
              bg={colors.white}
              color={colors.black}
            />
            <CustomButton
              iconAlign={"left"}
              // variant="editBtn"
              label="YES"
              width="120px"
              onClick={handleButton1Click}
              bg={colors.pinkred}
              color={colors.white}
            />
          </ButtonWrapper>
        </MainContainerModal>
      </ConfirmBox>
    </Dialog>
  );
}

const ConfirmBox = styled(Box)`
  width: 446px;
  // height: 306.64px;
  display: flex;
  background: colors.ghostWhite;
`;

const MainContainerModal = styled(Box)`
  margin: 20px 40px;
`;


const DelText = styled(Box)`
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  textalign: "right;
  justifyContent: " space-between;
  padding: 15px;
`;
