import { UPDATE_PROFILE_RES } from './type';

const initialState = {
    update_profile_loader: true
};

function updateProfileReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_PROFILE_RES:
            return {
                ...state,
                profile_data:action?.payload,
                update_profile_loader: false
            };
        default:
            return state;
    }
}

export default updateProfileReducer;
