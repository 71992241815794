import {
  BLOCK_USER_STATUS,
  DELETE_LOADER_UPDATE_RES,
  DELETE_USER_RES,
  FAIL_GET_USER,
  GET_ADMIRERS_RES,
  GET_ADMIRING_RES,
  GET_MATCHES_RES,
  GET_REPORTED_USER_RES,
  MATCH_LOADER_RES,
  SUSPEND_USER_STATUS,
} from "./type";

const initialState = {
  reported_user: [],
  reported_loading: true,
  admirers_user: [],
  admirers_loading: true,
  admiring_user: [],
  admiring_loading: true,
  delete_status: false,
  block_status: false,
  suspend_status: false,
  matches_user: [],
  matches_loading: true,
  report_count: 0,
};

function reportedReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTED_USER_RES:
      return {
        ...state,
        reported_user: action.payload?.data,
        report_count: action?.payload?.extra,
        reported_loading: action?.status,
        delete_status: action?.status,
        block_status: action?.status,
        suspend_status: action?.status,
      };
    case FAIL_GET_USER:
      return {
        ...state,
        reported_loading: false,
      };
    case GET_ADMIRERS_RES:
      return {
        ...state,
        admirers_user: action.payload,
        admirers_loading: false,
      };
    case GET_ADMIRING_RES:
      return {
        ...state,
        admiring_user: action.payload,
        admiring_loading: false,
      };
    case DELETE_USER_RES:
      return {
        ...state,
        delete_status: action.payload,
      };
    case BLOCK_USER_STATUS:
      return {
        ...state,
        block_status: true,
      };
    case SUSPEND_USER_STATUS:
      return {
        ...state,
        suspend_status: true,
      };
    case DELETE_LOADER_UPDATE_RES:
      return {
        ...state,
        delete_status: action.payload,
      };
    case GET_MATCHES_RES:
      return {
        ...state,
        matches_user: action.payload,
        matches_loading: false,
      };
    case MATCH_LOADER_RES:
      return {
        ...state,
        matches_loading: action.payload,
      };
    default:
      return state;
  }
}

export default reportedReducer;
