import { TextareaAutosize } from "@mui/material";
import colors from "../../theme/colors";
import styled from "@emotion/styled";
import CustomText from "./CustomText";
import { useState } from "react";
import Picker from "emoji-picker-react";

const EmojiTextAreaField = (props) => {
  const {
    value = "",
    onChange,
    name,
    placeholder,
    width,
    error,
    helperText,
    register,
    minRows,
  } = props;
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (emojiData, event) => {
    const data = value + emojiData.emoji;
    onChange(data);
    setShowPicker(false);
  };
  return (
    <>
      <EmojiFieldWrapper>
        <FieldStyle
          {...register}
          name={name}
          placeholder={placeholder || "Enter Something..."}
          value={value}
          hasError={error}
          error={error ? error : false}
          width={width}
          minRows={minRows}
          helperText={helperText ? helperText : ""}
          onChange={onChange}
        />
        <img
          className="emoji-icon"
          src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
          onClick={() => setShowPicker((val) => !val)}
        />
        {showPicker && (
          <Picker className="input-emoji-picker" onEmojiClick={onEmojiClick} />
        )}
      </EmojiFieldWrapper>
      {error && <CustomText variant="error-content">{helperText}</CustomText>}
    </>
  );
};

export default EmojiTextAreaField;

export const FieldStyle = styled(TextareaAutosize)(({ hasError, width }) => ({
  background: colors.white,
  width: width || "100%",
  border: `1px solid ${hasError ? colors.redError : colors.Gray90}`,
  borderRadius: "10px",
  height: "47px",
  outline: "0px",
  padding: "10px",
  paddingBottom: "20px",
}));

const EmojiFieldWrapper = styled.div`
  position: relative;
  .emoji-icon {
    position: absolute;
    bottom: 13px;
    right: 7px;
    cursor: pointer;
  }
  .EmojiPickerReact {
    width: 100% !important;
  }
`;
