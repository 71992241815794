import { takeLatest, call, put } from "redux-saga/effects";
import {
  GET_USERS,
  GET_USER_VIEW,
  GET_VERIFIED_USERS,
  USER_VERIFY_BY_F_G_C,
  USER_IMOTF,
  SIDEBAR_RESPONSIVE,
  GET_REGISTERED_USERS,
  GET_UNDER_REVIEW_USERS,
  EDIT_REJECT_EMAIL,
  UNDER_REVIEW_USER_ACCEPT,
  RESET_EDIT_USER_LOADER,
  UPDATE_IMOTF_STATUS,
  REJECT_USER_LIST,
  REJECT_USER,
  REJECT_LOADER_UPDATE,
} from "./type";
import {
  getRegisterUserRoute,
  getRejectUsersRoute,
  getUnderReviewUserRoute,
  getUserRoute,
  getUserViewApi,
  getVerifyUserRoute,
  rejectUserRoute,
  reviewUserAcceptRoute,
  sendEditRejectEmailRoute,
  userImotfApi,
  userVerifyFcgApi,
} from "./apiRoute";
import {
  editRejectMail_Res,
  editUserStatus,
  failViewStatus,
  getAllRejectUser_Res,
  getFailRes,
  getRegisterUser_Res,
  getUnderReviewUser_Res,
  getUsersRes,
  getUserViewRes,
  getVerifiedUsersRes,
  sideBar_Responsive_Res,
  underReviewAccept_Res,
  updateIMOTFStatus_res,
  updateReject_loaderStatus,
  userReject_Res,
  UsersImotfStatus,
  UsersVerifyFGC_Res,
  verifyStatus,
  viewUserStatus,
} from "./action";
import { toast } from "react-toastify";
import { GET_USER_VRIFY, GET_USER_EDIT } from "./type";
import { userEditApi, userVerifyApi } from "./apiRoute";

function* getUserViewGenerator(data) {
  try {
    const res = yield call(getUserViewApi, data.payload);
    if (res.status === 200) {
      yield put(getUserViewRes(res?.data));
      yield put(viewUserStatus(true));
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data.message || "Something went wrong!");
    yield put(failViewStatus());
    yield put(viewUserStatus(true));
  }
}

function* getUserVrifyGenerator(data) {
  try {
    const res = yield call(userVerifyApi, data.payload);
    if (res.status === 200) {
      yield put(verifyStatus());
      toast.success(res?.message);
    }
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    yield put(verifyStatus());
  }
}
function* getUserEditGenerator(data) {
  try {
    const res = yield call(userEditApi, data.payload);
    if (res.status === 200) {
      yield put(editUserStatus(true));
    }
  } catch (error) {
    console.log(error);
    yield put(editUserStatus(true));
    toast.error(error?.response?.data.message || "Something went wrong!");
  }
}

function* resetEditLoaderGenerator(data) {
  yield put(editUserStatus(data?.payload));
}

function* getUsersGenerator(action) {
  const { queryParam } = action || {};
  try {
    let res = yield call(getUserRoute, queryParam);
    yield put(getUsersRes(res, false));
  } catch (error) {
    if (error) {
      console.log(error);
      yield put(getFailRes(error));
      toast.error(error?.response?.data.message || "Something went wrong!");
    }
  }
}

function* getVerifiedUsersGenerator(payload) {
  const { data, queryParam } = payload || {};
  try {
    let res = yield call(getVerifyUserRoute, data, queryParam);
    yield put(getVerifiedUsersRes(res, false));
  } catch (error) {
    if (error) {
      console.log(error);
      yield put(getFailRes(error));
      toast.error(error?.response?.data.message || "Something went wrong!");
    }
  }
}

function* userVerifyFgcGenratior(data) {
  try {
    const res = yield call(userVerifyFcgApi, data.payload);
    if (res.status === 200) {
      toast.success(res?.message);
      yield put(UsersVerifyFGC_Res(true));
    }
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    yield put(UsersVerifyFGC_Res(true));
  }
}

function* userImotfGenratior(data) {
  try {
    const res = yield call(userImotfApi, data.payload);
    if (res.status === 200) {
      yield put(UsersImotfStatus(true));
      toast.success(res?.message);
    }
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    yield put(UsersImotfStatus(true));
  }
}

function* sidebarResponsive(data) {
  yield put(sideBar_Responsive_Res(data.payload));
}

function* getRegisterUsersGenerator(action) {
  const { queryParam } = action || {};
  try {
    let res = yield call(getRegisterUserRoute, queryParam);
    yield put(getRegisterUser_Res(res, false));
  } catch (error) {
    if (error) {
      console.log(error);
      yield put(getRegisterUser_Res(error));
      toast.error(error?.response?.data.message || "Something went wrong!");
    }
  }
}
function* getReviewUsersGenerator(action) {
  const { queryParam } = action || {};
  try {
    let res = yield call(getUnderReviewUserRoute, queryParam);
    yield put(getUnderReviewUser_Res(res, false));
  } catch (error) {
    if (error) {
      console.log(error);
      yield put(getUnderReviewUser_Res(error, false));
      toast.error(error?.response?.data.message || "Something went wrong!");
    }
  }
}

function* editRejectEmailGenerator(data) {
  try {
    let res = yield call(sendEditRejectEmailRoute, data.payload);
    yield put(editRejectMail_Res(true));
    toast.success(res?.message);
  } catch (error) {
    if (error) {
      console.log(error);
      yield put(editRejectMail_Res(true));
      toast.error(error?.response?.data.message || "Something went wrong!");
    }
  }
}

function* underReviewAcceptGenerator(data) {
  try {
    let res = yield call(reviewUserAcceptRoute, data.payload);
    yield put(underReviewAccept_Res(true));
    toast.success(res?.message || "");
  } catch (error) {
    if (error) {
      console.log(error);
      yield put(underReviewAccept_Res(true));
      toast.error(error?.response?.data.message || "Something went wrong!");
    }
  }
}

function* updateIMOTFStatusGenerator() {
  try {
    yield put(updateIMOTFStatus_res(false));
  } catch (error) {
    console.log(error);
    yield put(updateIMOTFStatus_res(false));
  }
}

function* getRejectUserGenerator(action) {
  const { queryParam } = action;
  try {
    let res = yield call(getRejectUsersRoute, queryParam);
    yield put(getAllRejectUser_Res(res, true));
  } catch (error) {
    if (error) {
      console.log(error);
      yield put(getAllRejectUser_Res(error, true));
      toast.error(error?.response?.data.message || "Something went wrong!");
    }
  }
}

function* rejectUserGenerator(data) {
  try {
    let res = yield call(rejectUserRoute, data.payload);
    yield put(userReject_Res(true));
    toast.success(res?.message || "User Rejected Successfully.");
  } catch (error) {
    if (error) {
      console.log(error);
      yield put(userReject_Res(true));
      toast.error(error?.response?.data.message || "Something went wrong!");
    }
  }
}

function* rejectLoaderUpdateGenerator(data) {
  try {
    yield put(updateReject_loaderStatus(data.payload));
  } catch (error) {
    console.log(error);
    yield put(updateReject_loaderStatus(data.payload));
  }
}

function* usersSaga() {
  yield takeLatest(GET_USER_EDIT, getUserEditGenerator);
  yield takeLatest(GET_USER_VRIFY, getUserVrifyGenerator);
  yield takeLatest(GET_USER_VIEW, getUserViewGenerator);
  yield takeLatest(GET_USERS, getUsersGenerator);
  yield takeLatest(GET_VERIFIED_USERS, getVerifiedUsersGenerator);
  yield takeLatest(USER_VERIFY_BY_F_G_C, userVerifyFgcGenratior);
  yield takeLatest(USER_IMOTF, userImotfGenratior);
  yield takeLatest(SIDEBAR_RESPONSIVE, sidebarResponsive);
  yield takeLatest(GET_REGISTERED_USERS, getRegisterUsersGenerator);
  yield takeLatest(GET_UNDER_REVIEW_USERS, getReviewUsersGenerator);
  yield takeLatest(EDIT_REJECT_EMAIL, editRejectEmailGenerator);
  yield takeLatest(UNDER_REVIEW_USER_ACCEPT, underReviewAcceptGenerator);
  yield takeLatest(RESET_EDIT_USER_LOADER, resetEditLoaderGenerator);
  yield takeLatest(UPDATE_IMOTF_STATUS, updateIMOTFStatusGenerator);
  yield takeLatest(REJECT_USER_LIST, getRejectUserGenerator);
  yield takeLatest(REJECT_USER, rejectUserGenerator);
  yield takeLatest(REJECT_LOADER_UPDATE, rejectLoaderUpdateGenerator);
}

export default usersSaga;
