import { Box, Card, IconButton } from "@mui/material";
import NavBar from "../navbar";
import CustomText from "../form/CustomText";
import CustomTable from "../form/CustomTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomLoader, SearchField } from "../../utils/common_functions";
import {
  getTestimonials,
  updateTestimonialLoader,
} from "../../redux/testimonial/action";
import { ViewIcon } from "../../theme/SvgIcons";
import ChildModel from "../deleteModel/ChildModel";
import colors from "../../theme/colors";
import EllipseText from "../form/EllipseText";

const column = [
  {
    id: "name",
    label: "User",
  },
  {
    id: "testimonial",
    label: "Message",
  },
  {
    id: "createdAt",
    label: "Created At",
  },
  {
    id: "action",
    label: "Action",
  },
];

const brcrmb = [{ name: "Testimonial", path: "/admin/testimonial" }];

const Feedback = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [detailMessage, setDetailMessage] = useState(null);
  const { testimonial_list, testimonial_loader, testimonial_count } =
    useSelector((e) => e.testimonial);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);

  const profileView = (el) => {
    setDetailMessage(el?.testimonial);
    setOpen(true);
  };

  const handleGetListingData = (newPage, rowsPerPage, searchText = "") => {
    const queryParam = `?page=${
      newPage + 1
    }&limit=${rowsPerPage}&search=${searchText}`;
    dispatch(getTestimonials(queryParam));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(updateTestimonialLoader(true));
    handleGetListingData(newPage, rowsPerPage, searchText);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(updateTestimonialLoader(true));
    let rowPerPage = parseInt(event.target.value, 10);
    setPage(0);
    setRowsPerPage(rowPerPage);
    handleGetListingData(page, rowPerPage, searchText);
  };

  let ref;
  useEffect(() => {
    if (testimonial_list !== undefined) {
      setPage(0);
      ref = setTimeout(() => handleSearch(), 1000);
    }
    return () => clearTimeout(ref);
  }, [searchText]);

  const handleSearch = () => {
    dispatch(updateTestimonialLoader(true));
    handleGetListingData(0, rowsPerPage, searchText);
  };

  const rows = () => {
    if (Array.isArray(testimonial_list)) {
      if (testimonial_list) {
        return testimonial_list.map((el) => ({
          name: (
            <CustomText
              variant="body_1"
              sx={{
                width: "120px",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {el?.name}
            </CustomText>
          ),
          testimonial: (
            <EllipseText variant="body_1">{el?.testimonial}</EllipseText>
          ),
          createdAt: (
            <CustomText variant="gray_title">
              {el?.createdAt.split("T")[0]}
            </CustomText>
          ),
          action: (
            <IconButton
              sx={{ width: "40px", height: "40px" }}
              onClick={() => profileView(el)}
            >
              <ViewIcon />
            </IconButton>
          ),
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  return (
    <>
      <NavBar option={brcrmb} />
      <Card sx={{ m: "20px 36px 50px 36px" }}>
        <Box sx={{ m: "30px", display: "flex", alignItems: "start" }}>
          <SearchField
            sx={{
              maxWidth: "579px",
              "& .MuiOutlinedInput-input": {
                background: "rgba(168, 88, 15, 0.06) !important",
              },
              background: "rgba(168, 88, 15, 0.06) !important",
            }}
            onChange={(e) => setSearchText(e)}
          />
        </Box>
        {testimonial_loader ? (
          <CustomLoader />
        ) : (
          <CustomTable
            rows={rows()}
            headCells={column}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={handleChangePage}
            setRowsPerPage={handleChangeRowsPerPage}
            totalCount={testimonial_count}
          />
        )}
        {open && (
          <ChildModel
            open={open}
            closeModal={() => setOpen(false)}
            title="Testimonial Message"
          >
            <CustomText
              sx={{
                height: "220px",
                overflow: "auto",
                overflowX: "hidden",
                padding: "10px",
                border: `1px solid ${colors.Gray90}`,
                borderRadius: "2px",
              }}
            >
              {detailMessage}
            </CustomText>
          </ChildModel>
        )}
      </Card>
    </>
  );
};

export default Feedback;
