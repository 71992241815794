import axiosInterceptor from "../../utils/axiosInterseptor";

export const reportedUserRoute = (queryParam) =>
  axiosInterceptor.get(`/admin/reportedUsers${queryParam}`);
export const gerAdmirersRoute = (id) =>
  axiosInterceptor.get(`/admin/getAdmirersUsers?user_id=${id}`);
export const getAdmiringUsersRoutes = (id) =>
  axiosInterceptor.get(`/admin/getAdmiringUsers?user_id=${id}`);
export const deleteUserRoute = (data) =>
  axiosInterceptor.put(`/admin/userDeletedByAdmin`, data);
export const blockUserRoute = (data) =>
  axiosInterceptor.put(`/admin/userBlock`, data);
export const suspendUserRoute = (data) =>
  axiosInterceptor.put(`/admin/userSuspendedByAdmin`, data);
export const matchesUserRoute = (id, attributeStatus) =>
  axiosInterceptor.get(
    `/admin/getMatchedUsers?user_id=${id}&attributeStatus=${attributeStatus}`
  );
