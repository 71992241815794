import styled from "@emotion/styled";
import { Box, Button, Chip, Divider, Select, TextField } from "@mui/material";
import colors from "../../theme/colors";
import { fontFamilys } from "../../theme/common_style";

export const labelStyle = (
  color = colors.black,
  fontSize = "22px",
  fontWeight = 400
) => {
  const style = {
    fontFamily: fontFamilys.poppinsFont,
    fontStyle: "normal",
    fontWeight,
    fontSize,
    textAlign: "start",
    color,
    lineHeight: "22px",
  };
  return style;
};

export const UserWrapper = styled(Box)({
  background: "white",
  margin: "0 auto",
  padding: "15px",
  display: "flex",
  textAlign: "center",
  borderRadius: "10px",
  justifyContent: "space-between",
  flexWrap:"wrap"
});

export const DetailWrapper = styled(Box)({
});

export const UserDetailsWrapper = styled(Box)({
  display:"flex"
});

export const TabButtonWrapper = styled(Box)({
  width: "100%",
  backgroundColor: "#D9D9D9",
  height: "49px",
  padding: "0px 50px",            
  display:"flex",
  overflow:"auto",
  '&::-webkit-scrollbar': { display: 'none' },
  textTransform: "uppercase",
  cursor:"pointer"
})

export const ReviewVerifyBtn = styled(Box)(({ bg}) => ({
  width: "90px",
  height: "27px",
  background: bg,
  borderRadius: "10px",
  padding: "0px 10px",
  marginight: "15px",
}))