import { Card, Grid } from "@mui/material";
import NavBar from "../navbar";
import ShowPosts from "./ShowPosts";
import UploadPostForm from "./UploadPostForm";

const brcrmb = [{ name: "Post", path: "/admin/my-posts" }];
const MyPosts = () => {
  return (
    <>
      <NavBar option={brcrmb} />
      <Grid
        sx={{ pt: "20px", pb: "20px", pr: "20px", pl: "20px" }}
        container
        rowSpacing="20px"
        wrap="wrap-reverse"
      >
        <Grid item md={7} xs={12}>
          <Card
            sx={{
              m: "0 16px",
              height: `calc(100vh - 139px)`,
              overflow: "auto",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <ShowPosts />
          </Card>
        </Grid>
        <Grid item md={5} xs={12}>
          <Card
            sx={{
              m: "0 16px",
              height: `calc(100vh - 139px)`,
              overflow: "auto",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            <UploadPostForm />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default MyPosts;
