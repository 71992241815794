import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import MainContainer from "./components/main-container";
import SignIn from "./components/sign-in";
import { getDataFromLocalStorage } from "./utils/common_functions";
import "react-toastify/dist/ReactToastify.css";
import UserLists from "./components/users";
import Profile from "./components/Profile/profile";
import { useSelector } from "react-redux";
import NoRouteFound from "./utils/NoPageFound";
import VerifyUsers from "./components/users/VerifyUsers";
import Dashboard from "./components/dashboard";
import ReportedUser from "./components/reported";
import ChangePassword from "./components/change-password";
import Feedback from "./components/feedback";
import Testimonial from "./components/testimonial";
import MyPosts from "./components/my-posts";
import SuspendUser from "./components/suspended";
import UpdateProfile from "./components/change-profile"
import DeactiveUsers from "./components/users/de-activeUser";
import RegisterdUsers from "./components/users/registerUser";
import UnderReviewUsers from "./components/users/underReview-user"
import RejectUsers from "./components/users/rejectUsers"
import { getAuth, signInAnonymously } from "firebase/auth";

function App() {
  const { sign_in_loader } = useSelector((e) => e.signIn);
  const userData = getDataFromLocalStorage("admin_detail");
  const newtoken =
    sign_in_loader && Object.keys(sign_in_loader).length > 0 ? sign_in_loader : userData;

    const createUserOnFirebase = async () => {
      const auth = getAuth();
      signInAnonymously(auth)
        .then(() => {
          // Signed in..
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });
    };
  const PublicRoute = () => {
    if (newtoken?.token !== undefined) {
      createUserOnFirebase();
      return <Navigate to="/admin/dashboard" replace />;
    }
    return <Outlet />;
  };
  
  const PrivateRoute = () => {
    if (newtoken?.token === undefined) {
      return <Navigate to="" />;
    }
    return <Outlet />;
  };

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="" element={<SignIn />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="" element={<MainContainer />}>
          <Route path='*' element={<NoRouteFound />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path='/admin/waiting-approval' element={<UserLists isUserVerify={false} />} />
          <Route path='/admin/users-verify' element={<VerifyUsers isUserVerify />} />
          <Route path='/admin/deactive-user' element={<DeactiveUsers isUserVerify />} />
          <Route path='/admin/register-user' element={<RegisterdUsers isUserVerify />} />
          <Route path='/admin/under-review' element={<UnderReviewUsers isUserVerify />} />
          <Route path='/admin/reject-user' element={<RejectUsers isUserVerify/>} />

          <Route path='/admin/profile/:id' element={<Profile />} />
          <Route path='/admin/change-password' element={<ChangePassword />} />
          <Route path='/admin/reported' element={<ReportedUser />} />
          <Route path='/admin/suspended' element={<SuspendUser />} />
          <Route path='/admin/block-user' element={<SuspendUser isBlockedUser={true} />} />
          <Route path='/admin/feedback' element={<Feedback />} />
          <Route path='/admin/testimonial' element={<Testimonial />} />
          <Route path='/admin/my-posts' element={<MyPosts />} />
          <Route path='/admin/update-profile' element={<UpdateProfile />} />
          <Route path='/admin/logout' element={'logout'} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
