import { takeLatest, call, put } from 'redux-saga/effects';
import { CHANGE_PASSWORD, RESET_LOADER_STATUS } from './type';
import { changePassword } from './apiRoute';
import { changePasswordRes } from './action';
import { toast } from 'react-toastify';

function* changePasswordGenerator(action) {
    const { payload } = action|| {};
    try {
        let res = yield call(changePassword, payload);
        yield put(changePasswordRes(false))
        if (res && res?.status === 200) {
            toast.success(res?.message)
        }

    } catch (error) {
        yield put(changePasswordRes(false))
        if (error) {
            toast.error(error?.response?.data.message || 'Something went wrong!')
        }
    }
}
function* resetPasswordGenerator(action) {
    const { payload } = action|| {};
    yield put(changePasswordRes(payload))
}

function* changePasswordSaga() {
    yield takeLatest(CHANGE_PASSWORD, changePasswordGenerator);
    yield takeLatest(RESET_LOADER_STATUS, resetPasswordGenerator);
}

export default changePasswordSaga;
