import { SIGN_IN, SIGN_IN_RES } from "./type";

export const signInAdmin = (data) => {
    return {
        type: SIGN_IN,
        payload: data
    };
};

export const signInAdminRes = (data) => {
    return {
        type: SIGN_IN_RES,
        payload: data
    };
};
