import {
  FEEDBACK_LOADER_STATUS_UPDATE,
  FEEDBACK_STATUS,
  GET_DASHBOARD_USER_COUNT_RES,
  RESPONSE_STATUS,
} from "./type";

const initialState = {
  dashboard_count: {},
  count_loading: true,
  feedback_list: [],
  feedback_loading: true,
  feedback_count: 0,
};

function dashboarCountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_USER_COUNT_RES:
      return {
        ...state,
        dashboard_count: action.payload,
        count_loading: false,
      };
    case RESPONSE_STATUS:
      return {
        ...state,
        count_loading: false,
      };
    case FEEDBACK_STATUS:
      return {
        ...state,
        feedback_list: action.payload?.data,
        feedback_count: action.payload?.extra,
        feedback_loading: false,
      };
    case FEEDBACK_LOADER_STATUS_UPDATE:
      return {
        ...state,
        feedback_loading: action.payload,
      };
    default:
      return state;
  }
}

export default dashboarCountReducer;
