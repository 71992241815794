import { Box, IconButton, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import styled from '@emotion/styled';
import { MenuDropDownIcon } from '../../theme/SvgIcons';

export const MenuStyle = styled(Menu)({
  '&  li': {
    width: '150px'
  }
});

const CustomMenu = (props) => {
  const { option, onChange, name, rest } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlemenuClick = (item) => {
    handleClose();
    onChange && onChange(item);
  };
  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MenuDropDownIcon />
      </IconButton>
      <MenuStyle {...rest} name={name} anchorEl={anchorEl} open={open} onClose={handleClose}>
        {option?.map((opt, key) => {
          return (
            <MenuItem key={key} onClick={() => handlemenuClick(opt)}>
              {opt.label}
            </MenuItem>
          );
        })}
      </MenuStyle>
    </Box>
  );
};

export default CustomMenu;
