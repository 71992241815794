import { Card, IconButton } from "@mui/material"
import CustomTable from "../form/CustomTable"
import CustomText from "../form/CustomText";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAdmirings } from "../../redux/reported/action";
import { CustomLoader } from "../../utils/common_functions";
import { BlockUser, DeleteIcon, ReportUser } from "../../theme/SvgIcons";
import colors from "../../theme/colors";
import { useParams } from "react-router-dom";

const column = [
    {
        id: "name",
        label: "Name",
    },
    {
        id: "user_name",
        label: "User Name",
    },
    {
        id: "admire_by",
        label: "UserID",
    },
    {
        id: "email",
        label: "Email",
    },
    {
        id: "createdAt",
        label: "Registered",
    },
];

const rows = (admirers_user) => {
    if (Array.isArray(admirers_user)) {
        if (admirers_user) {
            return admirers_user
                .map((el) => ({
                    name: <CustomText variant="body_1">{el?.AdmireTo?.user_name}</CustomText>,
                    user_name: <CustomText variant="gray_title">{el?.AdmireTo?.user_name}</CustomText>,
                    admire_by: <CustomText variant="gray_title">{el?.admire_to}</CustomText>,
                    email: <CustomText variant="gray_title">{el?.AdmireTo?.email}</CustomText>,
                    createdAt: <CustomText variant="gray_title">{el?.AdmireTo?.createdAt?.split('T')[0]}</CustomText>,
                    action: (
                        <>
                            <IconButton
                                sx={{ width: "40px", height: "40px" }}
                            >
                                <ReportUser />
                            </IconButton>
                            <IconButton
                                sx={{ width: "40px", height: "40px" }}
                            >
                                <DeleteIcon fill={colors.pinkred} />
                            </IconButton>
                            <IconButton
                                sx={{ width: "40px", height: "40px" }}
                            >
                                <BlockUser fill={colors.pinkred} />
                            </IconButton>
                        </>
                    ),
                }));
        } else {
            return [];
        }
    } else {
        return [];
    }
};
const NoOfAdmiring = () => {
    const dispatch = useDispatch()
    const { admiring_user, admiring_loading } = useSelector(e => e.reported)
    const params = useParams();
    const user_id = params?.id.split("=")[1];
    useEffect(() => {
        dispatch(getAdmirings(user_id))
    }, [dispatch, user_id])

    return (
        <Card>
            {
                admiring_loading ? <CustomLoader /> :
                    <CustomTable rows={rows(admiring_user)} headCells={column} />
            }
        </Card>
    )
}
export default NoOfAdmiring