import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';

export default function CustomDialog(props) {
  const { open, setOpen, children } = props;

  return (
    <Dialog open={open} onClose={() => setOpen()}>
      <DialogContent>
        {children && children}
      </DialogContent>
    </Dialog>
  );
}
