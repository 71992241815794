import { Typography } from '@mui/material';
import { fontFamilys } from '../../theme/common_style';

const CustomText = (props) => {
  const { children, variant, sx,className,...rest } = props;
  let style = {
    fontFamily: fontFamilys.poppinsFont,
    width: '100%'
  };
  return (
    <Typography
    {...rest}
    className={className}
      sx={{ ...style, ...sx }}
      component={'p'}
      variant={variant ? variant : 'body1_medium'}>
      {children}
    </Typography>
  );
};


export default CustomText;
