import {
  FAILER_USER_RES,
  GET_USERS,
  GET_USERS_RES,
  GET_USER_VIEW,
  GET_USER_VIEW_RESPONSE,
  GET_VERIFIED_USERS,
  GET_VERIFIED_USERS_RES,
  GET_USER_VRIFY,
  GET_USER_EDIT,
  USER_VERIFY_BY_F_G_C,
  USER_IMOTF,
  VERIFY_STATUS,
  USER_IMOTF_STATUS,
  USER_EDIT_STATUS,
  FAIL_VIEW_STATUS,
  SIDEBAR_RESPONSIVE,
  SIDEBAR_RESPONSIVE_RES,
  SET_LOADER_TRUE,
  GET_REGISTERED_USERS,
  GET_REGISTERED_USERS_RES,
  GET_UNDER_REVIEW_USERS,
  GET_UNDER_REVIEW_USERS_RES,
  EDIT_REJECT_EMAIL,
  EDIT_REJECT_EMAIL_RES,
  UNDER_REVIEW_USER_ACCEPT,
  UNDER_REVIEW_USER_ACCEPT_RES,
  USER_VERIFY_BY_F_G_C_RES,
  RESET_EDIT_USER_LOADER,
  UPDATE_IMOTF_STATUS,
  UPDATE_IMOTF_STATUS_RES,
  REJECT_USER,
  REJECT_USER_RES,
  REJECT_USER_LIST,
  REJECT_USER_LIST_RES,
  REJECT_LOADER_STATUS_UPDATE,
  REJECT_LOADER_UPDATE,
  LOADER_STATUS,
} from "./type";

export const getUsers = (queryParam) => {
  return {
    type: GET_USERS,
    queryParam,
  };
};

export const getUsersRes = (payload, status) => {
  return {
    type: GET_USERS_RES,
    payload,
    status,
  };
};

export const getUserView = (id) => {
  return {
    type: GET_USER_VIEW,
    payload: id,
  };
};

export const getUsersVerify = (payload) => {
  return {
    type: GET_USER_VRIFY,
    payload,
  };
};
export const verifyStatus = (payload) => {
  return {
    type: VERIFY_STATUS,
    payload,
  };
};

export const getUsersEdit = (payload) => {
  return {
    type: GET_USER_EDIT,
    payload,
  };
};

export const getUserViewRes = (res) => {
  return {
    type: GET_USER_VIEW_RESPONSE,
    payload: res,
  };
};

export const getFailRes = (res) => {
  return {
    type: FAILER_USER_RES,
    payload: res,
  };
};

export const getVerifiedUsers = (data, queryParam = "") => {
  return {
    type: GET_VERIFIED_USERS,
    data,
    queryParam,
  };
};

export const getVerifiedUsersRes = (payload, status) => {
  return {
    type: GET_VERIFIED_USERS_RES,
    payload,
    status,
  };
};

export const UsersVerifyFGC = (payload) => {
  return {
    type: USER_VERIFY_BY_F_G_C,
    payload,
  };
};

export const UsersVerifyFGC_Res = (payload) => {
  return {
    type: USER_VERIFY_BY_F_G_C_RES,
    payload,
  };
};

export const UsersImotf = (payload) => {
  return {
    type: USER_IMOTF,
    payload,
  };
};

export const UsersImotfStatus = (payload) => {
  return {
    type: USER_IMOTF_STATUS,
    payload,
  };
};

export const editUserStatus = (payload) => {
  return {
    type: USER_EDIT_STATUS,
    payload,
  };
};

export const resetEditUserLoader = (payload) => {
  return {
    type: RESET_EDIT_USER_LOADER,
    payload,
  };
};

export const failViewStatus = () => {
  return {
    type: FAIL_VIEW_STATUS,
  };
};

export const sideBar_Responsive = (data) => {
  return {
    type: SIDEBAR_RESPONSIVE,
    payload: data,
  };
};

export const sideBar_Responsive_Res = (data) => {
  return {
    type: SIDEBAR_RESPONSIVE_RES,
    payload: data,
  };
};

export const viewUserStatus = (data) => {
  return {
    type: SET_LOADER_TRUE,
    payload: data,
  };
};

export const updateIMOTFStatus = () => {
  return {
    type: UPDATE_IMOTF_STATUS,
  };
};
export const updateIMOTFStatus_res = (data) => {
  return {
    type: UPDATE_IMOTF_STATUS_RES,
    payload: data,
  };
};

// RegisterUser
export const getRegisterUser = (queryParam) => {
  return {
    type: GET_REGISTERED_USERS,
    queryParam,
  };
};

export const getRegisterUser_Res = (data, status) => {
  return {
    type: GET_REGISTERED_USERS_RES,
    payload: data,
    status,
  };
};

// underReview
export const getUnderReviewUser = (queryParam) => {
  return {
    type: GET_UNDER_REVIEW_USERS,
    queryParam,
  };
};

export const getUnderReviewUser_Res = (data, status) => {
  return {
    type: GET_UNDER_REVIEW_USERS_RES,
    payload: data,
    status,
  };
};

//under-review buttons action
export const editRejectMail = (data) => {
  return {
    type: EDIT_REJECT_EMAIL,
    payload: data,
  };
};

export const editRejectMail_Res = (data) => {
  return {
    type: EDIT_REJECT_EMAIL_RES,
    payload: data,
  };
};

export const underReviewAccept = (data) => {
  return {
    type: UNDER_REVIEW_USER_ACCEPT,
    payload: data,
  };
};

export const underReviewAccept_Res = (data) => {
  return {
    type: UNDER_REVIEW_USER_ACCEPT_RES,
    payload: data,
  };
};

// Reject users

export const userReject = (data) => {
  return {
    type: REJECT_USER,
    payload: data,
  };
};

export const userReject_Res = (data) => {
  return {
    type: REJECT_USER_RES,
    payload: data,
  };
};

export const updateReject_loader = (data) => {
  return {
    type: REJECT_LOADER_STATUS_UPDATE,
    payload: data,
  };
};
export const updateReject_loaderStatus = (data) => {
  return {
    type: REJECT_LOADER_STATUS_UPDATE,
    payload: data,
  };
};

export const getAllRejectUser = (queryParam) => {
  return {
    type: REJECT_USER_LIST,
    queryParam,
  };
};

export const getAllRejectUser_Res = (data, status) => {
  return {
    type: REJECT_USER_LIST_RES,
    payload: data,
    status,
  };
};

export const updateLoaderStatus = (status) => {
  return {
    type: LOADER_STATUS,
    status,
  };
};
