import NavBar from "../navbar";
import CustomText from "../form/CustomText";
import {
  BoxWrapper,
  CardStyle,
  ButtonStyle,
  ImageWrapper,
  TextContent,
  ChangePasswordContainer,
  UploadIconContainer,
  NameContainer,
  CardWrapper
} from "./style";
import {
  CustomFlex,
  CustomLoader,
  getDataFromLocalStorage,
  setDataInLocalStorage,
} from "../../utils/common_functions";
import { useEffect, useRef, useState } from "react";
import colors from "../../theme/colors";
import { useNavigate } from "react-router-dom";
import {
  CopyContentIcon,
  ProfileUploadIcon,
  EditBorderIcon,
} from "../../theme/SvgIcons";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../redux/update-profile/action";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";

const ProfileSection = () => {
  const navigate = useNavigate();
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [uploadImage, setUploadImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [copyMessage,setCopyMessage]=useState(false)
  const {profile_data,update_profile_loader}=useSelector(e=>e.profile)

  const dispatch = useDispatch();
  const admin_info = getDataFromLocalStorage("admin_detail");
  let update_name = useRef(admin_info?.first_name + " " + admin_info?.last_name);

  const brcrmb = [{ name: "Change Profile", path: "/admin/update-profile" }];

  useEffect(()=>{   
    if(!update_profile_loader) {
    let update_info={...admin_info,first_name:profile_data?.first_name,last_name:profile_data?.last_name,profile_picture:profile_data?.profile_picture}
    setDataInLocalStorage("admin_detail",update_info)
    update_name.current=profile_data?.first_name+" "+profile_data?.last_name
    setSelectedFileUrl(process.env.REACT_APP_IMAGE_URL + profile_data?.profile_picture)
    setLoader(false)    
    setIsEdit(false)
    }
  },[profile_data])

  const handledCopy=()=>{
    setCopyMessage(true)
    navigator.clipboard.writeText(admin_info?.email)
    setTimeout(()=>setCopyMessage(false),2000)
  }

  const onSubmit = () => {   
    if (
      uploadImage === null &&
      update_name?.current === admin_info?.first_name + " " + admin_info?.last_name
    )
      toast.info("Please either change your name or image first.");
    else {
      setLoader(true)
      let nameArray = update_name?.current.split(" ");
      let first_name =
        nameArray.length > 2
          ? nameArray.slice(0, -2).join(" ")
          : nameArray.at(0);
      let last_name = nameArray.length > 1 ? nameArray.at(-1) : "";
      const fd = new FormData();
      fd.append("first_name", first_name);
      fd.append("last_name", last_name);
      uploadImage !== null && fd.append("profile_picture", uploadImage[0], uploadImage[0]?.name);
      dispatch(updateProfile(fd))
    }
  };

  const imageUploader = async (event) => {
    const fr = new FileReader();
    if (event.target.files[0] !== undefined) {
      fr.readAsArrayBuffer(event.target.files[0]);
      fr.onload = function () {
        // you can keep blob or save blob to another position
        const blob = new Blob([fr.result]);
        // url for download
        const url = URL.createObjectURL(blob, { type: "image/*"});
        setSelectedFileUrl(url);
      };
    }
    setUploadImage(event.target.files);
  };

  const handleEdit = () => {
    setIsEdit((pre) => !pre);
  };

  const handleNameEdit = (e) => {
    update_name.current = e.target.value;
  };

  useEffect(() => {
    setSelectedFileUrl(
      process.env.REACT_APP_IMAGE_URL + admin_info?.profile_picture
    );
  }, []);

  return (
    <>
      <NavBar option={brcrmb} />
      {loader && <CustomLoader />}
      <BoxWrapper>
        <CardWrapper>
        <CardStyle>
          <UploadIconContainer>
            <ImageWrapper>
              <img
                src={selectedFileUrl}
                alt="Profile"
                height={"120px"}
                width={"auto"}
              />
            </ImageWrapper>
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={imageUploader}
            />
            <label htmlFor="contained-button-file">
              <ProfileUploadIcon />
            </label>
          </UploadIconContainer>
          <TextContent>
            <NameContainer>
              {isEdit ? (
                <input
                  type="text"
                  defaultValue={
                    admin_info?.first_name + " " + admin_info?.last_name
                  }
                  ref={update_name}
                  onChange={handleNameEdit}
                />
              ) : (
                admin_info?.first_name + " " + admin_info?.last_name
              )}
              &nbsp;&nbsp;
              <p onClick={handleEdit}>
                <EditBorderIcon />
              </p>
            </NameContainer>
            <CustomText
              variant={"title_blue"}
              sx={{ color: `${colors.rudyBlue}!important` }}
            >
              {admin_info?.email}
              &nbsp;&nbsp;
              <Tooltip title={copyMessage && "Copied!"}>
              <span onClick={handledCopy}><CopyContentIcon /></span>
              </Tooltip>
            </CustomText>
          </TextContent>
          <ChangePasswordContainer>
            <CustomFlex sx={{ flexDirection: "column" }}>
              <CustomText
                variant={"body_1"}
                sx={{ width: "fit-content!important", marginBottom: "10px" }}
              >
                Password
              </CustomText>
              <CustomText
                variant={"body_1"}
                sx={{ width: "fit-content!important" }}
              >
                ********
              </CustomText>
            </CustomFlex>
            <CustomText
              variant={"title_blue"}
              sx={{ width: "fit-content!important", cursor: "pointer" }}
              onClick={() => navigate("/admin/change-password")}
            >
              change Password
            </CustomText>
          </ChangePasswordContainer>
          <ButtonStyle type="submit" onClick={onSubmit}>
            Save
          </ButtonStyle>
        </CardStyle>
        </CardWrapper>
      </BoxWrapper>
    </>
  );
};

export default ProfileSection;
