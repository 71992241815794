import {
  GET_TESTIMONIAL_RESPONSE,
  TESTIMONIAL_LOADER_STATUS_UPDATE,
} from "./type";

const initialState = {
  testimonial_loader: true,
  testimonial_list: [],
  testimonial_count: 0,
};

function testimonialReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TESTIMONIAL_RESPONSE:
      return {
        ...state,
        testimonial_list: action.payload?.data,
        testimonial_count: action.payload?.extra,
        testimonial_loader: false,
      };
    case TESTIMONIAL_LOADER_STATUS_UPDATE:
      return {
        ...state,
        testimonial_loader: action.payload,
      };
    default:
      return state;
  }
}

export default testimonialReducer;
