import styled from "@emotion/styled";
import { Box, Button, Card, Typography } from "@mui/material";
import colors from "../../theme/colors";

export const CardStyle = styled(Card)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: `1px solid ${colors.gray66}`,
    padding: "30px 41px 50px 41px",
    marginTop: "4rem",
    background: colors.white,
    boxShadow: '0px 16px 112px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: '22px',
});

export const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "EBEDEF",
    minHeight: "100vh",
});

export const ButtonStyle = styled(Button)({
    width: "100%",
    textTransform: "capitalize",
    color: colors.white,
    background: 'linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)',
    height: "56px",
    marginTop: "40px",
    borderRadius: '10px'
});

export const LinkStyle = styled(Typography)({
    color: colors.black,
    marginLeft: "5px",
    fontWeight: 700,
    cursor: "pointer",
});
