import { useEffect, useState } from "react";
import { Box } from "@mui/material";
// import { SearchField } from "../../utils/common_functions";
import { PostContainer, PostImageWrapper } from "./style";
import PostTopSection from "./TopSection";
// import PostBottomSection from "./BottomSection";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdminPost, getAdminPosts } from "../../redux/post/action";
import { CustomFlex, CustomLoader } from "../../utils/common_functions";
import colors from "../../theme/colors";
import Logout from "../sidebar/Logout";

const ShowPosts = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [openConfirm, setOpenConfirm] = useState({
    type: "",
    openModal: false,
    post_id: null,
  });

  const confirmLabel = () => {
    const text = {
      delete: "Are you sure want to delete this user?",
    };
    if (openConfirm) {
      return text[openConfirm.type];
    }
  };

  const btnLabel = () => {
    const text = {
      delete: "Delete",
    };
    if (openConfirm) {
      return text[openConfirm.type];
    }
  };

  const handleConfirmClick = () => {
    if (openConfirm.type === "delete") {
      setLoader(true);
      dispatch(deleteAdminPost(openConfirm.post_id));
      setOpenConfirm({ openModal: false });
    }
  };
  const {
    post_users,
    all_post_loader,
    post_create_loader,
    delete_post_loader,
  } = useSelector((e) => e.post);

  const getAllPostList = () => {
    dispatch(getAdminPosts());
  };

  useEffect(() => {
    getAllPostList();
  }, []);

  useEffect(() => {
    if (all_post_loader) setLoader(false);
    else if (delete_post_loader) setLoader(false);
  }, [all_post_loader, delete_post_loader]);

  useEffect(() => {
    if (post_create_loader) {
      getAllPostList();
    }
  }, [post_create_loader]);

  return (
    <Box sx={{ p: "20px 100px", height: "100%" }}>
      {loader && <CustomLoader />}
      {post_users?.Posts?.length > 0 ? (
        post_users?.Posts.map((list, index) => (
          <PostContainer key={index}>
            <PostTopSection list={list} setOpenConfirm={setOpenConfirm} />
            <PostImageWrapper>
              {list?.media_type === "0" ? (
                <img
                  height={"100%"}
                  width={"auto"}
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    list?.PostContents[0]?.media_url
                  }
                  alt="post"
                />
              ) : (
                <video
                  autoPlay
                  muted
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    list?.PostContents[0]?.media_url
                  }
                  loop
                  width="100%"
                  height="auto"
                  controls
                />
              )}
            </PostImageWrapper>
            {/* <PostBottomSection/> */}
          </PostContainer>
        ))
      ) : (
        <CustomFlex
          sx={{
            height: `100%`,
            color: colors.grayLight,
            fontWeight: "bold",
            width: "100%",
            justifyContent: "center",
          }}
        >
          No Content
        </CustomFlex>
      )}
      {openConfirm.openModal && (
        <Logout
          open={openConfirm.openModal}
          setOpen={() => setOpenConfirm({ openModal: false })}
          text={confirmLabel()}
          onClick={() => handleConfirmClick()}
          btnLabel={btnLabel()}
        />
      )}
    </Box>
  );
};

export default ShowPosts;
