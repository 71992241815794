import { CHANGE_PASSWORD, CHANGE_PASSWORD_RES, RESET_LOADER_STATUS } from "./type";

export const changePassword = (data) => {
    return {
        type: CHANGE_PASSWORD,
        payload: data
    };
};

export const changePasswordRes = (data) => {
    return {
        type: CHANGE_PASSWORD_RES,
        payload: data
    };
};

export const resetLoaderStatus = (data) => {
    return {
        type: RESET_LOADER_STATUS,
        payload: data
    };
};