import styled from "@emotion/styled";
import { Box, Button, Card } from "@mui/material";
import colors from "../../theme/colors";

export const BoxWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "EBEDEF",
    minHeight: "calc(100vh - 99px)",
});

export const ButtonStyle = styled(Button)({
    width: "100%",
    textTransform: "capitalize",
    color: colors.white,
    background: 'linear-gradient(268.55deg, #FF483C 0%, #FF2C5A 100%)',
    height: "46px",
    marginTop: "30px",
    borderRadius: '10px'
});

export const CardStyle = styled(Card)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: `1px solid ${colors.gray66}`,
    padding: "30px 45px",
    background: colors.white,
    boxShadow: '0px 16px 112px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: '22px',
    width:"500px",
    maxHeight: "623px",
    margin: "auto",
});

export const CardWrapper = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: center;
  background: white;
  margin: 10px 20px;
  overflow-y: auto;
  height: calc(100vh - 119px);
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;